import api from '@/libs/axios'
import { formatWordings } from '@/utils/question'

function formatQuestion (questions) {
  const arr = []
  let question
  questions.forEach(element => {
    if (element.data.wordings && element.data.wordings[0] &&
         element.data.wordings[0].data.type === 'MULTIPLE_CHOICE' &&
          element.data.wordings[0].data.options) {
      question = {
        ...element,
        data: {
          ...element.data,
          wordings: [
            {
              ...element.data.wordings[0].data,
              id: element.data.wordings[0].metadata.id,
              options: formatWordings(element.data.wordings[0].data.options)
            }
          ]
        },
        id: element.metadata.id
      }
    } else {
      question = {
        ...element,
        id: element.metadata.id
      }
    }
    arr.push(question)
  })
  return arr
}

function formatExams (question) {
  if (!question) return {}
  const obj = {
    show: true,
    ...question.data,
    checked: false,
    title: question.data.name,
    duration: question.data.duration,
    id: question.metadata.id,
    question: formatQuestion(question.data.question),
    permissions: { ...question.permissions },
    pagination: { ...question.pagination }
  }
  return obj
}

const idOrganization = localStorage.getItem('ACTIVE_ORGANIZATION')

const getExam = async ({ commit }, { id, page = 1 }) => {
  if (!id) return
  commit('SET_EXAM_LOADING', true)
  await api.get(`/organizations/${idOrganization}/exams-database/${id}?with_questions=1&with_wordings=1&page=${page}`)
    .then(response => {
      commit('SET_EXAM_SELECTED', formatExams(response.data, page))
      commit('SET_EXAM_LOADING', false)
    })
}

export default {
  getExam
}
