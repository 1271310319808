<template>
  <div>
    <v-row class="pt-8" justify="center">
      <v-col cols="12" class="py-0">
        <label class="v-label v-label--active theme--light">Questões</label>
        <v-divider class="mt-1" style="border-color: #949494" />
        <list-question-exam @questionsOrder="$emit('questionsOrder', $event)" />
      </v-col>
    </v-row>

    <v-row class="pt-8 mb-6">
      <v-col cols="12" align="center">
        <dialog-add-question />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DialogAddQuestion from '@/components/dialog/DialogAddQuestion'
import ListQuestionExam from '@/components/list/ListQuestionExam'

export default {
  name: 'ListQuestionsAdded',
  components: {
    DialogAddQuestion,
    ListQuestionExam
  }
}
</script>
