<template>
  <div class="alerts">
    <div v-for="(card, index) in alerts" :key="index">
      <v-alert
        dense
        prominent
        class="font-bold"
        elevation="9"
        :type="card.color === 'red' ? 'error' : 'success'"
        :color="card.color ? card.color : 'green'"
      >&nbsp;&nbsp;<em>{{ card.msg }}</em></v-alert>
     </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['alerts'])
  }
}
</script>

<style  scoped>
.alerts {
  position: fixed;
  top: 5rem;
  right: 3rem;
  z-index: 300;
}
.font-bold {
  font-weight: 900
}
</style>
