<template>
    <v-container>
        <v-row>
            <v-col>
                <logs-table/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LogsTable from '@/components/logs/LogsTable.vue'

export default {
  name: 'LogsComponent',
  components: {
    LogsTable
  }
}
</script>
