<template>
  <v-card flat class="mb-7">
    <v-card-text class="pa-0">
      <div
        class="rounded-t card-user__background"
      ></div>
      <div class="pa-4 mt-n12 text-center">
        <div class="d-flex justify-center mt-n5" v-if="!isEditing">
          <img :src="user.data.avatar" class="rounded-circle elevation-1" width="128" height="128" />
        </div>
        <div class="d-flex justify-center mt-n5 user-avatar__cursor" v-else>
          <div v-if="user.data.is_certified">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <input hidden type="file" @change="changeAvatar($event)" id="avatar" />
                <v-badge
                  bottom
                  overlap
                >
                  <template v-slot:badge>
                    <div class="tooltip">
                      <v-icon>mdi-check</v-icon>
                      <span class="tooltiptext">Usuário com treinamento</span>
                    </div>
                  </template>
                  <img v-bind="attrs" v-on="on" @click="openInput()"
                       :src="user.data.avatar" class="rounded-circle elevation-1" width="128" height="128" />
                </v-badge>
              </template>
              <span>Alterar avatar</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <input hidden type="file" @change="changeAvatar($event)" id="avatar" />
                <img v-bind="attrs" v-on="on"
                     @click="openInput()" :src="$store.state.user.me.data.avatar" class="rounded-circle elevation-1" width="128" height="128" />
              </template>
              <span>Alterar avatar</span>
            </v-tooltip>
          </div>
        </div>
       <div v-if="!user.data.avatar.includes('gravatar') && $route.path.includes('my-profile')">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="ma-2"
              icon
              v-bind="attrs"
              v-on="on"
              @click="removeAvatar"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Remover Avatar</span>
        </v-tooltip>
       </div>
        <h2 class="mb-0 mt-4">{{ user.data.name }}</h2>
        <p class="font-weight-regular">{{ user.data.email }}</p>
      </div>
      <v-card-actions v-if="!isEditing">
        <v-btn @click="$router.replace(`/logs/${user.metadata.id}`)" dense small color="primary">
          <small>Ver registros de ações</small>
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    user: Object,
    isEditing: Boolean
  },
  methods: {
    ...mapMutations(['setLoading']),
    removeAvatar () {
      const organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
      const name = this.user.data.name.split(' ')
      const avatarName = `${name.at(0).charAt(0)}${name.at(-1).charAt(0)}`
      const payload = {
        avatar: `https://www.gravatar.com/avatar/d4570bd98a8555f5cb7b1e0f555c6876?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F${avatarName}%2F128%2F1e88e5%2Ffff%2F2%2F0.5%2F1%2F1%2F1`
      }
      this.setLoading(true)
      this.$axios.patch(`/organizations/${organizationId}/users/${this.user.metadata.id}`, payload)
        .then(() => {
          location.reload()
        })
        .finally(() => this.setLoading(false))
    },
    openInput () {
      document.getElementById('avatar').click()
    },
    changeAvatar (e) {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      this.$axios.post('/medias', formData).then(res => {
        const organizationId = this.$store.state.organization.activeOrganization.metadata.id
        const userId = this.$store.state.user.me.metadata.id
        this.$axios.patch(`/organizations/${organizationId}/users/${userId}`, {
          avatar: res.data.data.url
        }).then(() => {
          location.reload()
        })
      })
    }
  }
}
</script>

<style scoped>
  .card-user__background {
    min-height: 125px;
    background-color: #0093e9;
    background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  }
  .user-avatar__cursor {
    cursor: pointer;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: rgba(000,000, 000, 50%);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 150px;
    top: 100%;
    margin-top:8px;
    left: 50%;
    transform: translateX(-50%)
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
</style>
