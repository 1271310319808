import { render, staticRenderFns } from "./DialogPreviewExam.vue?vue&type=template&id=166e423c&scoped=true&"
import script from "./DialogPreviewExam.vue?vue&type=script&lang=js&"
export * from "./DialogPreviewExam.vue?vue&type=script&lang=js&"
import style0 from "./DialogPreviewExam.vue?vue&type=style&index=0&id=166e423c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "166e423c",
  null
  
)

export default component.exports