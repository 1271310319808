<template>
  <div>
    <v-card class="card-height  d-flex justify-center text-center flex-column align-center py-1" :color="color">
      <div class="text-h6 text-break pb-1 white--text div-font__size">{{title}}</div>
      <div class="text-h5 text-break white--text">{{text}}</div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['color', 'title', 'text', 'icon']
}
</script>

<style>
.card-height {
  height: 8rem;
}
.div-font__size {
  font-size: 0.5rem;
}
</style>
