<template v-slot:top>
  <v-toolbar flat class="mb-8">
    <v-row>
      <v-col cols="12" lg="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="searchLabel"
          filled
          background-color="transparent"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>
<script>
export default {
  name: 'DatatableSearchAction',
  props: {
    searchLabel: String,
    items: Array
  }
}
</script>
