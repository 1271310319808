<template>
  <v-text-field
    :label="labelText"
    :rules="rulesOptions"
    :validate-on-blur="validateOnBlur"
    :outlined="outlinedOption"
    :dense="denseOption"
    :background-color="backgroundColor"
    :min="minOption"
    @change="$emit('changeValue', $event)"
    :value="fieldValue"
    v-mask="maskOption"
    return-masked-value
    :prepend-icon="prependIcon"
    :readonly="readOnly"
    :type="fieldType"
  ></v-text-field>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    labelText: String,
    backgroundColor: String,
    outlinedOption: Boolean,
    denseOption: Boolean,
    validateOnBlur: Boolean,
    rulesOptions: Array,
    minOption: String,
    fieldValue: String,
    maskOption: String,
    prependIcon: String,
    readOnly: Boolean,
    fieldType: String
  }
}
</script>
