import Vue from 'vue'
import Vuex from 'vuex'
import organization from './modules/organization'
import exams from './modules/exams'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    organization,
    exams
  },
  state: {
    sidebarDrawer: null,
    customizerDrawer: false,
    sidebarColor: 'white',
    sidebarBg: '',
    navbarColor: 'primary',
    setHorizontalLayout: false,
    loading: false,
    firstLoading: true,
    user: {
      level: 0,
      access_token: '',
      me: {}
    },
    alerts: [],
    selectedCourse: {}
  },
  mutations: {
    setSidebarDrawer (state, payload) {
      state.sidebarDrawer = payload
    },
    setCustomizerDrawer (state, payload) {
      state.customizerDrawer = payload
    },
    setSidebarColor (state, payload) {
      state.sidebarColor = payload
    },
    setNavbarColor (state, payload) {
      state.navbarColor = payload
    },
    setLayout (state, payload) {
      state.setHorizontalLayout = payload
    },
    setUserMe (state, payload) {
      state.user.me = payload
    },
    setUserToken (state, payload) {
      state.user.access_token = payload
      localStorage.setItem('jwtToken', state.user.access_token)
    },
    setUserLevel (state, payload) {
      state.user.level = payload
    },
    removeAlert (state) {
      state.alerts.shift()
    },
    setAlert (state, value) {
      state.alerts.push(value)
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setFirstLoading (state, payload) {
      state.firstLoading = payload
    },
    setSelectedCourse (state, payload) {
      state.selectedCourse = payload
    }
  },
  actions: {
    setDataAuth ({ commit }, payload) {
      commit('setUserToken', payload.token)
      commit('setUserMe', payload.meData)
    },
    alert ({ commit }, payload) {
      commit('setAlert', payload)
      setTimeout(() => { commit('removeAlert') }, 7000)
    },
    setFirstLoading ({ commit }, payload) {
      if (!payload) {
        commit('setFirstLoading', payload)
        commit('setLoading', payload)
      }
      if (this.state.firstLoading) {
        commit('setLoading', payload)
      }
    }
  },
  getters: {
    token (state) {
      return state.user.access_token
    },
    getMeUser (state) {
      return state.user.me.data
    },
    getUserLevel (state) {
      return state.user.level
    }
  }
})
