<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form :disabled="disabled">
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <v-autocomplete
                :items="exams"
                label="Avaliação"
                clearable
                required
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <label class="v-label v-label--active theme--light">
                <template class="text-caption grey">Aplicar Avaliações Para</template>
              </label>
              <v-radio-group mandatory v-model="scope" row class="my-0">
                <v-radio label="Turma(s)" value="classes"></v-radio>
                <v-radio label="Aluno(s)" value="students"></v-radio>
                <v-radio label="Série" value="gradeLevel"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <!-- VER DEPOIS -->
              <!-- tirar o * dos autocompletes -->
              <v-autocomplete
                :items="classes"
                v-show="scope === 'classes'"
                label="Turmas *"
                clearable
                multiple
                deletable-chips
                small-chips
                outlined
                dense
                background-color="transparent"
              ></v-autocomplete>
              <!-- VER DEPOIS -->
              <!-- Funcionalidade de selecionar diretamente os alunos ainda não existe -->
              <v-autocomplete
                :items="students"
                v-show="scope === 'students'"
                label="Alunos *"
                clearable
                multiple
                deletable-chips
                small-chips
                outlined
                dense
                background-color="transparent"
              ></v-autocomplete>
              <v-autocomplete
                :items="grades"
                v-show="scope === 'gradeLevel'"
                label="Série *"
                clearable
                deletable-chips
                small-chips
                outlined
                dense
                background-color="transparent"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="m-0">
            <v-col cols="4" class="py-0">
              <v-datetime-picker
                label="Data e Hora de Início"
                v-model="initDatetime"
                required
                :rules="[rules.required]"
                validate-on-blur
                dateFormat="dd/MM/yyyy"
                timeFormat="HH:mm"
                show-current="true"
                :text-field-props="textFieldProps"
                :time-picker-props="timeProps"
                :date-picker-props="dateProps"
              >
                <template slot="dateIcon">
                  <v-icon>fas fa-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>fas fa-clock</v-icon>
                </template>
                <template slot="actions" slot-scope="{ parent }">
                  <!-- VER DEPOIS -->
                  <!-- início imediato não possui funcionalidade -->
                  <v-btn color="indigo" text elevation="0" @click.native="parent.clearHandler">
                    <v-icon class="mr-1">mdi-clock-fast</v-icon>INÍCIO IMEDIATO
                  </v-btn>
                  <v-btn color="grey" text @click.native="parent.clearHandler">LIMPAR</v-btn>
                  <v-btn color="primary" text @click="parent.okHandler">OK</v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-datetime-picker
                label="Data e Hora Final"
                v-model="endDatetime"
                required
                :rules="[rules.required]"
                validate-on-blur
                dateFormat="dd/MM/yyyy"
                timeFormat="HH:mm"
                :text-field-props="textFieldProps"
                :time-picker-props="timeProps"
                :date-picker-props="dateProps"
              >
                <template slot="dateIcon">
                  <v-icon>fas fa-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>fas fa-clock</v-icon>
                </template>
                <template slot="actions" slot-scope="{ parent }">
                  <v-btn color="grey" text @click.native="parent.clearHandler">LIMPAR</v-btn>
                  <v-btn color="primary" text @click="parent.okHandler">OK</v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                label="Tempo Limite (Em Minutos) *"
                v-model="duration"
                background-color="transparent"
                v-mask="'####'"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn text v-show="disabled" @click="showHideDetails" color="primary" class="px-0 mb-6">
            <v-icon>{{ showHideIcon }}</v-icon>&nbsp;Detalhes
          </v-btn>
          <div v-show="showDetails">
            <v-row>
              <v-col cols="12" md="12" class="py-0 editor-box">
                <label class="v-label v-label--active theme--light">
                  <sup>Instruções da Prova</sup>
                </label>
                <editor
                  api-key="hsj14jk6yuwdcaxfshmwdc8hh94wjnlnldoj813o1c9lhore"
                  :init="editorConfig"
                  v-model="instruction"
                  :disabled="disabled"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-row align="center" class="mx-2">
                  <v-checkbox v-model="offline">
                    <template v-slot:label>
                      <span>Prova será aplicada na modalidade impressa offline.</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="gray" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                        </template>
                        <span>Os alunos realizarão as provas no papel e as respostas deverão ser inseridas no gabarito online posteriormente.</span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="offline">
              <v-col cols="12" class="py-0">
                <div
                  class="tw-bg-gray-50 tw-border tw-border-gray-200 tw-mb-4 tw-rounded-md tw-px-4 tw-pt-4"
                >
                  <v-file-input
                    v-model="file"
                    color="primary"
                    counter
                    label="PDF da prova para impressão"
                    placeholder="Selecione o PDF da prova"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" color="primary" dark label small>{{ text }}</v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >+{{ file.length - 2 }} arquivos(s)</span>
                    </template>
                  </v-file-input>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="py-0 pb-4">
                <v-checkbox
                  label="Mostrar questões embaralhadas para os alunos na resolução"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Mostrar alternativas embaralhadas para os alunos na resolução"
                  hide-details
                ></v-checkbox>
                <v-checkbox label="Exibir marcadores de alternativas (A, B, C, D ...)" hide-details></v-checkbox>
                <v-checkbox label="Capturar foto do aluno antes de iniciar resolução" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <v-row align-end v-show="!disabled">
            <v-col cols="12" md="12">
              <v-btn color="primary" elevation="0">Salvar Aplicação</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import DatetimePicker from 'vuetify-datetime-picker'

import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'

Vue.use(DatetimePicker)

export default {
  components: {
    editor: Editor
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    /* VER DEPOIS */
    /* Não existe uma busca de provas, alunos, ou turmas para serem selecionadas */
    file: [],
    offline: false,
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.'
    },
    scope: 'classes',
    grades: [],
    duration: null,
    instruction: null,
    showHideIcon: 'mdi-plus',
    exams: [
      'Prova 01 - Lorem ipsum - 5 pontos',
      'Prova 02 - Lorem ipsum - 5 pontos',
      'Prova 03 - Lorem ipsum - 5 pontos',
      'Prova 04 - Lorem ipsum - 5 pontos'
    ],
    students: ['Aluno 01', 'Aluno 02', 'Aluno 03', 'Aluno 04'],
    classes: ['Turma 01', 'Turma 02', 'Turma 03', 'Turma 04'],
    textFieldProps: {
      outlined: true,
      dense: true
    },
    initDatetime: null,
    endDatetime: null,
    timeProps: {
      format: '24hr',
      'no-title': true,
      color: 'blue'
    },
    dateProps: {
      'no-title': true,
      color: 'blue',
      min: new Date().toISOString().slice(0, 10)
    },
    editorConfig: {
      height: 200,
      menubar: false,
      external_plugins: {
        mathjax: '/js/tinymce-mathjax/plugin.min.js'
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen media',
        'insertdatetime media table paste code help wordcount',
        'tinymcespellchecker powerpaste'
      ],
      powerpaste_word_import: 'merge',
      powerpaste_html_import: 'merge',
      powerpaste_googledocs_import: 'merge',
      smart_paste: true,

      mathjax: {
        lib: '/js/tinymce-mathjax/es5/tex-mml-chtml.js'
      },
      toolbar:
        'undo redo | formatselect | bold italic underline forecolor backcolor | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | removeformat | charmap image media mathjax',
      language: 'pt_BR'
    }
  }),
  methods: {
    submit () {
      this.$v.$touch()
    },
    update () {
      alert('ok')
    },
    removeManager (item) {
      const index = this.managers.indexOf(item.name)
      if (index >= 0) this.managers.splice(index, 1)
    },
    removeTeacher (item) {
      const index = this.teachers.indexOf(item.name)
      if (index >= 0) this.teachers.splice(index, 1)
    },
    showHideDetails () {
      this.showDetails = !this.showDetails
      this.showHideIcon === 'mdi-plus'
        ? (this.showHideIcon = 'mdi-minus')
        : (this.showHideIcon = 'mdi-plus')
    }
  },
  computed: {
    fromDateDisp () {
      return this.fromDateVal
    }
  },
  mounted () {
    setTimeout(() => {
      this.loadingEditor = false
    }, 1300)
  }
}
</script>
