<template>
  <v-dialog v-model="dialog.show" width="700" class="tw-overflow-hidden">
    <v-card>
      <v-toolbar dark color="primary">
        <!-- <v-toolbar-title>Resumo da Avaliação</v-toolbar-title> -->
        <v-toolbar-title class="text-h5 lighten-2">Resumo da questão</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom  v-if="dialog.permissions.update">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon dark @click="dialogConfirm.show = true">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Excluir questão</span>
        </v-tooltip>
        <v-tooltip bottom v-if="dialog.permissions.update">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon dark :to="`/question/edit/${dialog.id}`">
              <v-icon>mdi-pencil-box-outline</v-icon>
            </v-btn>
          </template>
          <span>Editar questão</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon dark :to="`/question/edit/${dialog.id}/copy`">
              <v-icon>mdi-file-replace</v-icon>
            </v-btn>
          </template>
          <span>Duplicar e editar questão</span>
        </v-tooltip>
        <v-btn icon dark @click="dialog.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="mt-3 mb-3">
          <div>
            {{ dialog.teaching_stage.name }} -
            {{ dialog.curricular_component.name }} -
            {{ dialog.knowledge_area.name }}
          </div>
          <div class="tw-text-gray-600 tw-font-medium tw-text-xl">
            {{ dialog.matter ? dialog.matter + " - " : "" }}Questão
            {{ getQuestionType(dialog.wordings[0].data.type) }}
          </div>
          <div>{{ dialog.difficulty }}</div>
        </div>

        <v-card outlined class="grey lighten-3">
          <v-card-text>
            <div class="text--primary tw-max-h-80 overflow-x-hidden tw-overflow-y-scroll" ref="text" id="text">
              <div v-html="dialog.title" id="enunciado"></div>
              <multiple-choice-preview
                v-if="dialog.wordings[0].data.type === 'MULTIPLE_CHOICE'"
                :options="dialog.wordings[0].data.options" />

              <matrix-preview
              v-if="dialog.wordings[0].data.type === 'MATRIX'"
              :wordings="dialog.wordings" />

              <slider-preview
               v-if="dialog.wordings[0].data.type === 'SLIDER'"
               :sliderData="dialog.wordings[0].data.params"
              />

              <rating-preview
                v-if="dialog.wordings[0].data.type === 'RATING'"
                :value="dialog.wordings[0].data.params.length"
                :length="dialog.wordings[0].data.params.length"
              />

              <mutiple-choice-mutiple-answer-preview
                v-if="dialog.wordings[0].data.type === 'MULTIPLE_CHOICE_MULTIPLE_ANSWER'"
                :options="dialog.wordings[0].data.options"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text>
        <div v-if="dialog.tags && dialog.tags.length">
          <div>Tags</div>
          <v-chip-group active-class="deep-purple accent-4 white--text" column>
            <v-chip v-for="(item, index) in dialog.tags" :key="index">
              {{
              item
              }}
            </v-chip>
          </v-chip-group>
        </div>

        <div v-if="dialog.matters && dialog.matters.length">
          <div>Assuntos</div>
          <v-chip-group active-class="deep-purple accent-4 white--text" column>
            <v-chip v-for="(item, index) in dialog.matters" :key="index">
              {{
              item
              }}
            </v-chip>
          </v-chip-group>
        </div>

        <div v-if="dialog.competency_matrices && dialog.competency_matrices.length">
          <!-- <div>{{dialog.curricular_component.name}}</div> -->
          <div>Matriz de referência - Descritores</div>

          <v-chip-group active-class="deep-purple accent-4 white--text" column>
            <v-tooltip bottom v-for="(item, index) in dialog.competency_matrices" :key="index">
              <template v-slot:activator="{ on, attrs }">
                 <v-chip v-bind="attrs" v-on="on" >{{ item.type === "OWN" ? "Instituição" : item.type }} - {{ item.code }}</v-chip>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
          </v-chip-group>
        </div>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-avatar color="grey darken-3">
            <v-img class="elevation-6" alt :src="dialog.author.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <div>{{ dialog.infoAuthor }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
    <dialog-confirm
      class="ma-0"
      :dialog="dialogConfirm"
      @action="removeQuestion(dialog.id)"
      @action2="dialogConfirm.show = false"
      @close="dialogConfirm.show = false"
    ></dialog-confirm>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { getQuestionType } from '@/utils/question'
import { getMarker } from '@/utils/marker'
import MultipleChoicePreview from '@/components/wording-preview/MultipleChoicePreview.vue'
import MatrixPreview from '@/components/wording-preview/MatrixPreview.vue'
import SliderPreview from '@/components/wording-preview/SliderPreview.vue'
import RatingPreview from '@/components/wording-preview/RatingPreview.vue'
import MutipleChoiceMutipleAnswerPreview from '@/components/wording-preview/MutipleChoiceMutipleAnswer.vue'

export default {
  components: {
    MultipleChoicePreview,
    MatrixPreview,
    SliderPreview,
    RatingPreview,
    MutipleChoiceMutipleAnswerPreview
  },
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {
          show: false,
          teaching_stage: { name: '' },
          curricular_component: { name: '' },
          knowledge_area: { name: '' },
          tags: [],
          permissions: {
            update: false,
            delete: false
          },
          author: {
            avatar: '',
            name: ''
          },
          wordings: [
            {
              data: {
                type: ''
              }
            }
          ]
        }
      }
    }
  },
  data () {
    return {
      id: '',
      dialogConfirm: {
        show: false,
        title: 'Atenção',
        close: 'Cancelar',
        confirm: 'Excluir permanentemente',
        color: 'red',
        confirm2: 'Cancelar',
        text: 'Tem certeza que deseja excluir esta questão?',
        icon: {
          type: 'mdi-alert',
          color: 'yellow darken-2'
        }
      }
    }
  },
  beforeMounted () {
    this.reRender()
  },
  computed: {
    ...mapState(['user']),
    userId () {
      return this.user.me.metadata.id
    }
  },
  watch: {
    dialog (newValue, oldValue) {
      this.verifyPermission()
      this.$nextTick(() => {
        //   this.getHeigth()
        this.reRender()
      })
    }

  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    // verify permission in vuex user sorte
  },
  methods: {
    ...mapMutations(['setLoading']),
    getQuestionType,
    getMarker,
    verifyPermission () {
      if (this.dialog.author.id === this.userId) {
        this.dialog.permissions.update = true
      }
    },
    removeQuestion (questionId) {
      this.dialogConfirm.show = false
      this.dialog.show = false
      this.setLoading(true)
      const self = this

      this.$axios.delete(`/organizations/${this.id}/questions/${questionId}`)
        .then(() => {
          self.$store.dispatch('alert', { color: 'success', msg: 'Questão deletada com sucesso' })
          this.$emit('removeQuestion', { id: questionId })
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    reRender () {
      if (window.MathJax) {
        setTimeout(() => {
          const elements = document.getElementsByClassName('math-tex')

          window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])

          // rendering done

          if (elements.length) {
            for (const id in elements) {
              elements[id].style.cssText += ';display:block;'
              // elements[id].style.display = 'block'
            }
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>
.v-dialog--fullscreen {
  overflow-x: hidden;
}
</style>
