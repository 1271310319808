<template>
    <v-row justify="center">
      <v-dialog v-model="value" persistent :max-width="width" transition="dialog-bottom-transition">
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-divider />
          <v-card-text class="pb-0 pt-4">
            <slot></slot>
          </v-card-text>
          <v-divider />
          <v-card-actions class="mt-3 flex justify-end">
            <v-btn color="primary" :loading="sending" outlined @click="$emit('close')">{{ cancelBtn }}</v-btn>
            <v-btn color="primary" :loading="sending" @click="$emit('action')">{{ okBtn }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
export default {
  props: ['value', 'title', 'okBtn', 'cancelBtn', 'isEdit', 'width', 'sending']
}
</script>
