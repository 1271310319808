import api from '@/libs/axios'

const getOrganizations = async ({ commit }, payload) => {
  commit('SET_LOADING', true)
  api.defaults.headers.Authorization = `bearer ${payload.token}`
  await api.get(`/organizations?page=${payload.page || 1}`)
    .then(response => {
      if (response.data.items.length !== 0) {
        commit('SET_ORGANIZATIONS', response.data.items)
      }
      const active = localStorage.getItem('ACTIVE_ORGANIZATION')

      if (!active) {
        const id = response.data.items[0].metadata.id
        getOrganization({ commit }, id)
      } else {
        getOrganization({ commit }, active)
      }
    })
  commit('SET_LOADING', false)
}

const getOrganization = async ({ commit }, id) => {
  if (!id) {
    id = localStorage.getItem('ACTIVE_ORGANIZATION')
  }

  if (id) {
    await api.get(`/organizations/${id}`)
      .then(response => {
        commit('SET_ACTIVE_ORGANIZATION', response.data)
        localStorage.setItem('ACTIVE_ORGANIZATION', id)
      })
  }
}

const clearOrganization = async ({ commit }) => {
  localStorage.removeItem('ACTIVE_ORGANIZATION')
  commit('SET_ORGANIZATIONS', {})
  commit('SET_ACTIVE_ORGANIZATION', {})
}

export default {
  getOrganizations,
  getOrganization,
  clearOrganization
}
