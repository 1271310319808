<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="peoples"
      :sort-by="sortBy"
      class="table-user"
      loading-text="Buscando dados... Aguarde"
      :search="search"
      :show-select="showSelect"
      style="cursor: pointer"
      @toggle-select-all="selectAllToggle"
      :options.sync="paginationConfig"
      :server-items-length="paginationConfig.total"
      :loading="loading"
      :footer-props="{
        'disable-items-per-page': paginationConfig.total < 15,
        'items-per-page-options':[15, 30, 50, 100]}"
    >
      <template v-slot:top>
        <v-container class="my-2">
          <v-row>
            <v-col cols="10" lg="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="text.searchLabel"
                outlined
                dense
                background-color="transparent"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" lg="6" align="right">
              <v-menu bottom left v-if="displayActionItems">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="(item, i) in actionItems" :key="i">
                    <template v-if="!item.needSelect || (item.needSelect && showSelect)">
                      <v-list-item-title>
                        <v-icon class="mr-3">{{ item.icon }}</v-icon>
                        {{ item.title }}
                      </v-list-item-title>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data="{ item, isSelected }">
        <div
          class="d-flex align-center flex-column flex-sm-row tw-w-full"
          :class="isSelected ? 'selected' : ''"
          v-if="item.data"
        >
          <div class="mr-4 tw-px-4">
            <v-img
              v-if="item.data.user.avatar"
              :src="item.data.user.avatar"
              :width="$vuetify.breakpoint.xs ? '80px' : '45px'"
              class="rounded-circle img-fluid mb-3"
            ></v-img>
          </div>

          <div class="tw-text-xl sm:tw-text-lg tw-mb-3 sm:mb-0">
            {{ item.data.user.name }}
            <span
              v-if="item.data.user.email"
              class="subtitle-2 font-weight-regular d-block"
              :class="{'text-no-wrap': !$vuetify.breakpoint.xs}"
            >{{ item.data.user.email }}</span>
          </div>
          <!-- <div v-if="item.data.numberOfNames">
          <v-chip
            class="ml-4"
            color="primary"
            small
            label
            text-color="white"
          >+ {{ item.data.numberOfNames }}</v-chip>
          </div>-->
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <template v-if="item.selectDisabled">
          <v-tooltip bottom>
            <!-- eslint-disable-next-line -->
            <template v-slot:activator="{ on, attrs }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.selectDisabled"
                :disabled="item.selectDisabled"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
            <span>{{ item.data.disabledDescription || '' }}</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
        </template>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.data.roles="{ item }">
        <div class="d-flex align-center tw-justify-center tw-flex-wrap">
          <v-checkbox v-model="item.data.roles" label="Admin" value="ADMIN" class="mr-2"></v-checkbox>
          <v-checkbox
            v-model="item.data.roles"
            label="Pedagogo"
            value="ORG_PEDAGOGICAL_COORDINATOR"
            class="mr-2"
          ></v-checkbox>
          <v-checkbox v-model="item.data.roles" label="Técnico" value="TECHNICAL" class="mr-2"></v-checkbox>
          <v-checkbox v-model="item.data.roles" label="Financeiro" value="FINANCIAL" class="mr-2"></v-checkbox>
        </div>
      </template>

      <template v-slot:no-data>{{ text.emptyLabel }}</template>
    </v-data-table>

    <v-row>
      <v-col>
        <v-card-actions>
          <v-spacer />
          <button-update :sending="sending" @update="update"></button-update>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    tableUser: Boolean,
    text: {},
    headers: {},
    sortBy: {},
    items: {
      type: Array,
      required: true
    },
    showSelected: {},
    showSelect: {},
    disabledDescription: {},
    displayActionItems: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    pagination: {
      type: Object,
      default () {
        return {
          page: 1,
          itemsPerPage: 15,
          total: 0

        }
      }
    }
  },
  computed: {
    paginationConfig: {
      get: function () {
        return this.pagination
      },
      set: function (newValue) {
        this.$emit('update', newValue)
      }
    }
    // peoples: {
    //   get () {
    //     return this.items
    //   },
    //   set (val) {
    //     this.$emit('update', val)
    //   }
    // }
  },
  data: () => ({
    isSelected: null,
    id: '',
    disabledCount: 0,
    selected: [],
    peoples: [],
    sending: false,
    actionItems: [
      {
        title: 'Remover Seleção',
        icon: 'mdi-delete-outline',
        needSelect: true
      },
      { title: 'Exportar (.pdf)', icon: 'mdi-file-pdf-outline' },
      { title: 'Exportar (.xlsx)', icon: 'mdi-file-excel-outline' },
      { title: 'Exportar (.csv)', icon: 'mdi-file-delimited-outline' }
    ],
    dialog: false,
    singleSelect: false,
    search: ''
  }),
  methods: {
    selectAllToggle (props) {
      if (this.selected.length !== this.peoples.length - this.disabledCount) {
        this.selected = []
        const self = this
        props.peoples.forEach((item) => {
          if (!item.selectDisabled) {
            self.selected.push(item)
          }
        })
      } else this.selected = []
    },
    // handleClick (value) {
    //   this.$router.push({ path: value.url })
    // },
    /* setFavorite (item) {
      const index = this.peoples.indexOf(item)
      this.peoples[index].favorite = !this.peoples[index].favorite
    }, */
    formatPeoplesSave (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        const roles = []
        value.data.roles.forEach((rolesValue) => {
          roles.push({ name: rolesValue, operation: 'ADD' })
        })
        const obj = {
          user: {
            id: value.data.user.id
          },
          roles
        }

        arr.push(obj)
      })
      return arr
    },
    update () {
      this.sending = true
      const self = this

      this.$axios.post(`/organizations/${this.id}/people/sync`, { users: this.formatPeoplesSave(this.peoples) })
        .then(response => { self.$store.dispatch('alert', { msg: 'Dados atualizados com sucesso' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
        })
    }
  }

  /*  created () {
    //     return this.peoples
    this.peoples = JSON.parse(JSON.stringify(this.items))
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')

    // this.peoples = [{ ...this.items }]
    // const self = this
    // this.peoples.map((item) => {
    //   if (item.selectDisabled) self.disabledCount += 1
    // })
  } */
}
</script>
<style>
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row:last-child {
  padding-bottom: 40px !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__header {
  display: none !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 2px 0 !important;
  min-height: auto !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell {
  width: 100% !important;
  text-align: center !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell
  .v-simple-checkbox {
  width: 100% !important;
  text-align: left !important;
  padding: 0.5rem 1rem !important;
}

.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  flex-direction: column !important;
  align-items: flex-start;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell
  .font-datatable-item {
  min-width: 235px;
  width: 100%;
  word-wrap: normal;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 6px 18px !important;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  background: #efefef;
  border-radius: 10px;
  margin: 6px 18px;
}
.v-data-table__wrapper .v-data-table__mobile-table-row.v-data-table__selected {
  background: #ceebff !important;
}
</style>
