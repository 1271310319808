<template>
  <base-card :heading="page.title">
  <template v-slot:customButton>
     <v-btn color="primary" outlined @click="syncMixedCourses" icon class="mr-2">
      <template v-slot:default>
        <IconTooltip
          color="primary"
          icon="mdi-refresh"
          text="Atualizar e sincronizar turmas"
        />
      </template>
     </v-btn>
  </template>
    <v-container fluid class="down-top-padding">
      <v-row class="mt-3 mb-3">
        <v-col cols="12" md="4">
          <v-select
            background-color="transparent"
            clearable
            dense
            hide-details
            label="Filtrar por autor da turma"
            outlined
            :items="owners"
            v-model="filters.owner"
            @change="getMixedCourses()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            background-color="transparent"
            clearable
            dense
            hide-details
            label="Filtrar por período letivo"
            outlined
            :items="school_years"
            v-model="filters.school_year"
            @change="getMixedCourses()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            background-color="transparent"
            clearable
            dense
            hide-details
            label="Filtrar por turno"
            outlined
            :items="courses_shift"
            item-text="text"
            item-value="value"
            v-model="filters.shift"
            @change="getMixedCourses()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            background-color="transparent"
            clearable
            dense
            hide-details
            label="Filtrar por escola"
            outlined
            :items="schools_name"
            v-model="filters.school"
            @change="getMixedCourses()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select background-color="transparent" clearable dense hide-details label="Filtrar por tipo" outlined
            :items="types" v-model="filters.type" item-text="name" item-value="value" @change="getMixedCourses(true)"></v-select>
        </v-col>
      </v-row>
      <v-simple-table
      v-if="fetch"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" v-for="header in headers" :key="header">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="course in courses"
              :key="course.metadata.id"
            >
              <td>{{ course.data.school.name ?? '-' }}</td>
              <td>{{ course.data.course.name ?? course.data.classroom.name }}</td>
              <td>
                <v-btn @click="openDialogCreateCourse(course)" color="primary" small
                  v-if="course.data.classroom && !course.data.course.id">
                  <small>Importar</small>
                </v-btn>
                <v-btn @click="openDialogLinkCourseToClassroom(course)" color="purple" class="text-white" small v-else-if="!course.data.classroom.id">
                  <small>Vincular</small>
                </v-btn>
                <v-btn color="teal" class="text-white" small v-else-if="course.data.course.status === 'ARCHIVED'" @click="invokeActionDialogCourse('ACTIVE', course.data)">
                  <small>Ativar</small>
                </v-btn>
                <v-btn color="warning" small v-else-if="course.data.course.status === 'ACTIVE'" @click="invokeActionDialogCourse('ARCHIVED', course.data)">
                  <small>Arquivar</small>
                </v-btn>
              </td>
              <td>
                <CourseStatus :course="course" />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4" class="pt-5">
                <v-pagination v-model="pagination.page" :length="pagination.totalPage"></v-pagination>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
      <v-skeleton-loader
        v-for="i in 5"
        v-else
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
      <dialog-create-course @closeDialog="closeDialog()" @closeDialogWithoutEvent="dialogCreateCourse=false" :selected-course="selectedCourse" :dialog="dialogCreateCourse" title="Importar turma" :teaching-stages="teachingStages" />
      <dialog-confirm-action-in-course
        :dialog="dialogActionCourse.dialog"
        :headerMessage="`Deseja ${dialogActionCourse.headerMsg} essa turma?`"
        :bodyMessage="dialogActionCourse.bodyMsg"
        :confirmMessage="dialogActionCourse.confirmBtn"
        denyMessage="Cancelar"
        :action="dialogActionCourse.action"
        :updateEndpoint="dialogActionCourse.updateEndpoint"
        @closeDialog="closeActionDialogCourse()"
        @updateSuccess="loadData()" />
      <dialog-link-course
        :dialog="dialogLinkCourseToClassroom"
        :selected-course="selectedCourse"
        @closeDialog="closeDialog()"
        @closeDialogWithoutEvent="dialogLinkCourseToClassroom=false"
        @loading="setLoading($event)" />
    </v-container>
  </base-card>
</template>

<script>
import BaseCard from '@/components/common/BaseCard.vue'
import DialogConfirmActionInCourse from '../dialog/DialogConfirmActionInCourse.vue'
import DialogCreateCourse from '../dialog/DialogCreateCourse.vue'
import DialogLinkCourse from '../dialog/DialogLinkCourse.vue'
import CourseStatus from './CourseStatus.vue'
import IconTooltip from '@/components/tooltip/IconTooltip.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'CoursesManagementComponent',
  components: {
    BaseCard,
    DialogCreateCourse,
    DialogConfirmActionInCourse,
    DialogLinkCourse,
    CourseStatus,
    IconTooltip
  },
  data () {
    return {
      page: {
        title: 'Gerenciar turmas'
      },
      headers: [
        'Nome da escola',
        'Nome da turma',
        'Ações',
        'Status da turma'
      ],
      courses: [],
      owners: [],
      school_years: [],
      courses_shift: [],
      schools_name: [],
      selectedCourse: {},
      teachingStages: [],
      fetch: false,
      dialogCreateCourse: false,
      dialogLinkCourseToClassroom: false,
      dialogActionCourse: {
        dialog: false,
        headerMsg: '',
        bodyMsg: '',
        confirmBtn: '',
        action: '',
        updateEndpoint: ''
      },
      filters: {
        author: '',
        school_year: '',
        shift: '',
        school: '',
        type: ''
      },
      types: [
        {
          name: 'Apenas Pruvo',
          value: 'PRUVO'
        },
        {
          name: 'Apenas Google Sala de Aula',
          value: 'CLASSROOM'
        },
        {
          name: 'Pruvo & Google Sala de Aula',
          value: 'PRUVO_CLASSROOM'
        }
      ],
      organizationId: '',
      pagination: {
        page: 1,
        totalPage: 1
      }
    }
  },
  watch: {
    'pagination.page': {
      async handler (page) {
        this.pagination.page = page
        await this.getMixedCourses()
      }
    }
  },
  async mounted () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    await this.loadData()
  },
  methods: {
    ...mapMutations(['setLoading']),
    async loadData () {
      await this.getMixedCourses()
      await this.getTeachingStages()
    },
    async getMixedCourses (resetPage) {
      this.fetch = false
      if (resetPage) {
        this.pagination.page = 1
      }
      const response = await this.$axios.get(`/organizations/${this.organizationId}/mixed-courses`, {
        params: {
          'classroom.ownerEmail': this.filters.owner,
          'course.school_year': this.filters.school_year,
          'course.shift': this.filters.shift,
          'school.name': this.filters.school,
          type: this.filters.type,
          page: this.pagination.page
        }
      })
      this.pagination.totalPage = response.data.pagination.last_page
      this.courses = response.data.items
      this.mountFacets(response.data.facets)
      this.fetch = true
    },
    invokeActionDialogCourse (action, courseData) {
      const msg = action === 'ACTIVE' ? 'Ativar' : 'Arquivar'
      const { course, school } = courseData
      this.dialogActionCourse.updateEndpoint = `/organizations/${this.organizationId}/schools/${school.id}/courses/${course.id}`
      this.dialogActionCourse.headerMsg = msg.toLowerCase()
      this.dialogActionCourse.bodyMsg = `Esta ação irá ${msg.toLowerCase()} essa turma`
      this.dialogActionCourse.confirmBtn = msg
      this.dialogActionCourse.action = action
      this.dialogActionCourse.dialog = true
    },
    closeActionDialogCourse () {
      this.dialogActionCourse.dialog = false
    },
    mountFacets (facets) {
      this.owners = Object.keys(facets['classroom.ownerEmail'])
      this.school_years = Object.keys(facets['course.school_year'])
      this.courses_shift = Object.keys(facets['course.shift']).map(item => {
        return {
          text: this.translateCourseShift(item),
          value: item
        }
      })
      this.schools_name = Object.keys(facets['school.name'])
    },
    async getTeachingStages () {
      const teachingStagesResponse = await this.$axios.get(`/organizations/${this.organizationId}/lists/teaching-stages`)
      this.teachingStages = teachingStagesResponse.data.items
    },
    translateCourseShift (courseShift) {
      const shifts = {
        MORNING: 'Manhã',
        FULLTIME: 'Integral',
        MORNING_INTERMEDIATE: 'Intermediário - Manhã',
        AFTERNOON: 'Tarde',
        AFTERNOON_INTERMEDIATE: 'Intermediário - Tarde',
        NIGHT: 'Noite'
      }

      return shifts[courseShift]
    },
    openDialogCreateCourse (course) {
      this.selectedCourse = course.data.classroom
      this.dialogCreateCourse = true
    },
    openDialogLinkCourseToClassroom (course) {
      this.selectedCourse = course.data
      this.dialogLinkCourseToClassroom = true
    },
    closeDialog () {
      this.fetch = false
      this.selectedCourse = {}
      this.dialogLinkCourseToClassroom = false
      this.dialogCreateCourse = false
      this.loadData()
    },
    syncMixedCourses () {
      this.setLoading(true)
      this.$axios.post(`/organizations/${this.organizationId}/mixed-courses/sync`)
        .then(() => {
          this.$store.dispatch('alert', { msg: 'Turmas atualizadas com sucesso!', color: 'green' })
          this.loadData()
        })
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>

<style scoped>
.my-tabs {
  justify-content: flex-start;
}
.text-white {
  color: white;
}
</style>
