import collect from 'collect.js'
import store from '../store'

const organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')

export const formatFullName = (text) => {
  const lowerCaseString = text.toLowerCase()
  const stringSplitByWordsArr = lowerCaseString.split(' ')
  const stringWithFirstCapital = stringSplitByWordsArr.map(el => {
    return el.charAt(0).toUpperCase() + el.slice(1)
  })
  return stringWithFirstCapital.join(' ')
}

export const createUrlParamFromObj = (obj) => {
  return new URLSearchParams(obj).toString()
}

export const convertHtmlToText = (value) => {
  return value
    .replace(/<img([\w\W]+?)>/g, '')
    .replace(/&(nbsp);|<[^>]*>?/g, '')
    .replace(/\\([()])/g, '')
    .replace(/\{\{\{/g, '')
    .replace(/\}\}\}/g, '')
}

export const striphtml = (text) => {
  if (!text) return ''
  return text
    .replace(/<[^>]*>?/gm, '')
    .replace('\\(', '')
    .replace('\\)', '')
    .replace(/&nbsp;/g, '')
}

export const handleError = (error) => {
  collect(error.response.data.errors)
    .when(
      collect(error.response.data.errors).count() && error.response.status === 422,
      errorsList => {
        collect(errorsList).each(error => {
          collect(error).each(msg => {
            this.$store.dispatch('alert', { color: 'red', msg })
          })
        })
      })

  collect(error.response.data.message)
    .when(
      error.response.status !== 422,
      msg => {
        this.$store.dispatch('alert', { color: 'red', msg: collect(msg).first() })
      })
}

export const getMonth = (value) => {
  const month = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
  }
  return month[value]
}

export const isSuperAdmin = () => {
  return store.getters.getMeUser?.is_super_admin ?? false
}

export const hasOrganizationLevel = () => {
  return collect(store.getters.getMeUser.acl)
    .where('organization_id', '===', organizationId)
    .where('type', '===', 'ORGANIZATION')
    .count() > 0
}

export const hasSchoolLevel = () => {
  return collect(store.getters.getMeUser.acl)
    .where('organization_id', '===', organizationId)
    .where('type', '===', 'SCHOOL')
    .count() > 0
}

export const getCourseIds = () => {
  return collect(store.getters.getMeUser.acl)
    .where('organization_id', '===', organizationId)
    .where('type', '===', 'COURSE')
    .map(acl => acl.course_id)
    .unique()
    .all()
}

export const getSchoolCourseIds = () => {
  return collect(store.getters.getMeUser.acl)
    .where('organization_id', '===', organizationId)
    .where('type', '===', 'COURSE')
    .map(acl => acl.school_id)
    .unique()
    .all()
}

export const getSchoolIds = () => {
  return collect(store.getters.getMeUser.acl)
    .where('organization_id', '===', organizationId)
    .where('type', '===', 'SCHOOL')
    .map(acl => acl.school_id)
    .unique()
    .all()
}
