<template>
  <base-card :heading="heading">
    <template v-slot:customButton v-if="showOptions">
      <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in options"
              :key="index"
            >
              <v-list-item-title @click="$router.push(item.url)" class="tw-cursor-pointer">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
    </template>
    <v-list class="py-0">
      <v-list-item-group two-line v-if="fetch">
        <template v-if="items.length && items[0].data.user">
          <div v-for="(item, index) in items.slice(0, 5)" :key="item.data.user.id">
            <v-list-item
              target="_blank"
              :to="`${linkBase ? linkBase : '/user/profile/'}${item.data.user.id}`"
            >
              <v-list-item-avatar >
                <v-img :src="item.data.user.avatar  || ''"></v-img>
              </v-list-item-avatar>
              <v-list-item-content style="cursor: pointer">
                <v-list-item-title v-text="item.data.user.name  || ''"></v-list-item-title>
                <v-list-item-subtitle v-text="item.data.user.email"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in items.slice(0, 5)" :key="item.metadata.id">
            <v-list-item
              :to="`${linkBase ? linkBase : '/user/profile/'}${item.metadata.id}`"
            >
             <v-list-item-avatar v-if="item.data.avatar">
                <v-img :src="item.data.avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content style="cursor: pointer">
                <v-list-item-title v-text="item.data.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.data.email"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
          </div>
        </template>
      </v-list-item-group>
      <v-divider />
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn large :to="footerUrl" text color="primary">{{ footerText }}</v-btn>
    </v-card-actions>
  </base-card>
</template>
<script>
export default {
  props: {
    fetch: {
      type: Boolean
    },
    items: {
      type: Array,
      default: () => { return [] }
    },
    showOptions: Boolean,
    options: Array,
    heading: String,
    footerUrl: String,
    footerText: String,
    linkBase: String
  }
}
</script>
