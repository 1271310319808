<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form ref="formCourse" v-if="courses">
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="courses.name"
                label="Nome da turma *"
                required
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="courses.room"
                type="number"
                min="1"
                label="Número de sala *"
                required
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="courses.school_year"
                label="Ano letivo *"
                type="number"
                min="1"
                v-mask="'####'"
                hint="ex: 2022"
                required
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-select
                v-model="courses.shift"
                label="Turno *"
                :items="types"
                item-text="name"
                item-value="value"
                required
                :rules="[rules.requiredSelect]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" md="12">
                <div style="color: rgba(0, 0, 0, 0.6)">Etapa de ensino *:</div>
              <v-radio-group
                row
                class="ma-0"
                v-model="courses.teaching_stage"
                @change="changeTeachingStage"
                hide-details=""
              >
                <v-radio
                  v-for="item in teaching_stages"
                  :label="item.text"
                  :value="item.value"
                  :key="item.value"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                :items="school_grades"
                v-model="courses.school_grade"
                label="Ano escolar *"
                required
                :rules="[rules.requiredObj]"
                validate-on-blur
                chips
                outlined
                background-color="transparent"
                :loading="fetchingSchoolGrades"
                :disabled="!fetchSchoolGrades || fetchingSchoolGrades"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                :items="curricular_components"
                v-model="courses.curricular_components"
                label="Disciplinas *"
                outlined
                multiple
                chips
                background-color="transparent"
                :loading="fetchingCurricularComponents"
                :disabled="!fetchCurricularComponents || fetchingCurricularComponents"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <div class="text-caption grey--text px-1">
                * todos os campos são obrigatórios
              </div>
            </v-col>
          </v-row>
          <button-clear-submit
            v-on:clear="clear"
            :sending="sending"
            v-on:submit="submit"
            v-if="action === 'add'"
            class="mt-6"
          ></button-clear-submit>
          <button-update
            :sending="sending"
            v-on:update="update"
            v-else-if="action === 'edit'"
            class="mt-6"
          ></button-update>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    action: {
      type: String,
      default: ''
    },
    edit: {
      type: Boolean,
      default: false
    },
    selectedCourse: {
      type: Object,
      default () {
        return {}
      }
    },
    coursesProp: {
      type: Object,
      default () {
        return {
          status: 'ACTIVE'
        }
      }
    }
  },
  data: () => ({
    types: [
      { name: 'Manhã', value: 'MORNING' },
      { name: 'Tarde', value: 'AFTERNOON' },
      { name: 'Noite', value: 'NIGHT' },
      { name: 'Integral', value: 'FULLTIME' },
      { name: 'Intermediário - Manhã', value: 'MORNING_INTERMEDIATE' },
      { name: 'Intermediário - Tarde', value: 'AFTERNOON_INTERMEDIATE' }
    ],
    fetchSchoolGrades: false,
    fetchingSchoolGrades: false,
    fetchCurricularComponents: false,
    fetchingCurricularComponents: false,
    courses: {
      name: '',
      room: 0,
      school_year: 0,
      shift: '',
      school_grade: {},
      teaching_stage: {},
      curricular_components: [],
      classroom: {}
    },
    curricular_components: [],
    school_grades: [],
    teaching_stages: [],
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      requiredObj: v => (v && !!Object.entries(v).length >= 1) || 'Selecione ao menos uma opção.',
      requiredArray: v => (v && !!v.length >= 1) || 'Selecione ao menos uma opção.',
      requiredSelect: v => (v && !!v.length >= 1) || 'Selecione um turno.'
    },
    id: '',
    link: '',
    sending: false
  }),
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getTeachingStages(this.id)

    try {
      if (this.edit) {
        this.courses = this.coursesProp.data
        this.courses.teaching_stage = this.courses.teaching_stage.id
        this.getSchoolGrades(this.id, this.courses.teaching_stage)
        this.getCurricularComponents(this.id, this.courses.teaching_stage)
        this.courses.school_grade = this.courses.school_grade.id
        this.courses.curricular_components = this.courses.curricular_components_ids
        this.link = `organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}`
      } else {
        this.courses = this.coursesProp
        if (Object.keys(this.selectedCourse).length !== 0) { // if object is not empty
          this.courses.name = this.selectedCourse.name ? this.selectedCourse.name : ''
          this.courses.room = this.selectedCourse.room ? this.selectedCourse.room.replace(/^\D+/g, '') : '' // remove all leading non-digits
          this.courses.classroom_id = this.selectedCourse.id ? this.selectedCourse.id : ''
        }
        this.link = `organizations/${this.id}/schools/${this.$route.params.id}/courses`
      }
    } catch (error) {
    }
  },
  methods: {
    ...mapMutations(['setLoading', 'setSelectedCourse']),

    getTeachingStages (id) {
      this.setLoading(true)
      this.$axios.get(`organizations/${id}/lists/teaching-stages`)
        .then(response => {
          const arr = []
          response.data.items.forEach((value) => {
            arr.push({
              text: value.name,
              value: value.id
            })
          })
          this.teaching_stages = arr.slice()
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    async changeTeachingStage () {
      this.courses.school_grade = {}
      this.courses.curricular_components = []
      await this.getSchoolGrades(this.id, this.courses.teaching_stage)
      await this.getCurricularComponents(this.id, this.courses.teaching_stage)
    },
    getCurricularComponents (id, teachingStage) {
      this.fetchingCurricularComponents = true
      this.$axios.get(`organizations/${id}/lists/curricular-component?teaching_stage=${teachingStage}`)
        .then(response => {
          const arr = []
          response.data.items.forEach((value) => {
            arr.push({
              text: value.name,
              value: value.id
            })
          })
          this.curricular_components = arr.slice()
        })
        .finally(() => {
          this.fetchingCurricularComponents = false
          this.fetchCurricularComponents = true
        })
    },
    getSchoolGrades (id, teachingStage) {
      this.fetchingSchoolGrades = true
      this.$axios.get(`organizations/${id}/lists/school-grades?teaching_stage=${teachingStage}`)
        .then(response => {
          const arr = []
          response.data.items.forEach((value) => {
            arr.push({
              text: value.name + ' (' + value.teaching_stage_name + ')',
              value: value.id
            })
          })
          this.school_grades = arr.slice()
        })
        .finally(() => {
          this.fetchingSchoolGrades = false
          this.fetchSchoolGrades = true
        })
    },
    update () {
      if (!this.$refs.formCourse.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.setLoading(true)
      this.sending = true
      const ccs = this.generateCurricularComponetsPayload()
      const payload = {
        ...this.courses,
        teaching_stage: {
          id: this.courses.teaching_stage
        },
        school_grade: {
          id: this.courses.school_grade
        },
        curricular_components: ccs
      }
      this.$axios.patch(this.link, payload)
        .then(response => {
          try {
            this.$store.dispatch('alert', { msg: 'Turma editada com sucesso' })
            this.$router.push('/course/' + this.$route.params.id + '/' + response.data.metadata.id)
          } catch (e) {
            this.$router.push('/school/course/' + this.$route.params.id)
          }
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              this.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            this.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
          this.setLoading(false)
        })
    },
    submit () {
      if (!this.$refs.formCourse.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.setLoading(true)
      this.sending = true

      const payload = {
        ...this.courses,
        teaching_stage: {
          id: this.courses.teaching_stage
        },
        school_grade: {
          id: this.courses.school_grade
        },
        curricular_components: this.courses.curricular_components.map(value => {
          return { id: value }
        })
      }
      this.$axios.post(this.link, payload)
        .then(response => {
          try {
            this.setSelectedCourse({})
            this.$store.dispatch('alert', { msg: 'Turma cadastrada com sucesso' })
            this.$router.push('/course/' + this.$route.params.id + '/' + response.data.metadata.id)
          } catch (e) {
            this.$router.push('/school/course/' + this.$route.params.id)
          }
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              this.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            this.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
          this.setLoading(false)
        })
    },
    clear () {
      this.courses = {
        name: '',
        room: '',
        school_year: '',
        shift: '',
        teaching_stage: {},
        curricular_components: [],
        school_grade: {},
        classroom: {}
      }
      this.$refs.formCourse.reset()
      this.$refs.formCourse.resetValidation()
      this.fetchCurricularComponents = false
      this.fetchSchoolGrades = false
    },
    generateCurricularComponetsPayload () {
      if (!this.edit) {
        return
      }
      const ccs = []
      this.coursesProp.data.curricular_components.map(cc => {
        if (!this.courses.curricular_components.includes(cc)) {
          ccs.push({ id: cc, operation: 'REMOVE' })
        }
      })
      this.courses.curricular_components.map(cc => {
        ccs.push({ id: cc, operation: 'ADD' })
      })
      return ccs
    }
  }
}

</script>
