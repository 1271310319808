<template>
  <v-card-actions>
    <v-spacer />
    <v-btn large @click="$emit('save')" :disabled="disabled" color="primary">Salvar</v-btn>
  </v-card-actions>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
  })
}
</script>
