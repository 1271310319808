<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form ref="formSchool" v-if="school">
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="school.name"
                label="Nome da Escola *"
                required
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <form-address :address="school.address" :sending="sending" />
          <v-row>
            <v-col cols="6">
              <v-btn color="secondary" large @click="$router.push({
                path: action === 'edit' ? `/school/${$route.params.id}` : '/school',
                replace: true
              })">
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex justify-end align-center">
              <button-update
                :sending="sending"
                v-on:update="update"
                v-if="action === 'edit'"
              ></button-update>
              <button-clear-submit
                v-on:clear="clear"
                :sending="sending"
                v-on:submit="submit"
                v-if="action === 'add'"
              ></button-clear-submit>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    action: {
      type: String,
      default: ''
    },
    edit: {
      type: Boolean,
      default: false
    },
    schoolProp: {
      type: Object,
      default () {
        return {
          name: null,
          status: 'ACTIVE',
          address: {
            zip: null,
            street: null,
            neighborhood: null,
            number: null,
            city: null,
            state: null,
            country: 'Brasil'
          }
        }
      }
    }
  },
  data: () => ({
    school: {},
    link: '',
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.'
    },
    people: [],
    id: '',
    sending: false
  }),
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')

    try {
      if (this.edit) {
        this.school = this.schoolProp.data
        this.link = `/organizations/${this.id}/schools/${this.$route.params.id}`
      } else {
        this.school = this.schoolProp
        this.link = `/organizations/${this.id}/schools`
      }
    } catch (error) {

    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    update () {
      if (!this.$refs.formSchool.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.setLoading(true)
      this.sending = true
      const self = this
      this.$axios.patch(this.link, {
        ...this.school,
        address: {
          ...this.school.address,
          zip: this.school.address.zip.replace('-', '')
        }
      })
        .then(() => {
          self.$store.dispatch('alert', { msg: 'Dados da escola alterados com sucesso' })
          this.edit ? this.$router.push({
            path: `/school/${this.$route.params.id}`,
            replace: true
          }) : this.$router.push({
            path: '/school',
            replace: true
          })
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
          this.setLoading(false)
        })
    },
    submit () {
      if (!this.$refs.formSchool.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      // this.$v.$touch()
      this.setLoading(true)
      this.sending = true

      const self = this
      this.$axios.post(this.link, {
        ...this.school,
        address: {
          ...this.school.address,
          zip: this.school.address.zip.replace('-', '')
        }
      })
        .then(response => {
          self.$store.dispatch('alert', { msg: 'Escola cadastrada com sucesso' })
          this.$router.push('/school')
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
          this.setLoading(false)
        })
    },
    clear () {
      this.school = {
        name: null,
        status: 'ACTIVE',
        address: {
          zip: null,
          street: null,
          neighborhood: null,
          number: null,
          city: null,
          state: null,
          country: 'Brasil'
        }
      }
      this.$refs.formSchool.reset()
      this.$refs.formSchool.resetValidation()
    }
  }
}
</script>
