<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form ref="formOrg">
          <v-row class="m-0">
            <v-col cols="12" md="6" class="py-0">
              {{ target }}
              <v-select
                v-model="organizationData.company.segment"
                :items="industry"
                label="Segmento"
                value="value"
                item-text="name"
                required
                :rules="[rules.requiredSelect]"
                validate-on-blur
                :disabled="sending"
                outlined
                dense
                background-color="transparent"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="12" class="py-0">
              <!-- v-if="organizationData && organizationData.company.document.id" -->
              <v-text-field
                v-model="organizationData.company.name.legal"
                label="Nome Formal da Instituição"
                required
                :disabled="sending"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                v-model="organizationData.company.name.trade"
                label="Nome da Instituição"
                required
                :disabled="sending"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="organizationData.billing.email"
                label="E-mail"
                :rules="[rules.email, rules.required]"
                validate-on-blur
                :disabled="sending"
                required
                outlined
                dense
                background-color="transparent"
                hint="Para envio da Nota Fiscal."
                persistent-hint
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="organizationData.phone"
                v-mask="maskTel()"
                label="Telefone *"
                :rules="[rules.phone, rules.required]"
                required
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>-->
          </v-row>
        </v-form>
        <v-card-actions v-if="action !== 'new'">
          <v-spacer />
          <button-update :sending="sending" v-on:update="update"></button-update>
        </v-card-actions>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      default: 'edit'
    }
  },
  computed: {
    organizationData: {
      get () {
        return this.organization.data
      },
      set (val) {
        this.$emit('update', val)
        return val
      }
    }
  },
  data: () => ({
    sending: false,
    target: {},
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      requiredSelect: v => (v && !!v.length >= 1) || 'Selecione um segmento.',
      registeredNumber: v =>
        /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(v) ||
        'Favor inserir um documento válido.',
      email: (v) => {
        if (!/.+@.+/.test(v)) return 'Favor inserir um e-mail válido.'
        if (v.indexOf(' ') >= 0) return 'Não são permitidos espaços.'
      },
      phone: v => v.length >= 14 || 'Favor inserir um telefone válido.'
    }
  }),
  methods: {
    update () {
      if (!this.$refs.formOrg.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.sending = true
      const self = this
      const payload = JSON.parse(JSON.stringify(this.organizationData))
      delete payload.company.logo
      payload.company.document.id = payload.company.document.id.replace(/\D/g, '')
      this.$axios
        .put(`/organizations/${this.organization.metadata.id}`, payload)
        .then(response => {
          self.$store.dispatch('alert', {
            msg: 'Dados atualizados com sucesso'
          })
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          this.sending = false
        })
    }
  }
}
</script>
