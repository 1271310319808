var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSelectAnswer),expression:"!isSelectAnswer"}],staticClass:"my-5"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h2',{staticClass:"mb-5"},[_vm._v("Linhas")]),_vm._l((_vm.rows),function(row,index){return _c('div',{key:index,staticClass:"tw-flex tw-justify-center tw-items-center"},[_vm._v(" "+_vm._s(index + 1)+". "),_c('v-text-field',{staticClass:"pr-10 pl-5",attrs:{"placeholder":`Pergunta ${index + 1}`,"append-outer-icon":"mdi-delete"},on:{"keyup":function($event){return _vm.$emit('updateMatrixData', {
              rows: _vm.rows,
              columns: _vm.columns,
            })},"click:append-outer":function($event){return _vm.removeRow(index)}},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}})],1)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.addRow()}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-plus")])],1)],1)],2),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h2',{staticClass:"mb-5"},[_vm._v("Colunas")]),_vm._l((_vm.columns),function(column,index){return _c('div',{key:index,staticClass:"tw-flex tw-justify-center tw-items-center"},[_vm._v(" "+_vm._s(index + 1)+". "),_c('v-text-field',{staticClass:"pr-10 pl-5",attrs:{"placeholder":`Resposta ${index + 1}`,"append-outer-icon":"mdi-delete"},on:{"keyup":function($event){return _vm.$emit('updateMatrixData', {
              rows: _vm.rows,
              columns: _vm.columns,
            })},"click:append-outer":function($event){return _vm.removeColumn(index)}},model:{value:(column.value),callback:function ($$v) {_vm.$set(column, "value", $$v)},expression:"column.value"}})],1)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.addColumn()}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-plus")])],1)],1)],2),(_vm.rows.length)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h2',{staticClass:"mb-5"},[_vm._v("Pontos")]),_vm._l((_vm.rows),function(row,index){return _c('div',{key:index,staticClass:"tw-flex tw-justify-center tw-items-center"},[_c('v-text-field',{staticClass:"pr-10 pl-5 text-field__size",attrs:{"placeholder":"Pontos"},on:{"keyup":function($event){return _vm.$emit('updateMatrixData', {
              rows: _vm.rows,
              columns: _vm.columns,
            })}},model:{value:(row.points),callback:function ($$v) {_vm.$set(row, "points", $$v)},expression:"row.points"}})],1)})],2):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"tw-overflow-x-auto"},[_c('table',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelectAnswer),expression:"isSelectAnswer"}],staticClass:"my-10"},[_c('tr',[_c('th'),_vm._l((_vm.columns),function(column,columnIndex){return _c('th',{key:column,staticClass:"text-center"},[_vm._v(" "+_vm._s(column.value ?? `Resposta ${columnIndex}`)+" ")])})],2),_vm._l((_vm.rows),function(row,rowIndex){return _c('tr',{key:rowIndex},[_c('td',{staticClass:"pa-3 text-center"},[_vm._v(_vm._s(row.value ?? `Pergunta ${rowIndex}`))]),_vm._l((_vm.columns),function(column,columnIndex){return _c('td',{key:columnIndex},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateCorrectAnswer(row, columnIndex)}},model:{value:(row.selectedAnswer),callback:function ($$v) {_vm.$set(row, "selectedAnswer", $$v)},expression:"row.selectedAnswer"}},[_c('v-radio',{key:columnIndex,staticClass:"tw-flex tw-justify-center",attrs:{"value":columnIndex}})],1)],1)})],2)})],2)])],1),_c('div',{staticClass:"my-5"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","large":""},on:{"click":function($event){_vm.isSelectAnswer = _vm.isSelectAnswer ? false : true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.isSelectAnswer ? "mdi-table" : "mdi-file-document")+" ")]),_vm._v(" "+_vm._s(_vm.isSelectAnswer ? "Montar matriz" : "Selecionar respostas")+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }