<template>
  <v-container fluid class="pa-0">
    <v-card
      v-if="cardData.gradient"
      height="100"
      flat
      rounded="0"
      class="position-full primary-gradient-background"
    ></v-card>
    <v-card>
      <v-card-text >
        <!-- pp: {{ org.data.company.logo }} -->
        <slot></slot>
        <v-list>
          <div  class="tw-flex tw-flex-col md:tw-flex-row tw-flex-1 tw-items-center tw-justify-center" >
            <v-list-item-avatar v-if="cardData.editButton" color="primary" height="100" width="100">
              <img
                v-if="org.data.company.logo"
                :key="key"
                :src="org.data.company.logo"
                style="background-color: #fff; padding: 5px"
              />
              <img
                v-else
                src="@/assets/images/default.png"
                style="background-color: #fff; padding: 5px"
              />
            </v-list-item-avatar>

            <v-badge
              v-else
              avatar
              color="transparent"
              offset-x="40"
              offset-y="20"
              class="edit-image"
              style="margin-left: -16px"
            >
              <template v-slot:badge>
                <v-btn fab x-small color="primary" @click="changeTrigger">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </template>

              <v-list-item-avatar color="primary" height="100" width="100">
                <avatar-cropper
                  v-model="trigger"
                  :labels="{ submit: 'Ok', cancel: 'Cancelar' }"
                  :upload-handler="upload"
                />
                <img
                  v-if="org.data.company.logo"
                  :src="org.data.company.logo"
                  :key="key"
                  style="background-color: #fff; padding: 5px"
                />
                <img
                  v-else
                  src="@/assets/images/default.png"
                  style="background-color: #fff; padding: 5px"
                />
              </v-list-item-avatar>
            </v-badge>
            <v-list-item-content class="tw-text-center md:tw-text-left">
              <h1> {{org.data && org.data.company.name ? org.data.company.name.legal : 'Instituição sem nome'.toUpperCase()}} </h1>
              <h4
                class="py-2 font-weight-light"
              > {{org.data && org.data.company.name ? org.data.company.name.trade : 'Instituição sem nome'.toUpperCase()}} </h4>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-btn
                v-if="cardData.editButton"
                @click="$router.push({
                  path: 'courses-management',
                  replace: true
                })"
                outlined
                color="primary"
                class="mr-3"
                elevation="0"
              >Gerenciar turmas</v-btn>
              <v-btn
                v-if="cardData.editButton"
                :to="cardData.editUrl"
                outlined
                color="primary"
                elevation="0"
              >Editar perfil</v-btn>
              <v-btn v-else @click="$router.go(-1)" outlined color="primary" elevation="0">Voltar</v-btn>
            </v-list-item-action-text>
          </div>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import AvatarCropper from 'vue-avatar-cropper'
export default {
  components: { AvatarCropper },
  props: {
    cardData: {
      type: Object,
      default () {
        return {
          title: null,
          subtitle: null,
          image: null,
          gradient: false,
          editButton: false,
          editUrl: null
        }
      }
    }
  },
  data: () => ({
    trigger: false,
    key: 1,
    org: { data: { company: {} } }
  }),
  beforeMount () {
    this.org = this.activeOrganization
    this.key++
  },
  watch: {
    activeOrganization (newValue, oldValue) {
      this.org = this.activeOrganization
      this.key++
    }
  },
  methods: {
    ...mapMutations(['setLoading']),

    changeTrigger () {
      this.trigger = true
    },
    upload (cropper) {
      const img = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime)
      this.setLoading(true)
      const self = this
      this.$axios.patch(`/organizations/${this.org.metadata.id}`, { company: { logo: img } })
        .then(response => {
          this.org.data.company.logo = response.data.data.company.logo
          this.key++
          self.$store.dispatch('alert', { color: 'success', msg: 'Imagem atualizada com sucesso' })
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.setLoading(false)
        })
    }

  },
  computed: {
    ...mapGetters({
      organizations: 'organization/organizations',
      activeOrganization: 'organization/activeOrganization'
    })
  }
}

</script>
<style scoped>
.primary-gradient-background {
  border: 0 !important;
  background: rgb(30, 136, 229) !important;
  background: linear-gradient(
    180deg,
    rgba(30, 136, 229, 1) 0%,
    rgba(30, 136, 229, 0.5) 100%
  ) !important;
}
.position-full {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -24px;
}
</style>
