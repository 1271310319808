<template>
  <v-card class="mb-7">
    <v-card-text class="pa-0">
      <div
        class="rounded-t divider"
      ></div>
      <div class="pa-4 mt-n12 text-center">
        <div class="d-flex justify-center mt-n5">
          <img
            src="https://www.youbilingue.com.br/blog/wp-content/uploads/2019/06/capa-blog-diferenciar-escola-min.png"
            alt="user"
            class="rounded-circle elevation-1"
            width="128"
            height="128"
          />
        </div>
        <h2 class="mb-0 mt-4">{{ organization.name }}</h2>
        <p class="font-weight-regular">{{ organization.email }}</p>
      </div>
    </v-card-text>
    <v-divider />
    <v-container>
      <v-card-text>
        <span class="font-weight-medium">E-mail</span>
        <p class="font-weight-regular">{{ organization.email }}</p>
        <span class="font-weight-medium">Telefone</span>
        <p class="font-weight-regular">{{ organization.phone }}</p>
        <span v-if="organization.streetName" class="font-weight-medium">Endereço</span>
        <p v-if="organization.streetName" class="font-weight-regular">
          {{ organization.streetName
          }}
          <span v-if="organization.number">, {{ organization.number }}</span>
          <span v-if="organization.complement">, {{ organization.complement }}</span>
          <br />
          {{ organization.city }} - {{ organization.state }}
        </p>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
export default {
  props: {
    organization: {
      type: Object,
      default () {
        return {
          registeredNumber: null,
          industry: null,
          name: null,
          email: null,
          phone: null,
          zipCode: null,
          streetName: null,
          number: null,
          complement: null,
          district: null,
          city: null,
          state: null
        }
      }
    }
  },
  data: () => ({})
}
</script>

<style scoped>
.divider {
  min-height: 125px;
  background: rgb(30, 136, 229);
  background: linear-gradient(
  180deg,
  rgba(30, 136, 229, 1) 0%,
  rgba(30, 136, 229, 0.5) 100%
  );
}
</style>
