<template>
  <div>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <img width="30" :src="require(`@/assets/images/icons/${img.src}`)" :alt="img.alt" v-bind="attrs" v-on="on" />
        </template>
        <span>{{ img.tool_tip_text }}</span>
      </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CourseStatus',
  props: ['course'],
  data: () => {
    return {
      img: {
        type: '',
        src: '',
        alt: '',
        tool_tip_text: ''
      },
      assets: [
        {
          type: 'CLASSROOM',
          src: 'classroom_icon.png',
          alt: 'Classroom image',
          tool_tip_text: 'Turma vinculada ao Google Sala de Aula'
        },
        {
          type: 'PRUVO',
          src: 'pruvo_icon.png',
          alt: 'Pruvo image',
          tool_tip_text: 'Turma vinculada ao Pruvo'
        },
        {
          type: 'PRUVO_CLASSROOM',
          src: 'pruvo_classroom_icon.png',
          alt: 'Pruvo Classroom image',
          tool_tip_text: 'Turma vinculada ao Pruvo e ao Google Sala de Aula'
        }
      ]
    }
  },
  mounted () {
    if (!this.course) return
    if (this.course.data.type) {
      this.img = this.assets.find(el => this.course.data.type === el.type)
    }
  }
}
</script>
