const SET_SELECTED = (state, item) => {
  state.selectedQuestions.push(item)
}

const CLEAR_SELECTED = state => {
  state.selectedQuestions = []
}

const REMOVE_SELECTED = (state, item) => {
  const index = state.selectedQuestions.findIndex(x => x.id === item.id)
  state.selectedQuestions.splice(index, 1)
}

const SET_EXAM_LOADING = (state, item) => {
  state.examLoading = item
}

const SET_EXAM_SELECTED = (state, item) => {
  state.examSelected = item
}
const HIDDEN_EXAM_SELECTED = state => {
  state.examSelected.show = false
}

export default {
  REMOVE_SELECTED,
  SET_SELECTED,
  CLEAR_SELECTED,
  SET_EXAM_LOADING,
  SET_EXAM_SELECTED,
  HIDDEN_EXAM_SELECTED
}
