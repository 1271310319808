<template>
  <v-row justify="center">
    <v-dialog v-model="dialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar {{ dialog.title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- :key="people" -->
                <v-combobox
                  v-model="users"
                  :items="people"
                  :disabled="isLoading"
                  item-text="name"
                  filled
                  @change="getPeople($event)"
                  small-chips
                  label="Digite um nome"
                  multiple
                  persistent-hint
                  hint="Adicione um novo usuário digitando seu e-mail e apertando a tecla ENTER"
                >
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <!-- {{ people }} -->
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {
          show: false,
          title: 'Adicionar',
          linkSave: '',
          role: ''
        }
      }
    }
  },
  data: () => ({
    isLoading: false,
    search: '',
    people: [],
    users: [],
    _timerId: null
  }),
  methods: {
    ...mapMutations(['setLoading']),
    remove (item) {
      const index = this.users.findIndex(x => x.id === item.id)
      if (index >= 0) this.users.splice(index, 1)
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        const obj = {
          avatar: value.data.avatar,
          email: value.data.email,
          name: value.data.name,
          id: value.metadata.id
        }
        arr.push(obj)
      })
      return arr
    },
    formatPeoplesSave (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach(value => {
        const obj = {
          user: {},
          roles: [
            { name: this.dialog.role, operation: 'ADD' }
          ]
        }

        if (value.id) {
          obj.user.id = value.id
        } else {
          obj.user.email = value
        }

        arr.push(obj)
      })
      return arr
    },
    save () {
      let url
      this.$emit('close')
      this.setLoading(true)
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      const self = this
      const payload = this.formatPeoplesSave(this.users)
      if (this.$route.params.course) {
        url = `/organizations/${id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people/sync`
      } else {
        url = this.dialog.linkSave
      }
      this.$axios.post(url, { users: payload })
        .then(response => {
          if (response.data.message === 'License limit exceeded') {
            self.$store.dispatch('alert', { color: 'red', msg: 'Limite de licenças excedido!' })
          } else {
            self.$store.dispatch('alert', { msg: 'Adicionado com sucesso!' })
          }
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          self.$emit('save')
          self.setLoading(false)
          this.users = []
        })
    },
    getPeople (search = null) {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')

      this.isLoading = true

      this.$axios.get(`/organizations/${id}/users?search=${search}`)
        .then(response => {
          this.people = this.formatPeoples(response.data.items)
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
