<template>
  <v-simple-table
    fixed-header
    :dense="true"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th v-for="(item, index) in headers" :key="index">{{ item }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(question, index) in questions"
          :key="question.id"
          @click="$emit('click', index)"
        >
          <td>
            <ResolutionTooltip
            :grade="question.wordings[0].grade"
            :status="question.answer.status"
            :finalGrade="question.answer.final_grade"
            />
          </td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  >{{ getTitle(question).slice(0, 75) }}...</span>
              </template>
              <span>{{ getTitle(question) }}</span>
            </v-tooltip>
          </td>
          <td>{{ question.competency_matriz ? question.competency_matriz.code : 'Não há código'}}</td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  >{{question.competency_matriz.description.slice(0, 20)}}...</span>
              </template>
              <span>{{question.competency_matriz.description}}</span>
            </v-tooltip>
          </td>
          <td>{{ question.answer.final_grade }}</td>
          <td>{{ (question.answer.final_grade ? 'Sim' : 'Não') }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import ResolutionTooltip from '../tooltip/ResolutionTooltip.vue'

export default {
  props: ['questions'],
  components: { ResolutionTooltip },
  data () {
    return {
      headers: [
        '',
        'Questão',
        'Código da habilidade',
        'Habilidade',
        'Nota na questão',
        'Habilitado'
      ]
    }
  },
  methods: {
    getTitle (question) {
      const wordingOrder = question.answer.wording_order
      const wording = question.wordings[wordingOrder]
      if (wording.type === 'MATRIX') {
        return `${question.title} - ${wording.wording}`
      }
      return question.title
    }
  }
}
</script>
