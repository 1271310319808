<template>
    <v-rating
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        hover
        :value="value"
        :length="length"
        readonly
        color="yellow darken-3"
        background-color="grey darken-1"
    ></v-rating>
</template>

<script>
export default {
  name: 'RatingPreview',
  props: ['value', 'length']
}
</script>
