import {
  upperAlpha,
  lowerAlpha,
  upperRoman,
  lowerRoman
} from '@/constants/markers'

function getMarker (value = null, order = null) {
  switch (value) {
    case 'UPPER_ALPHA':
      return upperAlpha[order]
    case 'LOWER_ALPHA':
      return lowerAlpha[order]
    case 'DECIMAL':
      return order
    case 'UPPER_ROMAN':
      return upperRoman[order]
    case 'LOWER_ROMAN':
      return lowerRoman[order]
    default:
      return upperAlpha[order]
  }
}

function getMarkerType (value) {
  switch (value) {
    case 'UPPER_ALPHA':
      return 'A, B, C'
    case 'LOWER_ALPHA':
      return 'a, b, c'
    case 'DECIMAL':
      return '1, 2, 3'
    case 'UPPER_ROMAN':
      return 'I, II, III'
    case 'LOWER_ROMAN':
      return 'i, ii, iii'
    default:
      return 'Nenhum'
  }
}

function getMarkerKebabName (value) {
  switch (value) {
    case 'UPPER_ALPHA':
      return 'upper-alpha'
    case 'LOWER_ALPHA':
      return 'lower-alpha'
    case 'DECIMAL':
      return 'decimal'
    case 'UPPER_ROMAN':
      return 'upper-roman'
    case 'LOWER_ROMAN':
      return 'lower-roman'
    default:
      return 'upper-alpha'
  }
}

export { getMarker, getMarkerType, getMarkerKebabName }
