var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',{attrs:{"label":_vm.label,"items":_vm.items,"loading":_vm.loading,"disabled":_vm.disabled,"multiple":_vm.multiple,"chips":_vm.chips,"outlined":""},on:{"input":function($event){return _vm.$emit('input', _vm.value)}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.getMore,
          options: {
            rootMargin: '0px',
            threshold: 1.0,
          },
        }),expression:"{\n          handler: getMore,\n          options: {\n            rootMargin: '0px',\n            threshold: 1.0,\n          },\n        }"}]})]},proxy:true}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})
}
var staticRenderFns = []

export { render, staticRenderFns }