<template>
    <div>
      <div v-for="(option, index) in options" :key="index"
        class="tw-bg-gray-50 tw-border tw-border-gray-200 tw-mb-4 tw-rounded-md tw-px-4 tw-pt-4">
        <v-row class="pt-4 pb-2" justify="center">
          <v-col cols="10" class="py-0">
              <v-switch v-model="option.is_correct" @change="handleUpdate('UPDATE')" class="pa-0 ma-0 d-inline-block"
                  label="Alternativa Correta"></v-switch>
          </v-col>
          <v-col cols="2" class="py-0" align="end">
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="handleUpdate('REMOVE', index)" icon>
                          <v-icon v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                      </v-btn>
                  </template>
                  <span>Remover Alternativa</span>
              </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mb-4 relative">
          <v-col cols="12" class="py-0 editor-box mb-3">
            <editor
              v-model="option.answer"
              api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh"
              :init="editorConfig"
              modelEvents="change"
              @selectionChange="handleUpdate('UPDATE')"
            />
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12" align="center">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="handleUpdate('ADD')" fab dark color="primary">
                        <v-icon dark v-bind="attrs" v-on="on">mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>Adicionar Alternativa</span>
            </v-tooltip>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import editorConfig from '@/libs/editorConfig'
import collect from 'collect.js'

export default {
  name: 'CheckBoxQuestion',
  props: ['value', 'action'],
  components: {
    editor: Editor
  },
  data () {
    return {
      editorConfig,
      options: [],
      optionModel: {
        is_correct: false,
        answer: '',
        order: 0
      }
    }
  },
  created () {
    if (this.action !== 'edit') {
      this.handleUpdate('ADD')
      return
    }
    this.options = [...this.value]
  },
  methods: {
    handleUpdate (action, index = null) {
      const actions = {
        ADD: () => this.addOption(),
        REMOVE: () => {
          if (this.options.length === 1) {
            return
          }
          this.removeOption(index)
        },
        UPDATE: () => {}
      }
      if (actions[action]) {
        actions[action]()
        this.reOrder()
      }
      this.$emit('input', this.options)
    },
    addOption () {
      this.options.push(this.optionModel)
    },
    removeOption (index) {
      this.options.splice(index, 1)
    },
    reOrder () {
      this.options = collect(this.options).map((option, index) => {
        return {
          ...option,
          order: index
        }
      }).all()
    }
  }
}
</script>
