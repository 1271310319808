<template>
  <div class="pa-6">
    <v-row class="m-0">
      <v-col cols="12" md="6" class="py-0 tw-bg-white">
        <v-text-field
          v-model="searchQuery"
          @input="isTyping = true"
          append-icon="mdi-magnify"
          label="Pesquisar pessoas"
          outlined
          dense
          background-color="transparent"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-0 tw-bg-white"></v-col>
    </v-row>
    <v-row class="m-0">
      <v-col cols="12" md="6" class="py-0 tw-bg-white">
        <v-subheader>Pessoas</v-subheader>
        <v-progress-linear indeterminate color="light-blue lighten-3" v-if="isLoading"></v-progress-linear>
        <draggable
          v-model="peoples"
          group="people"
          class="tw-border-2 tw-bg-border-200 tw-rounded-xl tw-p-4"
          style="min-height: 10px"
        >
          <div v-for="(item, index) in peoples" :key="item.id">
            <v-menu
              v-model="item.menu"
              bottom
              right
              transition="scale-transition"
              origin="top left"
              class="ma-2"
            >
              <template v-slot:activator="{ on }">
                <v-chip pill v-on="on" class="ma-2 tw-p-2">
                  <v-avatar left>
                    <v-img :src="item.avatar"></v-img>
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
              <v-card width="400">
                <v-list dark>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="item.menu = false">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item :to="`/user/profile/${item.id}`" target="_blank">
                    <v-list-item-action>
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-list-item-action>
                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item @click="actionAdd(item, index)">
                    <v-list-item-action>
                      <v-icon>mdi-account-plus</v-icon>
                    </v-list-item-action>
                    <v-list-item-subtitle>Adicionar à turma</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </draggable>
      </v-col>
      <v-col cols="12" md="6" class="py-0 tw-bg-white">
        <v-subheader>{{ data.titleAdm }}</v-subheader>
        <v-progress-linear indeterminate color="light-green lighten-3" v-if="isLoadingAdm"></v-progress-linear>

        <!-- {{ formatAdm(adm) }} -->
        <draggable
          @change="checkMove"
          v-model="adm"
          group="people"
          class="tw-border-2 tw-border-blue-200 tw-rounded-xl tw-p-4"
        >
          <div v-for="(item, index) in adm" :key="item.id">
            <v-menu
              v-model="item.menu"
              bottom
              right
              transition="scale-transition"
              origin="top left"
              class="ma-2"
            >
              <template v-slot:activator="{ on }">
                <v-chip pill v-on="on" class="ma-2 tw-p-2">
                  <v-avatar left>
                    <v-img :src="item.avatar"></v-img>
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
              <v-card width="300">
                <v-list dark>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="item.menu = false">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item @click="() => {}">
                    <v-list-item-action>
                      <v-icon>mdi-briefcase</v-icon>
                    </v-list-item-action>
                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item @click="actionDelete(item, index)">
                    <v-list-item-action>
                      <v-icon>mdi-account-remove</v-icon>
                    </v-list-item-action>
                    <v-list-item-subtitle>Remover da turma</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
          <div class="text-center mt-2">
            <v-btn
              elevation="4"
              x-small
              color="primary"
              @click="seeMore()"
              :disabled="disableButton"
            ><small>Ver mais</small></v-btn>
          </div>
        </draggable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import draggable from 'vuedraggable'
import { mapMutations } from 'vuex'

export default {
  components: {
    draggable
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    id: '',
    sending: false,
    isTyping: false,
    isLoading: false,
    isLoadingAdm: false,
    searchQuery: '',
    menu: false,
    peoples: [],
    adm: [],
    filteredPeoples: [],
    page: {
      title: 'Administradores'
    },
    text: {
      searchLabel: 'Pesquisar administradores',
      emptyLabel: 'Nenhum administrador encontrado',
      addLabel: 'Adicionar administradores',
      addUrl: '/school/add',
      importLabel: 'Importar administradores',
      importUrl: '/school/add'
    },
    sortBy: 'name',
    showSelect: true,
    current_page: 1,
    disableButton: false,
    last_page: undefined
  }),
  watch: {
    searchQuery: debounce(function () {
      this.isTyping = false
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.getFilterPeople(this.searchQuery)
      }
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    checkMove (evt) {
      if (evt.moved) {
      } else if (evt.added) {
        this.modifyPeople(evt.added.element, 'ADD')
      } else if (evt.removed) {
        this.modifyPeople(evt.removed.element, 'REMOVE')
      }
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        if (value.data) {
          const obj = {
            roles: value.data.roles ? value.data.roles : null,
            avatar: value.data.avatar
              ? value.data.avatar
              : value.data.user.avatar,
            name: value.data.name ? value.data.name : value.data.user.name,
            email: value.data.email ? value.data.email : value.data.user.email,
            id: value.data.user ? value.data.user.id : value.metadata.id,
            menu: false
          }
          arr.push(obj)
        }
      })
      return arr
    },
    removeAlreadyAdded (newArray) {
      this.adm.forEach(a => {
        newArray.forEach(function (b, index, object) {
          if (a.id === b.id) {
            object.splice(index, 1)
          }
        })
      })
      return newArray
    },
    seeMore () {
      this.isLoadingAdm = true
      this.getAdmPeople()
    },
    actionAdd (element, index) {
      this.adm.push(element)
      this.peoples.splice(index, 1)
      this.modifyPeople(element, 'ADD')
    },
    actionDelete (element, index) {
      this.peoples.push(element)
      this.adm.splice(index, 1)
      this.modifyPeople(element, 'REMOVE')
    },
    getAdmPeople () {
      this.disableButton = true
      this.$axios.get(`${this.data.linkGetPeopleAdm}&page=${this.current_page}`).then(response => {
        this.adm.push(...this.formatPeoples(response.data.items))
        this.current_page = this.current_page + 1
        this.last_page = response.data.pagination.last_page
        if (this.current_page <= this.last_page) {
          this.disableButton = false
        }
      }).finally(() => (this.isLoadingAdm = false))
    },
    async getPeoples () {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      this.isLoading = true
      this.isLoadingAdm = true
      if (id) {
        this.getAdmPeople()

        this.$axios.get(`/organizations/${id}/users`).then(response => {
          const arr = this.formatPeoples(response.data.items)
          this.peoples = this.removeAlreadyAdded(arr)
        }).finally(() => (this.isLoading = false))
      }
    },
    async getFilterPeople (string) {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      this.isLoading = true
      await this.$axios
        .get(`/organizations/${id}/users?search=${string}`)
        .then(response => {
          const arr = this.formatPeoples(response.data.items)
          this.peoples = this.removeAlreadyAdded(arr)
        })
      this.isLoading = false
    },
    modifyPeople (people, operation) {
      this.sending = true
      const self = this
      const roles = people.roles?.map(role => {
        return {
          name: role, operation
        }
      })
      const payload = [
        {
          user: {
            id: people.id
          },
          roles: operation === 'ADD' ? [{ name: this.data.roles, operation }] : roles
        }
      ]
      this.$axios
        .post(this.data.linkSaveAdm, { users: payload })
        .then(response => {
          self.$store.dispatch('alert', {
            msg:
              operation === 'ADD'
                ? 'Adicionado com sucesso'
                : 'Removido com sucesso'
          })
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          this.sending = false
        })
    }
  },
  mounted () {
    this.getPeoples()
    this.page.title = this.data.title
  }
}
</script>
