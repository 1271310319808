<template>
    <v-datetime-picker
        v-model="value"
        @input="handleInput"
        :text-field-props="{ prependIcon: 'mdi-calendar'}"
        :time-picker-props="timeProps"
        date-format="dd/MM/yyyy"
        :label="label"
        time-format="HH:mm"
    >
        <template slot="dateIcon">
            <v-icon>mdi-calendar</v-icon>
        </template>
        <template slot="timeIcon">
          <v-icon>mdi-clock-outline</v-icon>
        </template>
        <template slot="actions" slot-scope="{ parent }">
            <v-btn color="lighten-1" @click.native="parent.clearHandler">Cancelar</v-btn>
            <v-btn color="darken-1" @click="parent.okHandler">Ok</v-btn>
        </template>
        </v-datetime-picker>
</template>

<script>
export default {
  props: ['value', 'label'],
  data: () => ({
    timeProps: {
      format: '24hr'
    }
  }),
  methods: {
    handleInput () {
      this.$emit('input', this.value)
    }
  }
}
</script>
