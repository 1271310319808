<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      class="contact-listing-app"
      :search="search"
      :show-select="showSelect"
      @click:row="handleClick"
      :class="[tableUser ? 'table-user' : 'table-data']"
      style="cursor: pointer"
      @toggle-select-all="selectAllToggle"
      :options.sync="paginationConfig"
      :server-items-length="paginationConfig.total"
      :footer-props="{
        'disable-items-per-page': paginationConfig.total < 10,
        'items-per-page-options': [10, 15, 30, 50, 100],
        'show-current-page':true
      }"
      multi-sort
      @update:sort-by="$emit('updateSort', 'column', $event)"
      @update:sort-desc="$emit('updateSort', 'desc', $event)"
    >
      <template v-slot:top v-if="showSearch || displayActionItems">
        <v-container class="my-2">
          <v-row>
            <v-col cols="10" lg="6" v-if="showSearch">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="text.searchLabel"
                outlined
                dense
                background-color="transparent"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" lg="6" align="left">
              <v-menu bottom left v-if="displayActionItems">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="(item, i) in actionItems" :key="i">
                    <template v-if="!item.needSelect || (item.needSelect && showSelect)">
                      <v-list-item-title @click="item.action">
                        <v-icon class="mr-3">{{ item.icon }}</v-icon>
                        {{ item.title }}
                      </v-list-item-title>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <div
          class="d-flex align-start tw-justify-start tw-w-full"
          :class="isSelected ? 'selected' : ''"
          @click="openProfile(item)"
        >
          <div class="mr-4">
            <v-img
              v-if="item && item.avatar"
              :src="item.avatar"
              :width="$vuetify.breakpoint.xs ? '80px' : '45px'"
              class="rounded-circle img-fluid"
            ></v-img>
          </div>
          <div class="tw-text-xl sm:tw-text-lg tw-mb-3 sm:mb-0">
            {{ item.name }}
            <span
              v-if="item.email"
              class="subtitle-2 font-weight-regular d-block"
              :class="{'text-no-wrap': !$vuetify.breakpoint.xs}"
            >{{ item.email }}</span>
          </div>

        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.roles="{ item }">
        <div class="d-flex align-start tw-justify-start tw-flex-wrap">
          <div
            v-for="i in item.roles"
            :key="i"
            class="mr-2 tw-bg-blue-400 tw-rounded-full tw-py-1 tw-px-2 tw-text-white tw-capitalize mt-2"
          >
            {{
            i === 'SCHOOL_PEDAGOGICAL_COORDINATOR' ? 'Coordenador' :
            i === 'PRINCIPAL' ? 'Diretor' :
            i === 'STUDENT' ? 'Aluno' :
            i === 'TEACHER' ? 'Professor' : ''
            }}
          </div>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-start tw-justify-start tw-flex-wrap">
            <v-chip
              class="ma-2"
              :color="item.status.color"
              outlined
            >
            {{ item.status.name }}
            </v-chip>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.reopen="{ item }">
          <v-btn
            :disabled="!item.reopen.enabled || ['STARTED'].includes(item.status.value)"
            class="ma-2"
            outlined
            rounded
            @click="openReopenDialog(item)">
            Reabrir aplicação
          </v-btn>
      </template>
      <template #item.correct="{ item }">
        <v-btn
          :disabled="!item.url"
          class="ma-2"
          outlined
          rounded
          @click="openTabWithCorrection(item.url)">
          Corrigir aplicação
        </v-btn>
    </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <template v-if="item.selectDisabled">
          <v-tooltip bottom>
            <!-- eslint-disable-next-line -->
            <template v-slot:activator="{ on, attrs }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.selectDisabled"
                :disabled="item.selectDisabled"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
            <span>{{ item.disabledDescription || '' }}</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
        </template>
      </template>
      <template v-slot:no-data>{{ text.emptyLabel }}</template>
    </v-data-table>
    <!-- Dialog para confirmar a reabertura -->
    <v-dialog :value="reOpenAppDialog"
      max-width="380"
      @click:outside="dialogReopenLoading ? null : closeReopenDialog()"
      persistent
      :hide-overlay="!dialogReopenLoading"
      >
      <v-card :loading="dialogReopenLoading">
        <v-card-title class="text-h5">
          Reabrir aplicação
        </v-card-title>
          <v-card-text>Deseja reabrir a aplicação do aluno <strong>{{itemSelected.student_name}}</strong>?</v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn
            color="primary"
            text
            @click="closeReopenDialog()"
            :disabled="dialogReopenLoading">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="reopenApplication(itemSelected)"
            :disabled="dialogReopenLoading">
            Reabrir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  props: {
    tableUser: Boolean,
    text: {},
    headers: {},
    sortBy: {
      type: String,
      default: ''
    },
    sortDesc: {},
    items: {},
    showSelected: {},
    showSelect: {},
    pagination: {
      type: Object,
      default () {
        return {
          page: 1,
          itemsPerPage: 10
        }
      }
    },
    disabledDescription: {},
    showSearch: {
      type: Boolean,
      default: false
    },
    displayActionItems: {
      type: Boolean,
      default: true
    },
    emit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isSelected: null,
    disabledCount: 0,
    selected: [],
    loading: false,
    reOpenAppDialog: false,
    actionItems: [
      {
        title: 'Cancelar Seleção',
        icon: 'mdi-delete-outline',
        needSelect: true
      },
      {
        title: 'Deletar Selecionados',
        icon: 'mdi-delete-outline',
        needSelect: true
      }
    ],
    dialog: false,
    singleSelect: false,
    search: '',
    itemSelected: {},
    dialogReopenLoading: false
  }),
  computed: {
    paginationConfig: {
      get: function () {
        return this.pagination
      },
      set: function (newValue) {
        this.$emit('update', newValue)
      }
    }
  },
  methods: {
    reopenApplication (i) {
      this.dialogReopenLoading = true
      this.$axios.post(`/organizations/${i.organization_id}/exams/${i.exam_id}/applications/${i.exam_application_id}/students/${i.student_id_for_reopen}/reopen`)
        .then(() => {
          this.$store.dispatch('alert', {
            color: 'green',
            msg: 'Avaliação reaberta com sucesso'
          })
        })
        .catch(error => {
          if (error.response.status === 428) {
            this.$store.dispatch('alert', {
              color: 'red',
              msg: 'Não foi possível reabrir essa aplicação'
            })
          }
        })
        .finally(() => {
          this.closeReopenDialog()
          this.dialogReopenLoading = false
          this.$emit('reloadStudentList', true)
        })
    },
    openProfile (i) {
      if (!i.profileUrl) return
      this.$router.push({ path: i.profileUrl })
    },
    selectAllToggle (props) {
      if (this.selected.length !== this.items.length - this.disabledCount) {
        this.selected = []
        const self = this
        props.items.forEach((item) => {
          if (!item.selectDisabled) {
            self.selected.push(item)
          }
        })
      } else this.selected = []
    },
    removeSelection () {
      this.selected = []
    },
    deletePeople () {
      this.$emit('delete', this.selected)
    },
    handleClick (item) {
      this.$emit('select-item', item)
    },
    openReopenDialog (item) {
      this.reOpenAppDialog = true
      this.itemSelected = item
    },
    closeReopenDialog () {
      this.reOpenAppDialog = false
      this.itemSelected = {}
    },
    openTabWithCorrection (url) {
      const routeData = this.$router.resolve({ path: url })
      window.open(routeData.href, '_blank')
    }
  },
  created () {
    const self = this
    this.items.map((item) => {
      if (item.selectDisabled) self.disabledCount += 1
    })
  },
  mounted () {
    this.actionItems = [
      {
        title: 'Cancelar Seleção',
        icon: 'mdi-delete-outline',
        action: () => { this.removeSelection() },
        needSelect: true
      },
      {
        title: 'Deletar Selecionados',
        icon: 'mdi-delete-outline',
        action: () => { this.deletePeople() },
        needSelect: true
      }
    ]
  }
}
</script>
<style scoped>
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row:last-child {
  padding-bottom: 40px !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__header {
  display: none !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 2px 0 !important;
  min-height: auto !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell {
  width: 100% !important;
  text-align: start !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell
  .v-simple-checkbox {
  width: 100% !important;
  text-align: left !important;
  padding: 0.5rem 1rem !important;
}

.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  flex-direction: column !important;
  align-items: flex-start;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell
  .font-datatable-item {
  min-width: 235px;
  width: 100%;
  word-wrap: normal;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 6px 18px !important;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  background: #efefef;
  border-radius: 10px;
  margin: 6px 18px;
}
.v-data-table__wrapper .v-data-table__mobile-table-row.v-data-table__selected {
  background: #ceebff !important;
}
</style>
