<template>
  <v-container class="py-4">
    <v-row v-if="!isFetching">
      {{ formatCoordinators() }}
      {{
      formatAdmins()
      }}
      {{ coordinatorsSelecteds }}
      <v-col cols="12" class="pb-4">
        <v-autocomplete
          no-filter
          v-model="adminsSelecteds"
          :items="peoples"
          :v-if="peoples"
          :return-object="true"
          outlined
          chips
          required
          :rules="[rules.required]"
          label="Administradores"
          item-text="data.user.name"
          multiple
          background-color="transparent"
          hint="Perfil permite acesso total ao Pruvo dentro da Instituição."
          persistent-hint
        >
          <template v-slot:selection="user">
            <v-chip
              v-bind="user.attrs"
              :input-value="user.selected"
              close
              @click="user.select"
              @click:close="removeManager(user.item)"
            >
              <v-avatar left>
                <v-img :src="user.item.data.avatar"></v-img>
              </v-avatar>
              {{ user.item.data.name }}
            </v-chip>
          </template>
          <template v-slot:item="user">
            <template v-if="(typeof user.item) !== 'object'">
              <v-list-item-content v-text="user.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="user.item.data.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="user.item.data.name"></v-list-item-title>
                <v-list-item-subtitle v-html="user.item.data.email"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          v-model="coordinatorsSelecteds"
          :items="peoples"
          :return-object="true"
          outlined
          required
          :rules="[rules.required]"
          chips
          label="Pedagogos"
          item-text="data.name"
          multiple
          background-color="transparent"
          hint="Perfil permite acesso a todas as funcionalidades pedagógicas do Pruvo dentro da Instituição."
          persistent-hint
        >
          <template v-slot:selection="user">
            <v-chip
              v-bind="user.attrs"
              :input-value="user.selected"
              close
              @click="user.select"
              @click:close="removeTeacher(user.item)"
            >
              <v-avatar left>
                <v-img :src="user.item.data.avatar"></v-img>
              </v-avatar>
              {{ user.item.data.name }}
            </v-chip>
          </template>
          <template v-slot:item="user">
            <template v-if="(typeof user.item) !== 'object'">
              <v-list-item-content v-text="user.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="user.item.data.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="user.item.data.name"></v-list-item-title>
                <v-list-item-subtitle v-html="user.item.data.email"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card-actions>
          <v-spacer />
          <button-update :sending="sending" v-on:update="update"></button-update>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { TheMask } from 'vue-the-mask'

export default {
  // components: { TheMask },
  data: () => ({
    isFetching: true,
    id: '',
    sending: false,
    peoples: [],
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.'
    },
    adminsSelecteds: [],
    coordinatorsSelecteds: []
  }),
  async mounted () {
    try {
      await this.getAdm()
    } catch (error) {
    }
  },
  methods: {
    async getAdm () {
      this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
      if (this.id) {
        await this.$axios.get(`/organizations/${this.id}/users`)
          .then(response => {
            this.peoples = response.data.items
            this.isFetching = false
          })
      }
    },
    /* submit () {
      this.$v.$touch()
    }, */
    update () {
      this.sending = true
      const self = this
      const payload = [
        ...this.formatAdmins()
      ]
      this.$axios.post(`/organizations/${this.id}/people/sync`, payload)
        .then(response => { self.$store.dispatch('alert', { msg: 'Dados atualizados com sucesso' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
        })
    },

    formatCoordinators () {
      const role = 'ORG_PEDAGOGICAL_COORDINATOR'
      const arr = []
      const coordinators = this.coordinatorsSelecteds
      if (!coordinators.length) return arr
      coordinators.forEach((value, index) => {
        const roles = value.data.roles.includes(role) ? value.data.roles : value.data.roles.push(role)
        const obj = {
          user: value.metadata.id,
          roles
        }
        arr.push(obj)
      })
      return arr
    },
    removeManager (item) {
      const index = this.adminsSelecteds.indexOf(item.metadata.id)
      if (index >= 0) this.adminsSelecteds.splice(index, 1)
    },
    removeTeacher (item) {
      const index = this.coordinatorsSelecteds.indexOf(item.metadata.id)
      if (index >= 0) this.coordinatorsSelecteds.splice(index, 1)
    }
  }
}
</script>
