<template>
  <v-container class="grey lighten-5">
      <v-row no-gutters>
        <v-col cols="12" v-if="!isDataNewEmpty" >
          <h3> Dados atualizados: </h3>
          <LogsPreviewTable :data="dataNew" />
        </v-col>
        <v-col cols="12" v-if="!isDataOldEmpty" >
          <h3> Dados antigos: </h3>
          <LogsPreviewTable :data="dataOld" />
        </v-col>
        <v-col cols="12" class="tw-flex tw-justify-center tw-items-center"
        v-if="isDataNewEmpty && isDataOldEmpty">
          <h2> Nenhum registro encontrado </h2>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import collect from 'collect.js'
import LogsPreviewTable from './LogsPreviewTable.vue'

export default {
  components: {
    LogsPreviewTable
  },
  props: {
    dataNew: {
      type: Object,
      default () {
        return {}
      }
    },
    dataOld: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    isDataNewEmpty () {
      return collect(this.dataNew).isEmpty()
    },
    isDataOldEmpty () {
      return collect(this.dataOld).isEmpty()
    }
  }
}
</script>
