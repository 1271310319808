<template>
  <div>
    <v-list>
      <v-list-item v-if="!selectedQuestions.length" @click.prevent>
        <v-list-item-content>
          <v-list-item-title>Nenhuma questão adicionada.</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <draggable v-else :move="changeOrder" :list="selectedQuestions" class="cursor-move">
        <transition-group two-line>
          <div v-for="(item, index) in selectedQuestions" :key="item.id">
            <v-list-item @click.prevent class="animation tw-border-b tw-border-gray-500">
              <v-list-item-content @click="openModal(index)" style="cursor: pointer">
                <v-list-item-title v-text="item.title"></v-list-item-title>

                <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>

                <v-list-item-subtitle v-text="item.infoAuthor"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="!$vuetify.breakpoint.xs">
                <v-list-item-action-text v-text="item.difficulty"></v-list-item-action-text>

                <v-icon @click="remove(item)" color="grey lighten-1">mdi-delete-outline</v-icon>
              </v-list-item-action>
            </v-list-item>
          </div>
        </transition-group>
      </draggable>
    </v-list>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    draggable
  },
  computed: {
    ...mapGetters({
      selectedQuestions: 'exams/selectedQuestions'
    })
  },
  methods: {
    ...mapMutations('exams', ['SET_SELECTED', 'REMOVE_SELECTED']),
    remove (item) {
      this.REMOVE_SELECTED(item)
    },
    changeOrder (e) {
      this.$emit('questionsOrder', e.relatedContext.list)
    }
  }
}
</script>
<style>
.animation {
  transition: all 0.5s;
}
</style>
