<template>
    <div>
      <v-row>
        <v-col cols="12" md="6">
          <AutoComplete
            v-model="selectedRole"
            label="Selecione uma função"
            :items="roles"
          />
        </v-col>
        <v-col cols="12" md="6">
          <AutoComplete
            v-model="selectedSchool"
            label="Selecione uma escola"
            :items="schools.items"
            :disabled="!selectedRole || schools.disabled"
            :loading="schools.loading"
            @loadMore="$emit('loadMore')"
          />
        </v-col>
        <v-col cols="12" md="12">
          <AutoComplete
            v-model="selectedCourse"
            label="Selecione uma turma"
            :items="courses"
            :disabled="!selectedSchool || coursesIsLoading"
            :loading="coursesIsLoading"
            @loadMore="loadMoreCourses()"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-btn
            color="primary"
            outlined
            @click="validadeList(selectedRole, selectedSchool, selectedCourse)"
            :disabled="!selectedSchool || !selectedRole || !selectedCourse"
          >
            Adicionar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-chip v-for="item in filteredList" :key="item"
            class="mr-2 mb-5"
            close
            color="primary"
            label
            outlined
            @click:close="removeItemFromList(item)"
          >
          {{ `${getRoleName(item.role)} | ${item.course_name ?? 'Nome da turma não encontrado'}` }}
        </v-chip>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import collect from 'collect.js'
import AutoComplete from '@/components/select/AutoComplete.vue'

export default {
  name: 'FormUserCourseRoles',
  props: ['value', 'schools'],
  components: { AutoComplete },
  data () {
    return {
      organizationId: '',
      courses: [],
      selectedRole: '',
      selectedSchool: '',
      selectedCourse: '',
      selectedList: [],
      coursesIsLoading: false,
      roles: [
        { name: 'Professor', value: 'TEACHER' },
        { name: 'Aluno', value: 'STUDENT' }
      ]
    }
  },
  created () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.selectedList = this.value
  },
  watch: {
    selectedSchool (value) {
      this.courses = []
      this.getCourses(false, value.value)
    },
    selectedList (value) {
      this.handleInput()
    }
  },
  computed: {
    filteredList () {
      return this.selectedList.filter(item => item.operation === 'ADD') ?? []
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.selectedList)
    },
    clearSelection () {
      this.selectedCourse = ''
      this.selectedRole = ''
      this.selectedSchool = ''
    },
    findCourse (school, course) {
      this.courseIsLoading = true
      return this.$axios.get(`/organizations/${this.organizationId}/schools/${school}/courses/${course}`)
        .then(data => data)
    },
    getCourseName (course) {
      return collect(this.courses).where('value', course).first().name
    },
    getRoleName (roles) {
      const roleName = []
      collect(this.roles).map(item => {
        if (roles.includes(item.value)) {
          roleName.push(item.name)
        }
      })
      return roleName.toString().replace(',', ' e ')
    },
    find (selectedSchool, selectedCourse) {
      return this.selectedList.find(value => (value.school === selectedSchool && value.course === selectedCourse))
    },
    validadeList (selectedRole, selectedSchool, selectedCourse) {
      const item = this.find(selectedSchool.value, selectedCourse.value)
      const index = this.selectedList.indexOf(item)

      if (!item) {
        this.addIntoList(selectedRole.value, selectedSchool.value, selectedCourse.value)
        return
      }

      if (!item.role.includes(selectedRole.value)) {
        this.selectedList[index].role.push(selectedRole.value)
      }

      if (item.operation === 'REMOVE') {
        this.selectedList[index].operation = 'ADD'
      }

      this.clearSelection()
    },
    addIntoList (selectedRole, selectedSchool, selectedCourse) {
      this.selectedList.push({
        role: [selectedRole],
        school: selectedSchool,
        course: selectedCourse,
        operation: 'ADD',
        course_name: this.getCourseName(selectedCourse)
      })
      this.clearSelection()
    },
    removeItemFromList (item) {
      this.selectedList = collect(this.selectedList)
        .where('course', '!==', item.course)
        .push({ ...item, operation: 'REMOVE' })
        .all()
    },
    loadMoreCourses () {
      if (this.courses.pagination.current_page === this.courses.pagination.last_page) {
        return
      }
      this.getCourses(true, this.selectedSchool.value)
    },
    getCourses (getMore = false, school) {
      if (!school) {
        return
      }

      if (!getMore) {
        this.courses.items = []
        this.courses.pagination = {
          current_page: 0
        }
      }

      this.coursesIsLoading = true

      this.$axios.post(`/organizations/${this.organizationId}/lists/courses?page=${this.courses.pagination.current_page + 1}`, { school })
        .then(response => {
          collect(response.data.items)
            .map(course => this.courses.push(
              { name: course.name, value: course.id }
            ))

          this.courses.pagination = {
            ...response.data.pagination,
            last_page: Math.ceil(response.data.pagination.total / response.data.pagination.per_page)
          }
        })
        .finally(() => {
          this.coursesIsLoading = false
        })
    }
  }
}
</script>
