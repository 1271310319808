<template>
    <div>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="selectedRole"
            label="Selecione um tipo de função dentro da escola"
            outlined
            :items="roles"
        />
        </v-col>
        <v-col cols="12" md="6">
          <AutoComplete
          v-model="selectedSchool"
          label="Selecione uma ou mais escolas"
          :loading="schools.loading"
          :disabled="schools.disabled"
          :items="schools.items"
          @loadMore="$emit('loadMore')"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-btn
            color="primary"
            outlined
            @click="validadeList(selectedRole, selectedSchool)"
            :disabled="selectedSchool === '' || selectedRole === ''"
          >
            Adicionar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-chip
            v-for="item in filteredList" :key="item"
            class="mr-2 mb-5"
            close
            color="primary"
            label
            outlined
            @click:close="removeItemFromList(item)"
          >
            {{ `${getRoleName(item.role)} | ${getSchoolName(item.school) ?? 'Nome da escola não encontrado'}` }}
        </v-chip>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import collect from 'collect.js'
import AutoComplete from '@/components/select/AutoComplete.vue'

export default {
  name: 'FormUserSchoolRoles',
  props: ['value', 'schools'],
  components: { AutoComplete },
  data () {
    return {
      selectedRole: '',
      selectedSchool: '',
      selectedList: [],
      roles: [
        { text: 'Coordenador', value: 'SCHOOL_PEDAGOGICAL_COORDINATOR' },
        { text: 'Diretor', value: 'PRINCIPAL' }
      ]
    }
  },
  created () {
    this.selectedList = this.value
  },
  watch: {
    selectedList () {
      this.handleInput()
    }
  },
  computed: {
    filteredList () {
      return collect(this.selectedList).where('operation', 'ADD').all()
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.selectedList)
    },
    clearSelected () {
      this.selectedRole = ''
      this.selectedSchool = ''
    },
    getRoleName (roles) {
      const roleName = []
      collect(this.roles).map(role => {
        if (roles.includes(role.value)) {
          roleName.push(role.text)
        }
      })
      return roleName.toString().replace(',', ' e ')
    },
    getSchoolName (value) {
      return collect(this.schools.items).where('value', value).first().name
    },
    find (selectedSchool) {
      return collect(this.selectedList).where('school', selectedSchool).first()
    },
    validadeList (selectedRole, selectedSchool) {
      const item = this.find(selectedSchool.value)
      const index = this.selectedList.indexOf(item)

      if (!item) {
        this.addIntoList(selectedRole, selectedSchool.value)
        return
      }

      if (!item.role.includes(selectedRole)) {
        this.selectedList[index].role.push(selectedRole)
      }

      if (item.operation === 'REMOVE') {
        this.selectedList[index].operation = 'ADD'
      }

      this.clearSelected()
    },
    addIntoList (selectedRole, selectedSchool) {
      this.selectedList.push({
        role: [selectedRole],
        school: selectedSchool,
        operation: 'ADD'
      })
      this.clearSelected()
    },
    removeItemFromList (item) {
      this.selectedList = collect(this.selectedList)
        .where('school', '!==', item.school)
        .push({ ...item, operation: 'REMOVE' })
        .all()
    }
  }
}
</script>
