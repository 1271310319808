<template>
  <v-expansion-panels flat divider="true">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="10" sm="4" class="font-weight-semibold">Plano</v-col>
            <v-col cols="10" sm="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <span v-if="open" key="0">Escolha um novo plano</span>
                <span v-else key="1">{{ plan }}</span>
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-select
          v-model="plan"
          :items="plans"
          label="Planos Disponíveis"
          outlined
          dense
          background-color="transparent"
        ></v-select>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="10" sm="4">Forma de Pagamento</v-col>
          <v-col cols="10" sm="8" class="text--secondary">
            <v-fade-transition leave-absolute>
              <span v-if="open" key="0">Escolha a forma de Pagamento</span>
              <span v-else key="1">{{ payment }}</span>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-select
          v-model="payment"
          :items="payments"
          label="Formas de Pagamento Disponíveis"
          outlined
          dense
          background-color="transparent"
        ></v-select>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="10" sm="4">Data de Vencimento</v-col>
          <v-col cols="10" sm="8" class="text--secondary">
            <v-fade-transition leave-absolute>
              <span v-if="open" key="0">Escolha a data de vencimento</span>
              <span v-else key="1">Dia 15 de todo mês</span>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content></v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  data: () => ({
    date: null,
    trip: {
      name: '',
      location: null,
      start: null,
      end: null
    },
    plans: ['Governo (Google for Education)', 'Governo (Basic)'],
    plan: 'Governo (Google for Education)',
    payments: ['Cartão de Crédito (Final: 8845)', 'Boleto Bancario'],
    payment: 'Cartão de Crédito (Final: 8845)'
  })
}
</script>
