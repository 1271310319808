import tinymce from '@tinymce/tinymce-vue'
import baseAPI from './baseApi'

const editorConfig = {
  height: 450,
  menubar: true,
  external_plugins: {
    mathjax:
          '/js/tinymce-mathjax/plugin.min.js'
  },
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen media',
    'insertdatetime media table code help wordcount',
    'tinymcespellchecker powerpaste export a11ychecker'
  ],
  // powerpaste_word_import: 'merge',
  powerpaste_word_import: function () {
    // use a native confirm dialog to prompt the user to choose between clean and merge
    return new Promise(function (resolve) {
      if (confirm('Deseja manter a formatação?')) {
        resolve('merge')
      } else {
        resolve('clean')
      }
    })
  },
  powerpaste_googledocs_import: function () {
    // use a native confirm dialog to prompt the user to choose between clean and merge
    return new Promise(function (resolve) {
      if (confirm('Deseja manter a formatação?')) {
        resolve('merge')
      } else {
        resolve('clean')
      }
    })
  },
  // powerpaste_html_import: function () {
  //   // use a native confirm dialog to prompt the user to choose between clean and merge
  //   return new Promise(function (resolve) {
  //     if (confirm('Deseja manter a formatação?')) {
  //       resolve('merge')
  //     } else {
  //       resolve('clean')
  //     }
  //   })
  // },
  powerpaste_html_import: 'clean',
  // powerpaste_html_import: 'merge',
  smart_paste: true,
  image_title: true,
  automatic_uploads: true,
  file_picker_types: 'image',
  file_picker_callback: function (cb, value, meta) {
    var input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.onchange = function () {
      var file = this.files[0]
      var reader = new FileReader()
      reader.onload = function () {
        var id = 'blobid' + new Date().getTime()
        var blobCache = tinymce.activeEditor.editorUpload.blobCache
        var base64 = reader.result.split(',')[1]
        var blobInfo = blobCache.create(id, file, base64)
        blobCache.add(blobInfo)
        cb(blobInfo.blobUri(), {
          title: file.name
        })
      }
      reader.readAsDataURL(file)
    }

    input.click()
  },
  images_upload_handler: function (blobInfo, success, failure, progress) {
    var xhr, formData
    const token = localStorage.getItem('jwtToken')

    xhr = new XMLHttpRequest()
    xhr.withCredentials = false
    xhr.open('POST', `${baseAPI}/medias`)
    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100)
    }

    xhr.onload = function () {
      var json

      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, {
          remove: true
        })
        return
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status)
        return
      }

      json = JSON.parse(xhr.responseText)

      if (!json || typeof json.data.url !== 'string') {
        failure('Invalid JSON: ' + xhr.responseText)
        return
      }

      success(json.data.url)
    }

    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
    }

    formData = new FormData()
    formData.append('file', blobInfo.blob(), blobInfo.filename())
    formData.append('collection', 'questions')

    xhr.send(formData)
  },
  a11ychecker_level: 'aaa',
  mathjax: {
    lib: '/js/tinymce-mathjax/es5/tex-mml-chtml.js',
    className: 'math-tex'
    // symbols: { start: '$$', end: '$$' }
  },
  toolbar:
        'undo redo | formatselect | bold italic underline forecolor backcolor | ' +
                     'alignleft aligncenter alignright alignjustify | ' +
                     'bullist numlist outdent indent | removeformat | charmap image media mathjax ' +
                     'export a11ycheck',
  language: 'pt_BR',
  spellchecker_language: 'pt_BR'
}
export default editorConfig
