<template >
  <v-row justify="center">
    <v-dialog v-model="dialog.show" max-width="500px" :retain-focus="false">
      <v-card>
        <v-toolbar dense flat class="px-1 py-2">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="pt-0">
          <v-icon v-if="dialog.name === 'excluir'" class="pl-3" x-large :color="dialog.icon.color">{{dialog.icon.type}}</v-icon>
          <span class="text-h5 text-break px-2">{{ dialog.title }}</span>
        </v-card-title>
        <v-card-text class="mb-0 pb-2">
          <v-container>
            <v-row >
              <v-col cols="12">
                  <slot />

          <span class="subtitle-1 pt-3 text-break">{{ dialog.text }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="dialog.confirm2" :color="dialog.confirm2Color" text @click="$emit('action2')">{{ dialog.confirm2 }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="dialog.color || 'blue darken-1'" text @click="$emit('action')">{{ dialog.confirm }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {
          show: false,
          title: 'Adicionar',
          confirm: 'Confirmar',
          confirm2: false,
          confirm2Color: String,
          close: 'Cancelar',
          text: 'Tem certeza que deseja excluir esta avaliação?',
          icon: {
            show: false,
            type: 'mdi-alert',
            color: 'yellow darken-2'
          }
        }
      }
    }

  },
  data: () => ({
    isLoading: false
  })

}
</script>

<style lang="scss" scoped>
</style>
