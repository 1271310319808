<template>
    <v-simple-table>
        <template #default>
            <thead>
                <tr>
                    <th class="text-center" v-for="header in keys" :key="header">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center" v-for="item in values" :key="item" v-html="item">
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import collect from 'collect.js'

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    keys () {
      return collect(this.data).keys()
    },
    values () {
      return collect(this.data).values()
    }
  }
}
</script>
