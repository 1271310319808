<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'#####'"
          v-model="value.min"
          label="Valor mínimo"
          outlined
          @input="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="value.max"
          label="Valor máximo"
          outlined
          v-mask="'#####'"
          @input="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="value.step"
          label="Passos"
          outlined
          v-mask="'#####'"
          @input="update"
        >
          <template v-slot:append>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              Valor padrão é 1
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SliderQuestion',
  props: ['value'],
  methods: {
    update () {
      const sliderData = {
        min: parseInt(this.value.min),
        max: parseInt(this.value.max),
        step: parseInt(this.value.step)
      }

      this.$emit('input', sliderData)
    }
  }
}
</script>
