<template>
  <div>
    <v-row class="my-5" v-show="!isSelectAnswer">
      <v-col cols="12" md="4">
        <h2 class="mb-5">Linhas</h2>
        <div
          class="tw-flex tw-justify-center tw-items-center"
          v-for="(row, index) in rows"
          :key="index"
        >
          {{ index + 1 }}.
          <v-text-field
            :placeholder="`Pergunta ${index + 1}`"
            @keyup="
              $emit('updateMatrixData', {
                rows,
                columns,
              })
            "
            class="pr-10 pl-5"
            v-model="row.value"
            append-outer-icon="mdi-delete"
            @click:append-outer="removeRow(index)"
          ></v-text-field>
        </div>
        <div class="text-center">
          <v-btn small @click="addRow()" fab dark color="primary">
            <v-icon small dark>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <h2 class="mb-5">Colunas</h2>
        <div
          class="tw-flex tw-justify-center tw-items-center"
          v-for="(column, index) in columns"
          :key="index"
        >
          {{ index + 1 }}.
          <v-text-field
            :placeholder="`Resposta ${index + 1}`"
            @keyup="
              $emit('updateMatrixData', {
                rows,
                columns,
              })
            "
            class="pr-10 pl-5"
            v-model="column.value"
            append-outer-icon="mdi-delete"
            @click:append-outer="removeColumn(index)"
          ></v-text-field>
        </div>
        <div class="text-center">
          <v-btn small @click="addColumn()" fab dark color="primary">
            <v-icon small dark>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="4" v-if="rows.length">
        <h2 class="mb-5">Pontos</h2>
        <div
          class="tw-flex tw-justify-center tw-items-center"
          v-for="(row, index) in rows"
          :key="index"
        >
          <v-text-field
            placeholder="Pontos"
            @keyup="
              $emit('updateMatrixData', {
                rows,
                columns,
              })
            "
            class="pr-10 pl-5 text-field__size"
            v-model="row.points"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="tw-overflow-x-auto">
        <table v-show="isSelectAnswer" class="my-10">
          <tr>
            <th></th>
            <th class="text-center" v-for="column, columnIndex in columns" :key="column">
              {{ column.value ?? `Resposta ${columnIndex}` }}
            </th>
          </tr>
          <tr v-for="row, rowIndex in rows" :key="rowIndex">
            <td class="pa-3 text-center">{{ row.value ?? `Pergunta ${rowIndex}` }}</td>
            <td v-for="(column, columnIndex) in columns" :key="columnIndex">
              <v-radio-group @change="updateCorrectAnswer(row, columnIndex)" v-model="row.selectedAnswer">
                <v-radio class="tw-flex tw-justify-center" :key="columnIndex" :value="columnIndex">
                </v-radio>
              </v-radio-group>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <div class="my-5">
      <v-btn
        color="primary"
        @click="isSelectAnswer = isSelectAnswer ? false : true"
        outlined
        large
      >
        <v-icon small>
          {{ isSelectAnswer ? "mdi-table" : "mdi-file-document" }} </v-icon
        >&nbsp;{{ isSelectAnswer ? "Montar matriz" : "Selecionar respostas" }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import collect from 'collect.js'

export default {
  name: 'Matrix',
  props: ['wordings'],
  data () {
    return {
      rows: [],
      columns: [],
      isSelectAnswer: false
    }
  },
  created () {
    this.rows = collect(this.wordings).pluck('data').map(row => {
      const selectedAnswer = collect(row.options)
        .values()
        .map((option, index) => {
          if (!option.is_correct) {
            return null
          }
          return index
        })
      return {
        value: row.wording,
        points: row.grade,
        selectedAnswer: collect(selectedAnswer).whereNotNull().first()
      }
    }).toArray()
    const options = collect(this.wordings).first().data.options
    this.columns = collect(options).map(option => {
      return {
        value: option.answer
      }
    }).toArray()
  },
  watch: {
    columns: {
      handler () {
        this.$emit('updateMatrixData', {
          rows: this.rows,
          columns: this.columns
        })
      }
    },
    rows: {
      handler () {
        this.$emit('updateMatrixData', {
          rows: this.rows,
          columns: this.columns
        })
      }
    }
  },
  methods: {
    addRow () {
      this.rows.push({
        value: null,
        selectedAnswer: null
      })
    },
    addColumn () {
      this.columns.push({
        value: null
      })
    },
    removeColumn (i) {
      this.columns.splice(i, 1)
    },
    removeRow (i) {
      this.rows.splice(i, 1)
    },
    updateCorrectAnswer (row, answerId) {
      row.selectedAnswer = answerId
      this.$emit('updateMatrixData', {
        rows: this.rows,
        columns: this.columns
      })
    }
  }
}
</script>
