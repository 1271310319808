export default () => ({
  selectedQuestions: [],
  examLoading: false,
  examSelected: {
    id: '',
    show: false,
    pagination: {
      current_page: 1,
      per_page: 10,
      prev_page_url: false,
      next_page_url: false
    },
    permissions: {
      update: false
    }
  }
})
