<template>
  <!-- eslint-disable -->
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" md="8" class="pb-0">
        <v-text-field
          @input="search.isTyping = true"
          v-model="searchQuery"
          label="Pesquisar"
          background-color="transparent"
          hide-details
          outlined
        >
          <template v-slot:append>
            <v-btn
              color="grey"
              class="v-bnt-advanced-search mt-n1"
              depressed
              background-color="transparent"
              text
              @click="toggle = !toggle"
            >Busca Avançada</v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="pb-0">
        <v-select
          :items="types"
          v-model="search.typeSearch"
          item-text="name"
          @change="setTypeSearch()"
          item-value="id"
          label="Tipo"
          outlined
          background-color="transparent"
        ></v-select>
      </v-col>
    </v-row>
    <v-container
      transition="slide-y-transition"
      v-show="toggle"
      class="rounded grey lighten-4 tw-p-4"
    >
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <v-autocomplete
           no-filter
            v-model="search.author"
            :items="author"
            :loading="isLoading"
            :search-input.sync="searchAuthor"
            item-text="name"
            item-value="id"
            label="Autor"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item="data">
              <template v-if="(typeof data.item !== 'object')">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <!-- eslint-disable-next-line -->
                  <img :src="data.item.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-autocomplete
            no-filter
            v-model="search.owner"
            :items="owner"
            :loading="isLoadingOwner"
            :search-input.sync="searchOwner"
            item-text="legal_name"
            item-value="id"
            label="Organização"
          >
            <template v-slot:item="data">
              <template v-if="(typeof data.item !== 'object')">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img :src="data.item.logo" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.legal_name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.trade_name"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="py-0">
          <v-select
            :items="difficulty"
            v-model="search.difficulty"
            item-text="name"
            :disabled="sending"
            item-value="value"
            label="Dificuldade *"
            background-color="transparent"
          ></v-select>
        </v-col>

        <v-col cols="12" md="4" class="py-0">
          <v-select
            :items="grades"
            v-model="search.grades"
            item-text="name"
            item-value="id"
            label="Grau"
            multiple
            background-color="transparent"
          ></v-select>
        </v-col>

        <v-col cols="12" md="4" class="py-0">
          <v-select
            :items="typesQuestion"
            v-model="search.typeQuestion"
            item-text="name"
            item-value="value"
            label="Tipo"
            background-color="transparent"
          ></v-select>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="py-0">
            <!-- @input="search.isTypingMatter = true" -->
          <v-text-field
            v-model="search.searchQueryMatter"
            label="Assunto"
            background-color="transparent"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
            <v-combobox
            v-model="search.tags"
            label="Tags"
            background-color="transparent"
            multiple
          >
            <!-- chips
            hide-selected
            hint="Pressione TAB ou ENTER para adicionar uma nova tag." -->
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                close
                @click:close="data.parent.selectItem(data.item)"
              >{{ data.item }}</v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="p-4" align="right" right>
          
              <v-spacer></v-spacer>
          <v-btn color="primary" depressed large @click="clearFilters()" class="tw-m-2">Limpar Filtros</v-btn>
          
          <v-btn color="primary" depressed large @click="getQuestions()" class="tw-m-2">Buscar</v-btn>
          
        </v-col>
      </v-row>
    </v-container>
    <v-list>
      <v-list-item>
        <v-list-item-action v-if="selection">
          <v-checkbox
            v-model="checkAll.checked"
            :indeterminate="checkAll.indeterminate"
            @change="selectAll($event)"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Questão</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item v-for="(item, i) in actionItems" :key="i" @click="item.action">
                  <v-list-item-title>
                    <v-icon class="mr-3">{{ item.icon }}</v-icon>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
      <v-progress-linear indeterminate color="light-blue lighten-3" v-if="!fetchQuestions"></v-progress-linear>

      <v-divider />
        <v-list-item-group 
          two-line 
          class="tw-relative tw-min-h-[8rem]"
        >
        <div v-for="(item, index) in items" :key="item.id">
          <v-list-item :color="item.checked ? 'blue lighten-3' : 'blue lighten-3'">
            <v-list-item-action v-if="selection">
              <v-checkbox @change="setIndeterminate($event, item)" v-model="item.checked" hide-details></v-checkbox>
            </v-list-item-action>
            <v-list-item-content @click="openModal(index)" style="cursor: pointer">
              <v-list-item-title v-text="item.title"></v-list-item-title>

              <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>

              <v-list-item-subtitle v-text="item.infoAuthor"></v-list-item-subtitle>
            </v-list-item-content>

           <v-list-item-action v-if="!$vuetify.breakpoint.xs">
              <v-icon color="grey lighten-1" @click="goTo(item.id)">mdi-file-document-edit-outline</v-icon>
              <v-list-item-action-text v-text="item.difficulty"></v-list-item-action-text>

              <v-icon
                v-if="!item.favorite"
                @click="setFavorite(index)"
                color="grey lighten-1"
              >mdi-star-outline</v-icon>

              <v-icon v-else @click="setFavorite(index)" color="yellow darken-3">mdi-star</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index < items.length - 1" :key="index + (items.length*2)"></v-divider>
        </div>
         <div v-if="loadingQuestions && !skeleton" class="tw-h-full tw-w-full tw-bg-gray-300 tw-bg-opacity-20 tw-absolute tw-top-0 tw-l-0 tw-flex tw-justify-center tw-items-center">
         <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
          <v-skeleton-loader
            v-if="loadingQuestions && skeleton"       
            v-bind="{
            class: 'py-4 mb-1',
            boilerplate: true,
            elevation: 0
            }"
            v-for="i in 10"
            :key="i"
            class="tw-border-b tw-border-gray-300"
            type="table-heading"
          ></v-skeleton-loader>
      </v-list-item-group>

      <v-divider />
    </v-list>
    <v-row justify="center">
      <v-col cols="12" align="center" class="pb-0 pt-4">
        <small>Total: {{ pagination.total }}</small>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6" class="py-0">
        <v-container class="max-width py-0">
          <v-pagination
            depressed
            :light="true"
            v-model="pagination.current_page"
            class="my-4"
            :length="Math.ceil(pagination.total/pagination.per_page)||0"
          ></v-pagination>

          <!-- <Pagination
            @next="getQuestions(1)"
            @prev="getQuestions(-1)"
            :disable-prev="!pagination.prev_page_url"
            :disable-next="!pagination.next_page_url"
          />-->
        </v-container>
      </v-col>
    </v-row>
    <template>
      <div class="text-center">
        <dialog-preview-question :dialog="dialog" />
      </div>
    </template>
  </v-container>
</template>
<script>
import debounce from 'lodash/debounce'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getQuestionDifficulty, formatWordings } from '@/utils/question'

export default {
  // components: { Pagination },
  props: {
    text: {},
    selection: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array
    },
    path: {
      type: String
    }
  },
  data: () => ({
    skeleton: true,
    loadingQuestions: false,
    selected: [2, 3, 4, 5],
    id: '',
    searchQuery: '',
    isLoading: false,
    isLoadingOwner: false,
    searchAuthor: '',
    searchOwner: '',
    author: [],
    owner: [],
    search: {
      isTyping: false,
      isTypingMatter: false,
      searchQueryMatter: '',
      grades: [],
      tags: [],
      author: '',
      owner: '',
      difficulty: '',
      wording_type: '',
      typeSearch: 0,
      typeQuestion: 'ALL'
    },
    sending: false,
    dialog: {},
    difficulty: [
      {
        value: 'VERY_EASY',
        name: 'Trivial'
      },
      {
        value: 'EASY',
        name: 'Fácil'
      },
      {
        value: 'MEDIUM',
        name: 'Média'
      },
      {
        value: 'HARD',
        name: 'Difícil'

      },
      {
        value: 'VERY_HARD',
        name: 'Complexa'
      }
    ],
    questionsFormated: [],
    typesQuestion: [
      { name: 'Todos', value: 'ALL' },
      { name: 'Objetiva', value: 'MULTIPLE_CHOICE' },
      { name: 'Discursiva', value: 'OPEN_ENDED' }
    ],
    grades: [
      '1º Ano',
      '2º Ano',
      '3º Ano'
    ],
    scrollY: false,
    showMore: false,
    fetchQuestions: false,
    pagination: {
      current_page: 1,
      per_page: 25,
      prev_page_url: false,
      next_page_url: false
    },
    attrs: {
      class: 'mb-1',
      boilerplate: true,
      elevation: 2
    },
    itemsSelect: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    checkAll: {
      checked: false,
      indeterminate: false
    },
    checked: [],
    toggle: false,
    actionItems: [

      // { title: 'Exportar (.pdf)', icon: 'mdi-file-pdf-outline' },
      // { title: 'Exportar (.xlsx)', icon: 'mdi-file-excel-outline' },
      // { title: 'Exportar (.csv)', icon: 'mdi-file-delimited-outline' }
    ],
    types: [
      { name: 'Todas as Questões', id: 0 },
      { name: 'Minhas Questões', id: 1 },
      { name: 'Questões da Instituição', id: 2 }
    ],
    items: []
  }),
  watch: {
    selectedQuestions () {
      // const copySelected = this.selectedQuestions
      const copySelected = [
        ...this.selectedQuestions,
        ...this.questionsFormated

      ]
      // copySelected.push(this.questions)
      this.items.forEach(function (b) {
        b.checked = false
      })
      copySelected.forEach((a) => {
        this.items.forEach(function (b) {
          if (a.id === b.id) {
            b.checked = true
          }
        })
      })
    },
    searchAuthor (value) {
      if (!value) {
        return
      }
      debounce(this.getAuthor, 1500)(value, this)
    },
    searchOwner (value) {
      if (!value) {
        return
      }
      debounce(this.getOwner, 1500)(value, this)
    },
    searchQuery: debounce(function () {
      this.search.isTyping = false
    }, 1000),
    'search.isTyping': function (value) {
      if (!value) {
        this.getQuestions()
      }
    },
    // searchQueryMatter: debounce(function () {
    //   this.search.isTypingMatter = false
    // }, 1000),
    // isTypingMatter: function (value) {
    //   if (!value) {
    //     this.getQuestions()
    //   }
    // },
    search: {
      handler: function () {
        // this.getQuestions()
      },
      deep: true
    },
    'pagination.current_page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getQuestions(true)
      }
    }

  },
  methods: {
    ...mapMutations('exams', ['SET_SELECTED', 'REMOVE_SELECTED']),
    getQuestionDifficulty,
    formatIds (ids) {
      const arr = []
      if (!ids) return arr
      ids.forEach((id) => {
        arr.push({ id })
      })
      return arr
    },
    checkSelected (arr) {
      const copySelected = [
        ...this.selectedQuestions,
        ...this.questionsFormated
      ]
      copySelected.forEach((a) => {
        arr.forEach(function (b) {
          if (a.id === b.id) {
            b.checked = true
          }
        })
      })
      return arr
    },
    getAuthor: async (value, self) => {
      if (!value) {
        self.author = []
      }
      // if (self.isLoading) {
      //   return
      // }
      self.isLoading = true
      await self.$axios.get(`/organizations/${this.id}/questions/authors?search=${value}`)
        .then(response => {
          self.author = response.data.data
        }).finally(() => (self.isLoading = false))
    },
    getOwner: async (value, self) => {
      if (!value) {
        self.owner = []
      }
      // if (self.isLoadingOwner) {
      //   return
      // }
      self.isLoadingOwner = true
      await self.$axios.get(`/organizations/${this.id}/questions/owners?search=${value}`)
        .then(response => {
          self.owner = response.data.data
        }).finally(() => (self.isLoadingOwner = false))
    },
    setTypeSearch () {
      const val = this.search.typeSearch
      if (val === 1) {
        this.search.author = this.userId
        this.search.owner = ''
      } else if (val === 2) {
        this.search.owner = this.id
        this.search.author = ''
      } else {
        this.search.author = this.search.owner = ''
      }
      this.getQuestions()
    },
    clearFilters () {
      this.search = {
        isTyping: false,
        isTypingMatter: false,
        searchQueryMatter: '',
        grades: [],
        tags: [],
        author: '',
        owner: '',
        difficulty: '',
        wording_type: '',
        typeSearch: 0,
        typeQuestion: 'ALL'
      }
    },

    formatArray (value) {
      if (!value) return
      return value.join(', ')
    },
    formatWordings,
    openModal (index) {
      this.scrollY = false
      const item = this.items[index]
      this.dialog = {
        ...item,
        show: true
      }
      // this.$nextTick(() => {
      //   this.getHeigth()
      // })
    },
    capitalize (string) {
      string = string.toLowerCase()
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formatQuestions (questions) {
      const arr = []
      if (!questions.length) return arr
      questions.forEach((value, index) => {
        let wordings = []

        if (value) {
          if (value.data.wordings[0].data.type === 'MULTIPLE_CHOICE' && value.data.wordings[0].data.options) {
            wordings = this.formatWordings(value.data.wordings[0].data.options)
          }
          const obj = {
            ...value.data,
            permissions: { ...value.permissions },
            favorite: false,
            checked: false,
            difficulty: this.getQuestionDifficulty(value.data.difficulty),
            title: value.data.main_text.replace(/<[^>]*>?/gm, '').replace('\\(', '').replace('\\)', '').replace(/&nbsp;/g, ''),
            subtitle: `Disciplina: ${value.data.curricular_component}, Área de conhecimento: ${value.data.knowledge_area}, Grau: ${this.formatArray(value.data.grades)}, Tags: ${this.formatArray(value.data.tags)}, Assunto: ${value.data.matter}`,
            infoAuthor: `Autor: ${this.capitalize(value.data.author.name)}${value.data.owner ? ' - ' + this.capitalize(value.data.owner.trade_name) : ''}`,
            id: value.metadata.id,
            wordings: [
              {
                ...value.data.wordings[0],
                data: {
                  ...value.data.wordings[0].data,
                  options: wordings
                }
              }
            ]
          }
          arr.push(obj)
        }
      })
      return arr
    },
    createLink (changePagination) {
      // grades: [],
      // author: [],
      const s = this.search
      let params = ''
      params += this.searchQuery ? `&search=${encodeURIComponent(this.searchQuery)}` : ''
      params += s.author ? `&author=${encodeURIComponent(s.author)}` : ''
      params += s.owner ? `&owner=${encodeURIComponent(s.owner)}` : ''
      params += s.difficulty ? `&difficulty=${encodeURIComponent(s.difficulty)}` : ''
      params += s.typeQuestion && s.typeQuestion !== 'ALL' ? `&wording_type=${encodeURIComponent(s.typeQuestion)}` : ''
      params += s.searchQueryMatter ? `&matter=${encodeURIComponent(s.searchQueryMatter)}&` : ''
      params += s.grades.length ? '&grades[]=' + s.grades.join('&grades[]=') : ''
      params += s.tags && s.tags.length ? '&tags[]=' + s.tags.join('&tags[]=') : ''
      const pagination = `&page=${changePagination ? this.pagination.current_page : 1}&per_page=${this.pagination.per_page}`
      return `/organizations/${this.id}/questions/questions?with_wordings=1${params}${pagination}`
    },
    async getQuestions (changePagination) {
      const self = this
      try {
        this.fetchQuestions = false
        this.loadingQuestions = true
        await this.$axios.get(this.createLink(changePagination))
          .then(response => {
            const formated = this.formatQuestions(response.data.items)
            this.items = this.checkSelected(formated)
            this.pagination = response.data.pagination
          })
      } catch (error) {
        const errosArray = Object.assign({}, error.response.data.errors)
        if (error.response.data.errors) {
          Object.keys(errosArray).forEach(function (item) {
            self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
          })
        }
      } finally {
        this.fetchQuestions = true
        this.loadingQuestions = false
      }
      // get adms
    },
    async actionStorageQuestion (question, operation) {
      this.sending = true
      const self = this
      const payload = [
        { id: question.id, operation }
      ]
      await this.$axios.patch(this.path, { questions: payload })
        .then(response => { self.$store.dispatch('alert', { msg: operation === 'ADD' ? 'Adicionado com sucesso' : 'Removido com sucesso' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
          this.skeleton = false
        })
    },
    setFavorite (index) {
      this.items[index].favorite = !this.items[index].favorite
    },
    goTo (id) {
      this.$router.push({ path: '/question/edit/' + id })
    },
    selectAll (event) {
      this.checkAll.checked = event
      this.items.forEach((element) => {
        element.checked = event
      })
    },
    removeSelection (event) {
      // this.selected = []
      this.checkAll = false
      this.items.forEach((element) => {
        element.checked = false
      })
    },
    async setIndeterminate (event, item) {
      if (!event) {
        try {
          this.REMOVE_SELECTED(item)
          this.checkAll.checked = false
          await this.actionStorageQuestion(item, 'REMOVE')
        } catch (error) {}
      } else {
        try {
          await this.actionStorageQuestion(item, 'ADD')
          this.SET_SELECTED(item)
        } catch (error) {}
      }
    }
    // getHeigth () {
    //   this.$nextTick(function () {
    //     this.showMore = this.$refs.text.clientHeight > 300
    //   })
    // }
  },
  computed: {
    ...mapGetters({
      selectedQuestions: 'exams/selectedQuestions'
    }),
    ...mapState(['user']),
    userId () {
      return this.user.me.metadata.id
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getQuestions()
    this.getAuthor('', this)
    this.getOwner('', this)
    this.questionsFormated = this.formatIds(this.questions)
    this.actionItems = [
      ...this.actionItems,
      {
        title: 'Remover Seleção',
        icon: 'mdi-delete-outline',
        action: () => { this.removeSelection() },
        needSelect: true
      }
    ]
  }

}
</script>
<style scoped>
.v-bnt-advanced-search {
  height: 30px !important;
  font-size: 12px !important;
}
</style>
