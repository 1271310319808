<template>
  <v-row class="pa-10 align-center">
    <v-col cols="12" md="6">
      <TextField :field-value="$store.state.user.me.data.name" @changeValue="name = $event" prepend-icon="mdi-account" label-text="Nome"/>
    </v-col>
    <v-col cols="12" md="6">
      <TextField :read-only="true" :field-value="user.data.email" prepend-icon="mdi-email" label-text="E-mail"/>
    </v-col>
    <v-col cols="12" md="6" class="d-flex align-center">
      <TextField :read-only="true" :field-value="organizationName" label-text="Nome da organização" prepend-icon="mdi-domain"/>
    </v-col>
    <v-col cols="12" md="6">
<!--      <TextField :read-only="true" :field-value="role" label-text="Função" prepend-icon="mdi-trophy-award"/>-->
    </v-col>
    <v-col cols="12" md="6">
      <v-tooltip bottom v-if="!changePassword">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="changePassword = true" color="primary">Alterar minha senha</v-btn>
        </template>
        <span>Essa senha é utilizada para login via Pruvo, não irá alterar a senha da conta Google</span>
      </v-tooltip>
      <v-row v-if="changePassword">
        <v-col>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Nova senha"
            counter
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="confirmPassword"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            label="Confirmar nova senha"
            counter
            @click:append="showConfirmPassword = !showConfirmPassword"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="primary" @click="save()" :disabled="sending">
        Salvar
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import TextField from '../form-fields/TextField'
import store from '../../store'
export default {
  name: 'FormMyProfile',
  components: { TextField },
  props: ['user', 'organizationName', 'role'],
  data: () => ({
    name: '',
    password: '',
    sending: false,
    changePassword: false,
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false
  }),
  mounted () {
    this.name = this.user.data.name
  },
  methods: {
    save () {
      this.sending = true
      if (this.password !== this.confirmPassword) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'As senhas precisam ser iguais'
        })
        this.sending = false
        return
      }
      const organizationId = this.$store.state.organization.activeOrganization.metadata.id
      const payload = {
        name: this.name
      }
      if (this.password) {
        payload.password = this.password
      }
      this.$axios.patch(`/organizations/${organizationId}/users/${this.user.metadata.id}`, payload)
        .then(() => {
          this.$store.dispatch('alert', {
            msg: 'Cadastro salvo com sucesso'
          })
          this.$emit('updatedUser')
          this.$axios.post('/auth/me').then(async res => {
            const meData = res.data
            await store.dispatch('setDataAuth', { meData })
          })
        }).finally(() => (this.sending = false))
    }
  }
}
</script>

<style scoped>

</style>
