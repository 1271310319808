const SET_ACTIVE_ORGANIZATION = (state, payload) => {
  state.activeOrganization = payload
}
const SET_ORGANIZATIONS = (state, payload) => {
  state.organizations = [...state.organizations, ...payload]
}

const SET_LOADING = (state, payload) => {
  state.loading = payload
}

export default {
  SET_ORGANIZATIONS,
  SET_ACTIVE_ORGANIZATION,
  SET_LOADING
}
