<template>
  <v-row justify="center">

    <v-tooltip top color="primary">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" @click="dialog = true" fab dark depressed color="primary">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Questões</span>
    </v-tooltip>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Adicionar Questões</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">OK</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <list-question :is-show-selected-questions="true" :selection="true" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-row>
</template>
<script>
import ListQuestion from '@/components/list/ListQuestion'

export default {
  components: {
    ListQuestion
  },
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false
    }
  }
}
</script>
