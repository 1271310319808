const roles = [
  { name: 'ADMIN', value: 'Administrador' },
  { name: 'TECHNICAL', value: 'Técnico' },
  { name: 'FINANCIAL', value: 'Financeiro' },
  { name: 'ORG_PEDAGOGICAL_COORDINATOR', value: 'Coordenador pedagógico' }
]

export {
  roles
}
