<template>
    <v-slider
        :max="sliderData.max"
        :min="sliderData.min"
        readonly
></v-slider>
</template>

<script>
export default {
  name: 'SliderPreview',
  props: ['sliderData']
}
</script>
