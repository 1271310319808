import Vue from 'vue'
import axios from 'axios'
import baseAPI from './baseApi'
const token = localStorage.getItem('jwtToken')

let api
if (token) {
  api = axios.create({
    baseURL: baseAPI,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      Authorization: 'bearer ' + token
    }
  })
} else {
  api = axios.create({
    baseURL: baseAPI,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json'
    }
  })
}

Vue.prototype.$axios = api

export default api
