<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text class="font-weight-bold pa-3 text-center mb-2">
        <div class="mb-3 text-uppercase loading-message">
          {{ message }}
        </div>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CardLoading',
  props: {
    message: String,
    dialog: Boolean
  }
}
</script>
