<template>
    <v-dialog :persistent="true" v-model="dialog">
        <v-card>
            <v-card-title class="text-h5">
                {{title}} - {{ selectedCourse.id }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mt-10">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-autocomplete :items="schools" label="Escola *" required validate-on-blur chips
                        outlined dense background-color="transparent" v-model="form.school"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Nome da turma *" v-model="form.courseName" dense outlined>
                            <v-tooltip bottom slot="prepend-inner">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-on="on" v-bind="attrs" color="dark" @click="form.courseName=selectedCourse.name">
                                        mdi-border-color
                                    </v-icon>
                                </template>
                                <span>Usar mesmo nome do Google Sala de Aula</span>
                            </v-tooltip>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field label="Número de sala *" v-model="form.room" dense outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field label="Ano letivo *" v-model="form.schoolYear" dense outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select :items="types" v-model="form.shift" item-text="name"
                        item-value="value" label="Turno *" dense outlined></v-select>
                    </v-col>
                </v-row>
                <v-row class="my-0">
                    <v-col cols="12" md="12">
                        <div class="mb-2">Etapa de ensino *:</div>
                        <v-radio-group row class="ma-0" hide-details="" v-model="teachingStageId" @change="getSchoolGradesAndCurricularComponents($event)">
                            <v-radio v-for="item in teachingStages" :label="item.name" :value="item.id" :key="item.id" />
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row class="m-0">
                    <v-col cols="12" md="6" class="py-0">
                        <v-autocomplete :items="schoolGrades" v-model="form.schoolGrade" label="Ano escolar *" required
                        validate-on-blur chips dense :disabled="!fetchData" :loading="loadingData" outlined background-color="transparent"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-autocomplete :items="curricularComponents" v-model="form.curricularComponents" label="Disciplinas *"
                            outlined multiple :disabled="!fetchData" :loading="loadingData" chips background-color="transparent"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="$emit('closeDialogWithoutEvent')">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="createCourse()">
                    Criar turma
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'DialogCreateCourse',
  props: ['dialog', 'title', 'selectedCourse', 'teachingStages'],
  data () {
    return {
      teachingStageId: null,
      schoolGrades: [],
      curricularComponents: [],
      schools: [],
      fetchData: false,
      loadingData: false,
      types: [
        { name: 'Manhã', value: 'MORNING' },
        { name: 'Tarde', value: 'AFTERNOON' },
        { name: 'Noite', value: 'NIGHT' },
        { name: 'Integral', value: 'FULLTIME' },
        { name: 'Intermediário - Manhã', value: 'MORNING_INTERMEDIATE' },
        { name: 'Intermediário - Tarde', value: 'AFTERNOON_INTERMEDIATE' }
      ],
      organizationId: null,
      form: {
        school: null,
        courseName: null,
        room: null,
        schoolGrade: null,
        shift: null,
        schoolYear: null,
        curricularComponents: []
      }
    }
  },
  methods: {
    createCourse () {
      this.$axios.post(`/organizations/${this.organizationId}/schools/${this.form.school}/courses`, {
        name: this.form.courseName,
        room: this.form.room,
        school_year: this.form.schoolYear,
        shift: this.form.shift,
        teaching_stage: {
          id: this.teachingStageId
        },
        school_grade: {
          id: this.form.schoolGrade
        },
        curricular_components: this.form.curricularComponents.map(id => {
          return { id }
        }),
        classroom_id: this.selectedCourse.id
      })
        .then(() => (self.$store.dispatch('alert', { msg: 'Turma cadastrada com sucesso' })))
        .catch(() => (self.$store.dispatch('alert', { color: 'red', msg: 'Erro ao cadastrar turma, tente novamente' })))
      this.closeDialog()
    },
    getSchoolGradesAndCurricularComponents (teachingStageId) {
      this.getSchoolGrades(this.organizationId, teachingStageId)
      this.getCurricularComponents(this.organizationId, teachingStageId)
    },
    getSchoolGrades (organizationId, teachingStageId) {
      this.loadingData = true
      this.$axios.get(`organizations/${organizationId}/lists/school-grades?teaching_stage=${teachingStageId}`)
        .then(response => {
          const arr = []
          response.data.items.forEach((value) => {
            arr.push({
              text: value.name + ' (' + value.teaching_stage_name + ')',
              value: value.id
            })
          })
          this.schoolGrades = arr.slice()
        })
        .finally(() => {
          this.fetchData = true
          this.loadingData = false
        })
    },
    getCurricularComponents (organizationId, teachingStageId) {
      this.loadingData = true
      this.$axios.get(`organizations/${organizationId}/lists/curricular-component?teaching_stage=${teachingStageId}`)
        .then(response => {
          const arr = []
          response.data.items.forEach((value) => {
            arr.push({
              text: value.name,
              value: value.id
            })
          })
          this.curricularComponents = arr.slice()
        })
        .finally(() => {
          this.fetchData = true
          this.loadingData = false
        })
    },
    closeDialog () {
      this.teachingStageId = ''
      this.form = {
        school: null,
        courseName: '',
        room: null,
        schoolGrade: null,
        shift: null,
        schoolYear: null,
        curricularComponents: []
      }
      this.getSchoolGradesAndCurricularComponents()
      this.$emit('closeDialog')
    }
  },
  mounted () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.$axios.post(`organizations/${this.organizationId}/lists/schools`)
      .then(response => {
        const arr = []
        response.data.items.forEach(value => {
          arr.push({
            text: value.name,
            value: value.id
          })
        })
        this.schools = arr.slice()
      })
  }
}
</script>
