<template>
    <v-form>
        <v-container>
            <v-row>
                <v-col class="pb-0" cols="12" md="6">
                    <v-text-field
                    v-model="form.name"
                    :rules="nameRules"
                    label="Nome *"
                    outlined
                    @input="handleInput"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-0" cols="12" md="6">
                    <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    label="E-mail *"
                    outlined
                    :disabled=isEdit
                    @input="handleInput"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-0" cols="12" md="6" v-if="!isEdit">
                    <v-text-field
                    v-model="form.password"
                    :rules="passwordRules"
                    type="password"
                    label="Senha *"
                    outlined
                    @input="handleInput"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-if="!isEdit"
                    v-model="isNeedToChangePassword"
                    label="Forçar mudança de senha no primeiro login"
                    @change="handleInput"
                  />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { roles } from '@/constants/roles'

export default {
  props: ['value', 'isEdit'],
  data: () => ({
    form: {
      name: '',
      password: '',
      email: '',
      acl: [],
      is_needed_to_change_password: false
    },
    nameRules: [
      v => !!v || 'O campo nome é obrigatório'
    ],
    passwordRules: [
      v => !!v || 'O campo senha é obrigatório'
    ],
    emailRules: [
      v => !!v || 'O campo e-mail é obrigatório',
      v => /.+@.+/.test(v) || 'O E-mail precisa ser valido'
    ],
    roles,
    isAclLoading: false

  }),
  watch: {
    value: {
      deep: true,
      handler () {
        this.form = this.value
      },
      immediate: true
    }
  },
  created () {
    this.form = this.value
  },
  methods: {
    handleInput () {
      this.$emit('change', this.form)
    }
  },
  computed: {
    isNeedToChangePassword: {
      get () {
        return this.form.is_needed_to_change_password
      },
      set (value) {
        this.form.is_needed_to_change_password = value
        this.handleInput()
      }
    }
  }
}
</script>
