import collect from 'collect.js'
import { convertHtmlToText } from './helpers'

function formatWordings (object) {
  if (object) {
    const arr = Object.keys(object).map(id => {
      const data = object[id]
      data.id = id
      return data
    })
    arr.sort((a, b) => {
      if (a.order > b.order) return 1
      if (a.order < b.order) return -1
      return 0
    })
    return arr
  }
}

function formatQuestions (questions) {
  return collect(questions).map(question => {
    return {
      permissions: { ...question.permissions },
      favorite: false,
      checked: false,
      difficulty: getQuestionDifficulty(question.data.difficulty),
      is_shared: question.data.is_shared,
      title: question.data.main_text,
      subtitle: `Disciplina: ${question.data.curricular_component.name
        }, Área de conhecimento: ${question.data.knowledge_area.name
        }, Tags: ${question.data.tags.length ? question.data.tags : 'Nenhuma'}
        , Assunto: ${question.data.matters.length ? question.data.matters : 'Nenhum'}`,
      infoAuthor: `Autor: ${question.data.author.name}`,
      matters: question.data.matters,
      tags: question.data.tags,
      competency_matrices: question.data.competency_matrices,
      id: question.metadata.id,
      wordings: question.data.wordings,
      author: question.data.author,
      type: question.data.wordings[0].data.type,
      teaching_stage: {
        name: question.data.teaching_stage.name
      },
      curricular_component: {
        name: question.data.curricular_component.name
      },
      knowledge_area: {
        name: question.data.knowledge_area.name
      }
    }
  }).collapse()
    .toArray()
}

function getQuestionType (type) {
  switch (type) {
    case 'OPEN_ENDED':
      return 'discursiva'
    case 'MULTIPLE_CHOICE':
      return 'objetiva'
    case 'TRUE_FALSE':
      return 'de verdadeiro/falso'
  }
}

function getQuestionDifficulty (value) {
  switch (value) {
    case 'VERY_EASY':
      return 'Trivial'
    case 'EASY':
      return 'Fácil'
    case 'MEDIUM':
      return 'Média'
    case 'HARD':
      return 'Difícil'
    case 'VERY_HARD':
      return 'Complexa'
    default:
      return ''
  }
}

export {
  formatQuestions,
  getQuestionType,
  getQuestionDifficulty,
  formatWordings
}
