<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form ref="formExam">
          <v-row>
            <v-col cols="12" class="py-0 pt-3">
              <v-text-field
                label="Título *"
                v-model="form.name"
                outlined
                required
                :rules="[rules.required]"
                validate-on-blur
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0 pt-3">
              <v-text-field
                v-model="form.description"
                label="Descrição *"
                required
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <div class="text-caption grey--text px-1">
                * todos os campos são obrigatórios
              </div>
            </v-col>
          </v-row>
          <button-save v-on:save="save" v-if="action === 'add'" class="mt-12"></button-save>
          <button-update v-on:update="update" v-else-if="action === 'edit'" class="mt-6"></button-update>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {},
  props: {
    action: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    rules: {
      required: value => (value && !!value.trim()) || 'Este campo é obrigatório.'
    },
    name: '',
    id: '',
    form: {
      name: '',
      instructions: ''
    }
    // editorConfig
  }),
  methods: {
    ...mapMutations(['setLoading']),
    save () {
      if (!this.$refs.formExam.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.setLoading(true)
      this.sending = true
      const self = this
      this.$axios
        .post(`/organizations/${this.id}/exams`, { ...this.form })
        .then(response => {
          self.$store.dispatch('alert', { msg: 'Cadastrado com sucesso' })
          // self.$router.push('/question/edit/' + response.data.metadata.id)
          self.$router.push(`/exam/view/${response.data.metadata.id}`)
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          this.setLoading(false)
          this.sending = false
        })
    },
    update () {
      if (!this.$refs.formExam.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.setLoading(true)
      this.sending = true
      const self = this
      this.$axios
        .patch(`/organizations/${this.id}/exams/${this.$route.params.id}`, { ...this.form })
        .then(response => {
          self.$store.dispatch('alert', { msg: 'Atualizado com sucesso' })
          // self.$router.push('/question/edit/' + response.data.metadata.id)
          self.$router.push(`/exam/view/${response.data.metadata.id}`)
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          this.setLoading(false)
          this.sending = false
        })
    },
    async getExam () {
      try {
        const classResponse = await this.$axios.get(
          `organizations/${this.id}/exams/${this.$route.params.id}`
        )
        if (!classResponse.data) throw classResponse
        this.form = {
          description: classResponse.data.data.description,
          name: classResponse.data.data.name
        }
        this.examFetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {
      }
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      selectedQuestions: 'exams/selectedQuestions'
    })
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (this.action === 'edit') {
      this.setLoading(true)
      this.getExam()
    }
  }
}
</script>
