<template>
    <v-dialog persistent v-model="dialog" max-width="520">
        <v-card>
            <v-card-title class="text-h5 mb-3">
                Vincular turma do Google Sala de Aula
            </v-card-title>

            <v-card-subtitle>
                <h2>{{ selectedCourse.course.name }}</h2>
            </v-card-subtitle>

            <v-card-text>
                <v-autocomplete :loading="loading" :search-input.sync="search" item-text="name" item-value="value" :items="classroomCourses" v-model="classroomId" label="Selecionar turma do Google Sala de Aula">
                    <template v-slot:append-item>
                        <div v-intersect.quiet="onIntersect" class="pa-4">
                        </div>
                    </template>
                </v-autocomplete>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="$emit('closeDialogWithoutEvent')">
                    Cancelar
                </v-btn>

                <v-btn color="primary darken-1" text @click="linkCourse()">
                    Vincular turma
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DialogLinkCourse',
  props: ['dialog', 'selectedCourse'],
  data () {
    return {
      classroomId: '',
      search: '',
      page: 1,
      lastPage: 1,
      classroomCourses: [],
      loading: false
    }
  },
  watch: {
    search (value) {
      if (!value) {
        return
      }
      clearTimeout(this._timerId)
      this.page = 1
      this.classroomCourses = []
      this.search = value
      this._timerId = setTimeout(async () => {
        await this.loadClassroomCourses()
      }, 1000)
    },
    async selectedCourse () {
      await this.loadClassroomCourses()
    }
  },
  methods: {
    async loadClassroomCourses () {
      const organizationId = this.selectedCourse.organization_id
      if (!organizationId) {
        return
      }
      this.loading = true
      const response = await this.$axios.get(
            `organizations/${organizationId}/mixed-courses`,
            {
              params: {
                type: 'CLASSROOM',
                search: this.search,
                page: this.page
              }
            }
      )
      this.lastPage = response.data.pagination.last_page
      this.classroomCourses = [
        ...this.classroomCourses,
        ...response.data.items.map(item => {
          return {
            name: item.data.classroom.name,
            value: item.data.classroom.id
          }
        })
      ]
      this.loading = false
    },
    async onIntersect () {
      if (this.page >= this.lastPage) {
        return
      }
      this.page += 1
      await this.loadClassroomCourses()
    },
    linkCourse () {
      this.$emit('loading', true)
      this.$emit('closeDialogWithoutEvent')
      // eslint-disable-next-line camelcase
      const { course, school, organization_id } = this.selectedCourse
      this.$axios.patch(
          // eslint-disable-next-line camelcase
          `organizations/${organization_id}/schools/${school.id}/courses/${course.id}`,
          {
            classroom_id: this.classroomId
          }
      ).then(() => {
        this.$store.dispatch('alert', { msg: 'Turma vinculada com sucesso!', color: 'green' })
        this.$emit('closeDialog')
      }).catch(() => {
        this.$store.dispatch('alert', { msg: 'Erro na vinculação da turma!', color: 'red' })
      }).finally(() => {
        this.$emit('loading', false)
      })
    }
  }
}
</script>
