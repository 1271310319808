const matrices = [
  {
    value: 'OWN',
    name: 'Instituição'
  },
  {
    value: 'ENEM',
    name: 'ENEM'
  },
  {
    value: 'BNCC',
    name: 'BNCC'
  },
  {
    value: 'SAEB',
    name: 'SAEB'
  }
]

export {
  matrices
}
