<template>
  <div>
    <span
      class="body-2 mr-2"
      :class="navbar ? 'tw-text-gray-600' : 'tw-text-gray-300' "
    >{{ timer.display }}</span>
    <v-progress-circular rotate="270" :value="timer.progress" :color="navbar ? 'blue' : 'white'"></v-progress-circular>
  </div>
</template>
<script>
export default {
  props: {
    start: {
      type: Number,
      default: null
    },
    end: {
      type: Number,
      default: null
    },
    duration: {
      type: Number,
      default: 0
    },
    navbar: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timer: {
      countdown: null,
      display: null,
      progress: null,
      total: null
    }
  }),
  methods: {
    setTimer () {
      this.timer.total = this.duration * 60
      this.timer.countdown = this.timer.total
    },
    timerCountdown () {
      if (this.timer.countdown > 0) {
        setTimeout(() => {
          this.timer.countdown -= 1
          this.timer.display = new Date(this.timer.countdown * 1000)
            .toISOString()
            .substr(11, 8)
          this.timer.progress = (this.timer.countdown / this.timer.total) * 100
          this.timerCountdown()
        }, 1000)
      } else {
        this.timer.display = 'Tempo encerrado!'
      }
    }
  },
  mounted () {
    this.setTimer()
    setTimeout(this.timerCountdown(), 1000)
  }
}
</script>
