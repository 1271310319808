<template>
    <v-row>
        <v-col>
            <v-text-field
                v-model="value"
                @input="handleInput"
                label="Quantidade de estrelas"
                v-mask="'##'"
                outlined
            />
            <div>
                <span class="text-caption text-uppercase">Pré-visualização</span>
            </div>
            <v-rating
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                hover
                :value="value - 1"
                :length="value"
                readonly
                color="yellow darken-3"
                background-color="grey darken-1"
            ></v-rating>
        </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'RatingQuestion',
  props: ['value'],
  methods: {
    handleInput () {
      if (this.value >= 1) {
        this.$emit('input', this.value)
      }
    }
  }
}
</script>
