<template>
    <v-dialog
          v-model="dialog"
          max-width="340"
        >
        <v-card>
            <v-card-title class="text-h5 mb-5">
              <v-icon color="yellow darken-3">mdi-alert</v-icon>&nbsp;&nbsp;Atenção
            </v-card-title>

            <v-card-subtitle>
              <h2>{{ headerMessage }}</h2>
            </v-card-subtitle>

            <v-card-text>
              {{ bodyMessage }}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                color="primary darken-1"
                text
                :disabled="sending"
                @click="$emit('closeModal')"
                >
                {{ denyMessage }}
                </v-btn>

                <v-btn
                color="red darken-1"
                text
                :disabled="sending"
                @click="destroy()"
                >
                {{ confirmMessage }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'DialogDelete',
  props: ['dialog', 'headerMessage', 'bodyMessage', 'confirmMessage', 'denyMessage', 'deleteEndpoint'],
  data () {
    return {
      sending: false
    }
  },
  methods: {
    async destroy () {
      this.sending = true
      const response = await this.$axios.delete(
        this.deleteEndpoint
      )
      this.sending = false
      if (response.status === 204) {
        return this.$emit('deleteSuccess')
      }
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Ocorreu um erro durante a exclusão, tente novamente.'
      })
    }
  }
}
</script>
