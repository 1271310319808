<template>
    <v-app>
        <v-row class="d-flex">
            <v-col cols="12" lg="7" xl="6" class="primary d-flex align-center justify-center">
                <v-container>
                    <div class="pa-10">
                        <v-row justify="center">
                            <v-col cols="10" md="8" xl="5">
                                <div class="d-flex align-center align-md-start justify-start flex-column">
                                    <img src="@/assets/images/logo/logo-pruvo-white.png" class="logo-pruvo" />
                                    <div class="tw-hidden md:tw-block">
                                        <h6
                                            class="tw-hidden md:tw-block subtitle-1 mt-4 white--text op-5 font-weight-regular">
                                            Olá! Você está a poucos passos de contar com a praticidade da nossa
                                            ferramenta. Com a opção de integração do Pruvo com a plataforma
                                            Google for Education, facilitamos diariamente a vida de milhares de
                                            professores, alunos e instituições.
                                        </h6>
                                        <a href="https://pruvo.app">
                                            <v-btn class="mt-4 text-capitalize" x-large outlined color="white">Saiba
                                                Mais</v-btn>
                                        </a>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-col>
            <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
                <v-container>
                    <div class="pa-7 pa-sm-12">
                        <v-row>
                            <v-col cols="12" lg="9" xl="6">
                                <slot name="form"></slot>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>

<style scoped>
.logo-pruvo {
    max-width: 200px;
}
</style>
