<template>
  <v-app id="materialpro" class="full-sidebar">
    <router-view />
    <base-alerts />
    <base-loading />
  </v-app>
</template>

<script>
import '@/assets/tailwind.css'

export default {
  name: 'App',
  watch: {
    '$route' (to, from) {
      if (from.path === '/login') {
        if (to.path === '/organization' || to.path === '/school') {
          window.location.reload(true)
        }
      }
    }
  }
}
</script>
