<template>
    <v-select
        v-model="value"
        @input="$emit('input', value)"
        :label="label"
        :items="items"
        :loading="loading"
        :disabled="disabled"
        :multiple="multiple"
        :chips="chips"
        outlined
    >
        <template #append-item>
         <div v-intersect="{
              handler: getMore,
              options: {
                rootMargin: '0px',
                threshold: 1.0,
              },
            }" />
        </template>
    </v-select>
</template>

<script>
export default {
  name: 'SelectInfiniteScroll',
  props: ['value', 'label', 'items', 'loading', 'disabled', 'multiple', 'chips'],
  methods: {
    getMore () {
      this.$emit('getMore')
    }
  }
}
</script>
