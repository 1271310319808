<template>
  <v-card text class="mb-7">
    <v-card-text class="pa-5" :class="color">
      <div class="d-flex align-center">
        <div>
          <h3 class="card-title white--text title font-weight-regular">{{ title }}</h3>
          <h6 class="card-subtitle white--text op-5 subtitle-2 font-weight-regular">{{ subtitle }}</h6>
        </div>
      </div>
      <v-row class="mt-1">
        <v-col cols="6" class="d-flex align-center">
          <h2 class="font-weight-bold white--text text-nowrap">{{ info }}</h2>
        </v-col>
        <v-col cols="6">
          <div class="float-right">
            <i style="font-size: 80px" class="white--text op-5" :class="icon"></i>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    title: String,
    subtitle: String,
    info: String,
    icon: String,
    color: String
  },
  data: () => ({})
}
</script>
