<template>
  <v-container>
    <div>
      <div>
        <form>
          <v-card-title class="text-uppercase font-weight-bold">Informações da Prova</v-card-title>
          <v-card class="pa-5" tile>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-checkbox v-model="checkbox" :label="`Compartilhar questões entre professores da minha instituição.`">
                </v-checkbox>
              </v-col>
              <v-col cols="12" class="mt-3">
                <v-text-field :label="'Nome da Prova'" v-model="exam.name" outlined>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="py-0 mb-10">
                <label class="v-label v-label--active theme--light">
                  <sup>Instruções da Prova *</sup>
                </label>
                <editor api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh" :init="{
                        menubar: false,
                        toolbar:
                          'bold italic underline'
                    }" v-model="exam.instructions" :key="exam" :id="'exam-instructions'" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" xl="4" class="py-0">
                <autocomplete-sync label="Etapa de Ensino" :action="action" path="lists/teaching-stages"
                  type="teaching_stages" @change="setTeachingStages($event)" />
              </v-col>
              <v-col cols="12" md="6" xl="4" class="py-0">
                <autocomplete-sync label="Área de conhecimento" :action="action"
                  :path="`lists/knowledge-area?teaching_stage=${teaching_stage.id}`" type="knowledge_area"
                  @change="setKnowledgeArea($event)" :disabled="!teaching_stage.id" />
              </v-col>
              <v-col cols="12" xl="4" class="py-0">
                <autocomplete-sync label="Disciplina" :action="action"
                  :path="`lists/curricular-component?knowledge_area=${knowledge_area.id}`" type="curricular_component"
                  @change="setCurricularComponent($event)" :disabled="!knowledge_area.id" />
              </v-col>
            </v-row>
          </v-card>
          <div v-for="(form, formIndex) in forms" v-bind:key="formIndex" class="mb-5">
            <v-card-title class="mt-6 text-uppercase font-weight-bold">Questão {{formIndex + 1}}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="removeQuestion(formIndex, form)" icon>
                    <v-icon v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Remover Questão</span>
              </v-tooltip>
            </v-card-title>
            <v-card class="pa-5 pt-8">
              <v-row>
                <v-col cols="12" md="12" class="py-0 editor-box mb-3">
                  <label class="v-label v-label--active theme--light">
                    <sup>Enunciado *</sup>
                  </label>
                  <editor api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh" :init="editorConfig"
                    v-model="form.main_text" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" class="py-0 mt-3">
                  <v-select :items="typesMatrices" v-model="typesMatricesSelected" item-text="name" :disabled="sending"
                    item-value="value" return-object label="Tipo da Matriz*" background-color="transparent" outlined
                    dense></v-select>
                </v-col>
                <v-col cols="12" md="8" class="py-0 mt-3">
                  <autocomplete-sync label="Matriz de Referência" :action="action"
                    :path="`lists/competency-matrix?teaching_stage=${teaching_stage.id}&knowledge_area=${knowledge_area.id}&curricular_component=${curricular_component.id}&type=${typesMatricesSelected.value}`"
                    type="competency-matrix" :multiple="true" :value="{...competency_matrices}"
                    @change="setCompetencyMatrices($event)"
                    :disabled="(!knowledge_area.id||!teaching_stage.id||!curricular_component.id)" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" class="py-0">
                  <v-select :items="difficulty" v-model="form.difficulty" item-text="name" :disabled="sending"
                    item-value="value" label="Dificuldade *" background-color="transparent" outlined dense></v-select>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-select :items="types" v-model="form.wordings[0].data.type" item-text="name" :disabled="sending"
                    item-value="value" label="Tipo *" @change="changeType(form)" background-color="transparent" outlined
                    dense></v-select>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field label="Peso *" v-model="form.wordings[0].data.grade" background-color="transparent"
                    v-mask="'###'" outlined dense></v-text-field>
                </v-col>
              </v-row>
              <div :key="optionsKey">
                <div v-show="form.showOptions" v-for="(option, index) in form.wordings[0].data.options" :key="index"
                  class="tw-bg-gray-50 tw-border tw-border-gray-200 tw-mb-4 tw-rounded-md tw-px-4 tw-pt-4 mt-5">
                  <v-row class="pt-4" justify="center">
                    <v-col cols="10" class="py-0">
                      <v-switch v-model="option.is_correct" :id="'switcher' + index" class="pa-0 ma-0 d-inline-block"
                        label="Alternativa Correta"></v-switch>
                    </v-col>
                    <v-col cols="2" class="py-0" align="end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="removeOption(index, form)" icon>
                            <v-icon v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Remover Alternativa</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="mb-4 relative">
                    <v-col cols="12" class="py-0 editor-box mb-3">
                      <v-divider class="mt-n2 mb-4" style="border-color: #949494" />
                      <editor api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh" :init="{
                          menubar: false,
                          toolbar:
                            'bold italic underline'
                      }" v-model="option.answer" :key="key" :id="'form-' + formIndex + 'answer-' + index" />
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-show="form.showOptions">
                <v-row>
                  <v-col cols="12" align="center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="addOption(form)" fab dark color="primary">
                          <v-icon dark v-bind="attrs" v-on="on">mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Adicionar Alternativa</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <v-row>
            <v-col cols="12" class="mt-4" align="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large outlined @click="createQuestion()" fab dark color="primary">
                    <v-icon dark v-bind="attrs" v-on="on">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar Questão</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-card-actions v-if="action === 'add'">
            <v-spacer />
            <v-btn large outlined @click="$router.go(-1)" color="primary">Cancelar</v-btn>
            <v-btn large @click="save" color="primary">Salvar</v-btn>
          </v-card-actions>
        </form>
      </div>
    </div>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="font-weight-bold pa-3 text-center mb-2">
          <div class="mb-3 text-uppercase loading-message">
            {{ message }}
          </div>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { mapMutations } from 'vuex'
import { handleError } from '@/utils/helpers'

export default {
  components: {
    editor: Editor
  },
  props: {
    examDatabase: {
      type: Boolean,
      default: true
    },
    examApplication: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'add'
    },
    enunciated: {
      type: Object,
      default () {
        return { text: null }
      }
    }
  },
  data: () => ({
    checkbox: true,
    dialog: false,
    message: '',
    exam: {
      name: '',
      instructions: ''
    },
    key: 0,
    id: '',
    items: {},
    optionsKey: 0,
    sending: false,
    optionIndex: 0,
    search: {
      teachingStages: {
        path: 'lists/teaching-stages'
      }
    },
    types: [
      { name: 'Objetiva', value: 'MULTIPLE_CHOICE' },
      { name: 'Discursiva', value: 'OPEN_ENDED' }
    ],
    typesMatricesSelected: {
      value: 'OWN',
      name: 'Instituição'
    },
    typesMatrices: [
      {
        value: 'OWN',
        name: 'Instituição'
      },
      {
        value: 'ENEM',
        name: 'ENEM'
      },
      {
        value: 'BNCC',
        name: 'BNCC'
      },
      {
        value: 'SAEB',
        name: 'SAEB'
      },
      {
        value: 'PROVA_BRASIL',
        name: 'PROVA BRASIL'
      }
    ],
    difficulty: [
      {
        value: 'VERY_EASY',
        name: 'Trivial'
      },
      {
        value: 'EASY',
        name: 'Fácil'
      },
      {
        value: 'MEDIUM',
        name: 'Média'
      },
      {
        value: 'HARD',
        name: 'Difícil'
      },
      {
        value: 'VERY_HARD',
        name: 'Complexa'
      }
    ],

    permissions: {
      update: false
    },
    teaching_stage: {
      id: ''
    },
    knowledge_area: {
      id: ''
    },
    curricular_component: {
      id: ''
    },
    competency_matrices: [],
    forms: [
    ],
    notFound: false,
    editorConfig: {
      height: 250,
      toolbar:
        'bold italic underline | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | removeformat | charmap image media mathjax ' +
        'export a11ycheck'
    }
  }),
  methods: {
    ...mapMutations(['setLoading']),
    setTeachingStages (item) {
      this.teaching_stage = item
    },
    setKnowledgeArea (item) {
      this.knowledge_area = item
    },
    setCurricularComponent (item) {
      this.curricular_component = item
    },
    setCompetencyMatrices (items) {
      const arr = []
      if (!items.length) return arr
      items.forEach((value, index) => {
        const obj = {
          id: value.id
        }
        arr.push(obj)
      })
      this.competency_matrices = arr
    },
    handleError,
    loadingAndMessage (bool, message = null) {
      this.message = message
      this.setLoading(bool)
      this.sending = bool
      this.dialog = bool
    },
    saveQuestion (value, order) {
      const payload = {
        ...value,
        teaching_stage: { id: this.teaching_stage.id },
        knowledge_area: { id: this.knowledge_area.id },
        curricular_component: { id: this.curricular_component.id },
        is_shared: this.checkbox
      }
      if (payload.competency_matrices.length === 0) {
        delete payload.competency_matrices
      }
      this.loadingAndMessage(true, 'Salvando as questões...')
      return this.$axios.post(`/organizations/${this.id}/questions`, payload)
        .then(response => response.data.metadata.id)
        .then(id => id)
        .catch(responseError => this.handleError(responseError))
        .finally(() => this.loadingAndMessage(false))
    },
    createExam (questions) {
      this.loadingAndMessage(true, 'Salvando a avaliação...')
      this.$axios.post(`/organizations/${this.id}/exams`, {
        duration: 1,
        instructions: this.exam.instructions,
        name: this.exam.name
      })
        .then(async response => {
          const examId = response.data.metadata.id
          await this.importQuestions(examId, questions)
          this.$router.push({
            name: 'Exam List'
          })
        })
        .catch(error => this.handleError(error))
        .finally(() => {
          this.loadingAndMessage(false)
        })
    },
    async importQuestions (examId, questions) {
      this.loadingAndMessage(true, 'Importando as questões...')
      await this.$axios.post(`/organizations/${this.id}/exams/${examId}/questions/import`, { questions })
      this.loadingAndMessage(false)
    },
    save () {
      const questions = []
      this.forms.map((value, index) => {
        this.saveQuestion(value).then(res => {
          questions.push({ id: res, order: index + 1 })
          if (index + 1 === this.forms.length) {
            this.createExam(questions)
          }
        })
      })
    },
    changeType (value) {
      if (value.wordings[0].data.type === 'MULTIPLE_CHOICE') {
        value.showOptions = true
        if (
          !value.wordings[0].data.options ||
          !value.wordings[0].data.options.length
        ) {
          value.wordings[0].data.options = [
            {
              is_correct: false,
              answer: ''
            }
          ]
        }
      } else value.showOptions = false
    },
    addOption (value) {
      this.optionsKey++
      const newOption = {
        is_correct: false,
        answer: ''
      }
      value.wordings[0].data.options.push(newOption)
    },
    removeOption (i, value) {
      value.wordings[0].data.options.splice(i, 1)
    },
    createQuestion () {
      this.forms.push({
        main_text: '',
        teaching_stage: {
          id: ''
        },
        knowledge_area: {
          id: ''
        },
        curricular_component: {
          id: ''
        },
        competency_matrices: [],
        matters: [],
        difficulty: '',
        grades: [],
        wordings: [
          {
            data: {
              wording: '',
              type: 'OPEN_ENDED',
              grade: 1,
              options: [
                {
                  is_correct: false,
                  answer: ''
                }
              ]
            }
          }
        ]
      })
    },
    removeQuestion (i, value) {
      this.forms.splice(i, 1)
    }
  },
  async mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.id = id

    const data = this.$route.hash.substring(1).split('=')

    const dataFromBase64 = JSON.parse(decodeURIComponent(escape(atob(data[3]))))

    this.exam.name = dataFromBase64.name
    this.exam.instructions = dataFromBase64.instruction

    dataFromBase64.questions.forEach(value => {
      const question = {
        main_text: value.main_text,
        teaching_stage: {
          id: ''
        },
        knowledge_area: {
          id: ''
        },
        curricular_component: {
          id: ''
        },
        competency_matrices: [],
        matters: [],
        difficulty: '',
        grades: [],
        wordings: []
      }

      if (value.main_text.match(/src=".*"/)) {
        // eslint-disable-next-line no-useless-escape
        const image = value.main_text.match(/src=".*"/)[0].split(' ')[0].replace(/src=/, '').replace('"', '').replace(/\"/g, '')

        this.$axios.post('/medias', {
          file: image
        }).then(res => {
          const width = value.main_text.match(/width=.*/)[0].split(' ') ? value.main_text.match(/width=.*/)[0].split(' ')[0] : ''

          question.main_text = value.main_text.replace(/src=.*"/, `src="${res.data.data.url}" ${width}`)
        })
      } else {
        question.main_text = value.main_text
      }

      const wordings =
        {
          data: {
            wording: '',
            type: value.wordings[0].type,
            grade: value.wordings[0].grade,
            options: []
          }
        }

      if (value.wordings[0].options) {
        value.wordings[0].options.forEach(async (answer, index) => {
          const answers = {
            is_correct: answer.is_correct,
            order: index + 1
          }

          if (answer.answer.match(/src=".*"/)) {
            // eslint-disable-next-line no-useless-escape
            const answerImage = answer.answer.match(/src=".*"/)[0].split(' ')[0].replace(/src=/, '').replace('"', '').replace(/\"/g, '')

            const response = await this.$axios.post('/medias', {
              file: answerImage
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            })

            const width = answer.answer.match(/width=.*/)[0].split(' ') ? answer.answer.match(/width=.*/)[0].split(' ')[0] : ''

            answers.answer = answer.answer.replace(/src=.*"/, `src="${response.data.data.url}" ${width}`)
          } else {
            answers.answer = answer.answer
          }

          question.showOptions = true

          wordings.data.options.push(answers)

          question.wordings[0] = wordings
        })
      } else {
        question.wordings = [
          {
            data: {
              wording: '',
              type: value.wordings[0].type,
              grade: value.wordings[0].grade
            }
          }
        ]
      }

      this.forms.push(question)
    })

    this.link = {
      save: `/organizations/${id}/questions`
    }
  }
}
</script>
<style scoped>
forms.disabled {
  opacity: 0.55;
  pointer-events: none;
}
.loading-message {
  color: white;
}
</style>
