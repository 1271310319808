<template>
  <div>
    <v-data-table
    :options.sync="paginationConfig"
    :server-items-length="paginationConfig.total"
    v-model="selected"
    :headers="headers"
    :items="items"
    v-if="items.length"
    :sort-by="sortBy"
      class="contact-listing-app" loading-text="Buscando dados... Aguarde" :search="search" :show-select="showSelect"
      :class="[tableUser ? 'table-user' : 'table-data']" style="cursor: pointer" @toggle-select-all="selectAllToggle">
      <template v-slot:top v-if="showSearch || displayActionItems">
        <v-container class="my-2">
          <v-row>
            <v-col cols="10" lg="6" v-if="showSearch">
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="text.searchLabel" outlined dense
                background-color="transparent" hide-details></v-text-field>
            </v-col>
            <v-col cols="2" lg="6" align="right">
              <v-menu bottom left v-if="displayActionItems">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="(item, i) in actionItems" :key="i">
                    <template v-if="!item.needSelect || (item.needSelect && showSelect)">
                      <v-list-item-title>
                        <v-icon class="mr-3">{{ item.icon }}</v-icon>
                        {{ item.title }}
                      </v-list-item-title>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item, isSelected }">
        <div class="d-flex align-center flex-column flex-sm-row tw-w-full" :class="isSelected ? 'selected' : ''">
          <div class="mr-4">
            <v-img v-if="item.avatar" :src="item.avatar" :width="$vuetify.breakpoint.xs ? '80px' : '45px'"
              class="rounded-circle img-fluid"></v-img>
          </div>

          <div class="tw-text-xl sm:tw-text-lg">
            {{ item.name }}
            <span v-if="item.email" class="subtitle-2 font-weight-regular d-block"
              :class="{'text-no-wrap': !$vuetify.breakpoint.xs}">{{ item.email }}</span>
          </div>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <template v-if="item.selectDisabled">
          <v-tooltip bottom>
            <!-- eslint-disable-next-line -->
            <template v-slot:activator="{ on, attrs }">
              <v-simple-checkbox :value="isSelected" :readonly="item.selectDisabled" :disabled="item.selectDisabled"
                @input="select($event)"></v-simple-checkbox>
            </template>
            <span>{{ item.data.disabledDescription || '' }}</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
        </template>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.roles="{ item }">
        <div class="d-flex align-center tw-justify-center tw-flex-wrap">
          <v-select :items="roles" v-model="item.roles" outlined multiple chips hide-details @blur="updateRoles(item)">
          </v-select>
        </div>
      </template>
    </v-data-table>
    <v-skeleton-loader
        v-for="i in 5"
        v-else
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    showSearch: {
      type: Boolean,
      default: false
    },
    tableUser: Boolean,
    text: {},
    headers: {},
    sortBy: {},
    items: {
      type: Array,
      required: true
    },
    showSelected: {},
    showSelect: {},
    disabledDescription: {},
    displayActionItems: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Object,
      default () {
        return {
          page: 1
        }
      }
    }
  },
  data: () => ({
    id: '',
    disabledCount: 0,
    selected: [],
    selectedPerson: {},
    sending: false,
    actionItems: [
      {
        title: 'Remover Seleção',
        icon: 'mdi-delete-outline',
        needSelect: true
      },
      { title: 'Exportar (.pdf)', icon: 'mdi-file-pdf-outline' },
      { title: 'Exportar (.xlsx)', icon: 'mdi-file-excel-outline' },
      { title: 'Exportar (.csv)', icon: 'mdi-file-delimited-outline' }
    ],
    dialog: false,
    dialogConfirm: {
      show: false,
      title: 'Deseja remover as permissões deste usuário?',
      close: 'Cancelar',
      confirm: 'Sim, desejo'
    },
    singleSelect: false,
    search: '',
    roles: [
      {
        text: 'Administrador',
        value: 'ADMIN'
      },
      {
        text: 'Coordenador pedagógico',
        value: 'ORG_PEDAGOGICAL_COORDINATOR'
      },
      {
        text: 'Técnico',
        value: 'TECHNICAL'
      },
      {
        text: 'Financeiro',
        value: 'FINANCIAL'
      }
    ]
  }),
  methods: {
    ...mapMutations(['setLoading']),
    selectAllToggle (props) {
      if (this.selected.length !== this.peoples.length - this.disabledCount) {
        this.selected = []
        const self = this
        props.peoples.forEach((item) => {
          if (!item.selectDisabled) {
            self.selected.push(item)
          }
        })
      } else this.selected = []
    },
    async updateRoles (item) {
      const payload = [{
        user: {
          id: item.id
        },
        roles: []
      }]

      let flag = 0
      this.roles.forEach(role => {
        if (item.roles.some(elem => elem === role.value)) {
          payload[0].roles.push({ name: role.value, operation: 'ADD' })
          flag++
        } else {
          payload[0].roles.push({ name: role.value, operation: 'REMOVE' })
        }
      })
      const self = this
      await this.$axios.post(`/organizations/${this.id}/people/sync`, { users: payload })
        .then(response => { self.$store.dispatch('alert', { msg: 'Atualizado com sucesso' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
        })

      if (!flag) {
        this.$emit('getPeople')
      }
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
  },
  computed: {
    paginationConfig: {
      get: function () {
        return this.pagination
      },
      set: function (newValue) {
        this.$emit('update', newValue)
      }
    }
  }
}
</script>
<style>
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row:last-child {
  padding-bottom: 40px !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__header {
  display: none !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 2px 0 !important;
  min-height: auto !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell {
  width: 100% !important;
  text-align: center !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell
  .v-simple-checkbox {
  width: 100% !important;
  text-align: left !important;
  padding: 0.5rem 1rem !important;
}

.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  flex-direction: column !important;
  align-items: flex-start;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell
  .font-datatable-item {
  min-width: 235px;
  width: 100%;
  word-wrap: normal;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 6px 18px !important;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  background: #efefef;
  border-radius: 10px;
  margin: 6px 18px;
}
.v-data-table__wrapper .v-data-table__mobile-table-row.v-data-table__selected {
  background: #ceebff !important;
}
</style>
