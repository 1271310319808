    <template>
  <div class="text-center">
    <v-dialog
      :key="index"
      v-model="show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="examShow(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Resumo da Avaliação</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="examShow(false)">OK</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="text--primary mt-4">
          <div>
            <div class="tw-text-gray-600 tw-font-medium tw-text-xl tw-mt-6 tw-mb-2">{{ exam.name }}</div>
            <div
              v-if="exam.instructions"
              v-html="exam.instructions"
              class="tw-bg-gray-200 tw-p-4 tw-rounded-md"
            ></div>
          </div>

        <div v-if="exam.author">
          <v-list-item class="grow px-0">
            <v-list-item-avatar color="grey darken-3">
              <v-img class="elevation-5" alt :src="exam.author.avatar"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <div>{{ exam.author.name }}</div>
            </v-list-item-content>
          </v-list-item>
        </div>

          <!-- <div
            class="tw-text-gray-600 tw-font-medium tw-text-base tw-my-2"
          >Duração sugerida: {{exam.duration}}m</div> -->
        </v-card-text>
        <v-card-actions>
           <!-- <group-button text="Avaliação" icon="mdi-chevron-right" outlined>
              <v-list-item @click="importExam()">
                <v-list-item-icon>
                    <v-icon>mdi-text-box-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Importar para minhas avaliações</v-list-item-title>
              </v-list-item>
              <v-list-item  :disabled="!exam.permissions && !exam.permissions.update" :to="`/examdatabase/edit/${exam.id}`">
                <v-list-item-icon>
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar dados da avaliação</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="!exam.permissions && !exam.permissions.delete" @click="deleteExam">
                <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Apagar avaliação</v-list-item-title>
              </v-list-item>
            </group-button> -->

            <!-- <v-btn
              color="primary"
              outlined
              v-if="exam.permissions && exam.permissions.update"
              @click="importQuestion = true"
            >
              <v-icon>mdi-cog</v-icon>
              Gerenciar questões
            </v-btn> -->
          <!-- <v-btn depressed color="primary">Criar uma nova questão</v-btn>z -->

        </v-card-actions>
        <v-card class="mx-4">
          <!-- <v-card-title
            class="text-h6 grey lighten-2"
          >{{ exam.questionsLength>1 ? `${exam.questionsLength} Questões`: `${exam.questionsLength} Questão`}}</v-card-title>-->
          {{ exam.questions }}
          <tabs-question :elevation="0" :questions="exam.question" :index="currentPage" />
          <v-pagination
            depressed
            :light="true"
            v-model="currentPage"
            class="my-4"
            :length="totalPages"
          ></v-pagination>
            <!-- :length="Math.ceil(exam.questions_count/10)||0" -->

          <!--  -->
          <!--
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab
              v-for="(question, index) in exam.questions"
              :key="question.id"
            >Questão {{ index+1 }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(question) in exam.questions" :key="question.id">

            </v-tab-item>
          </v-tabs-items>-->

        </v-card>

       <v-divider></v-divider>
         <!-- <v-tabs-items>
          <v-card-actions>
            <v-btn color text @click="examShow(false)">Fechar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :to="`/question/edit/${exam.id}`">Editar questão</v-btn>
          </v-card-actions>
        </v-tabs-items> -->
      </v-card>
    </v-dialog>
    <dialog-base
      title="Gerenciar questões"
      v-if="exam.permissions && exam.permissions.update"
      :show="importQuestion"
      @close="importQuestion = false; getExam({ id:exam.id })"
    >
      <list-question-action
        :selection="true"
        :path="`/exams/${exam.id}`"
        :questions="exam.questions_id"
      />
    </dialog-base>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import TabsQuestion from '../list/TabsQuestion.vue'
import DialogBase from './DialogBase.vue'
export default {
  components: { TabsQuestion, DialogBase },
  props: {
    examPreview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tab: null,
      action: false,
      index: 0,
      show: false,
      importQuestion: false,
      currentPage: 1,
      totalPages: 1,
      id: ''
    }
  },
  methods: {
    ...mapMutations('exams', ['SET_SHOW_EXAM_SELECTED', 'HIDDEN_EXAM_SELECTED']),
    ...mapMutations(['setLoading']),
    ...mapActions('exams', ['getExam']),
    examShow (payload) {
      this.show = false
      this.HIDDEN_EXAM_SELECTED()
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
  },
  watch: {
    'exam' (newExal) {
      this.show = newExal
      this.scrollY = false
      // this.index++
      this.totalPages = Math.ceil(newExal.questions_count / 10)
    },
    currentPage (page) {
      this.getExam({ id: this.exam.id, page })
    }
  },

  computed: {
    exam () {
      return this.$store.state.exams.examSelected
    }
    // ...mapState({
    //   exam: 'exams/examSelected'
    // })
    // ...mapGetters({
    //   exam: 'exams/examSelected'
    // })
  }
}
</script>

<style scoped>
.v-dialog--fullscreen{
  overflow-x: hidden;
}
</style>
