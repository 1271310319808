<template>
  <v-autocomplete
    v-model="itemSelected"
    :items="finalItems"
    :search-input.sync="search"
    :loading="isLoading"
    :item-text="(row) => {return row.name+ ' - ' + row.description;}"
    item-value="id"
    return-object
    outlined
    dense
    color="blue-grey lighten-2"
    :label="label"
    :multiple="multiple"
    :disabled="disabled"
  >
    <template v-slot:selection="data">
      <div class="py-3" v-if="multiple">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >{{ data.item.name }}</v-chip>
          </template>
          {{ data.item.description }}
        </v-tooltip>
      </div>
      <span v-else>{{ data.item.name }}</span>
    </template>
    <template v-slot:item="data">
      <template v-if="(typeof data.item) !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
            <span v-if="data.item.description">- {{ data.item.description }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
     <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>{{ isLoading ? 'Procurando, aguarde..' : 'Nenhum dado encontrado' }}</v-list-item-title>
        </v-list-item>
      </template>
       <template v-slot:append-item>
        <div v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }">
           <v-skeleton-loader v-show="isLoading" class="mx-auto" type="list-item" />
          </div>
      </template>
  </v-autocomplete>
</template>

<script>
import collect from 'collect.js'

export default {
  props: {
    path: {
      type: String,
      require: true
    },
    label: {
      type: String
    },
    type: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    },
    action: {
      type: String
    },
    maintainSelected: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    pagination: {
      total: 0,
      current_page: 1,
      per_page: 20000
    },
    isLoading: false,
    index: 0,
    firstFeth: false,
    verifyFinished: true,
    items: [],
    response: [],
    itemSelected: [],
    _timerId: null,
    id: ''
  }),
  watch: {
    search (value) {
      if (!value) {
        return
      }
      if (this.type === 'schools') {
        this.postData(value, this)
      } else {
        this.getData()
      }
    },
    value: {
      deep: true,
      handler (value) {
        if (!value || Object.keys(value).length === 0) {
          // clear
          this.itemSelected = []
        }
      }
    },
    path (val) {
      if (!this.disabled) {
        this.getData()
      }
    },
    firstFeth (newVal, oldVal) {
      if (!oldVal && newVal && this.action === 'edit') {
        this.verifyValue()
      }
    },
    itemSelected (val) {
      if (this.verifyFinished) {
        this.$emit('change', val)
      }
      this.verifyFinished = true
    }
  },
  methods: {
    onIntersect (entries) {
      collect(entries).each(entry => {
        if (entry.isIntersecting) {
          this.getMore()
        }
      })
    },
    getMore () {
      if (this.isLoading) return
      if (this.type === 'competency-matrix') {
        if ((this.pagination.total / this.pagination.per_page) <= this.pagination.current_page) return

        this.concat = true
        this.pagination.current_page = parseInt(this.pagination.current_page, 10) + 1
        this.getData(true)
      }
    },
    remove (item) {
      const index = this.itemSelected.findIndex(x => x.id === item.id)
      if (index >= 0) this.itemSelected.splice(index, 1)
    },

    async verifyValue () {
      await this.$nextTick()
      if (this.type === 'competency-matrix') {
        const arr = []
        for (const a in this.value) {
          arr.push({
            ...this.value[a],
            name: `${this.value[a].type} - ${this.value[a].code}`,
            description: this.value[a].description,
            id: this.value[a].id
          })
        }
        this.itemSelected = arr
      } else {
        const index = this.items.findIndex(x => x.id === this.value.id)
        if (index >= 0) this.itemSelected = this.items[index]
      }
    },
    formatResponses (items) {
      const arr = []
      if (!items.length) return arr
      if (this.type === 'competency-matrix') {
        items.forEach(value => {
          const obj = {
            name: `${value.type === 'OWN' ? 'Instituição' : value.type} - ${value.code}`,
            description: value.description,
            id: value.id
          }
          arr.push(obj)
        })
      } else {
        items.forEach(value => {
          arr.push(value)
        })
      }

      return arr
    },
    async getData (getMore = false) {
      this.isLoading = true
      clearTimeout(this._timerId)
      if (!getMore) {
        this.pagination.current_page = 1
      }
      this._timerId = setTimeout(async () => {
        let payload = `&per_page=${this.pagination.per_page}&page=${this.pagination.current_page}`
        if (this.type === 'teaching_stages') {
          payload = `?per_page=${this.pagination.per_page}&page=${this.pagination.current_page}`
        }
        if (this.type === 'competency-matrix') {
          payload = `&search=${this.search ?? ''}&page=${this.pagination.current_page}`
        }
        await this.$axios
          .get(`/organizations/${this.id}/${this.path}${payload}`)
          .then(response => {
            response.data.items.forEach((value, index) => {
              if (value.organization_id !== this.id) {
                delete response.data.items[index]
              }
            })
            this.items = this.formatResponses(response.data.items)
            this.pagination = response.data.pagination
            if (this.concat) {
              this.response = [
                ...this.response,
                ...this.items
              ]
            } else {
              this.response = [...this.items]
            }
            this.firstFeth = true
          })
          .finally(() => {
            this.isLoading = false
            this.concat = false
          })
      }, 1500)
    },
    async postData () {
      this.isLoading = true
      let payload = ''
      if (this.search) {
        payload = `?search=${this.search}`
      }
      clearTimeout(this._timerId)

      this._timerId = setTimeout(async () => {
        await this.$axios
          .post(`/organizations/${this.id}/${this.path}${payload}`)
          .then(response => {
            this.items = this.formatResponses(response.data.items)

            this.response = [
              { header: 'Resultados da busca:' },
              ...this.items
            ]

            this.firstFeth = true
          })
          .finally(() => (this.isLoading = false))
      }, 1500)
    }
  },
  computed: {
    finalItems () {
      if (this.maintainSelected) {
        return this.itemSelected.concat(this.response.filter(item => !this.itemSelected.includes(item)))
      } else {
        return this.response
      }
    }
  },
  async mounted () {
    if (this.action === 'edit') {
      this.verifyFinished = false
    }
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (!this.disabled || this.action === 'edit') {
      if (this.type === 'schools') {
        await this.postData(this)
      } else {
        await this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
