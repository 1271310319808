const types = [
  { name: 'Objetiva', value: 'MULTIPLE_CHOICE' },
  { name: 'Discursiva', value: 'OPEN_ENDED' },
  { name: 'Múltipla escolha', value: 'MULTIPLE_CHOICE_MULTIPLE_ANSWER' },
  { name: 'Matriz', value: 'MATRIX' },
  { name: 'Barra de progresso', value: 'SLIDER' },
  { name: 'Avaliação com estrelas', value: 'RATING' }
]

export {
  types
}
