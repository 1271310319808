<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-row class="m-0">
          <v-col cols="12" class="py-0" v-if="!duplicate && editDisabled">
            <p>
              Você está duplicando a questão e as alterações feitas aqui não afetarão a questão original.
              <v-btn text color="primary" @click="duplicate = true">
                <v-icon>mdi-file-replace</v-icon>Duplicar e editar
              </v-btn>
            </p>
          </v-col>
        </v-row>
        <form :class="{disabled: (editDisabled && !duplicate)}">
          <!-- v-model="data.question" -->
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <v-checkbox
                v-model="checkbox"
                :label="`Compartilhar entre professores da minha instituição.`"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0 editor-box mb-3">
              <label class="v-label v-label--active theme--light">
                <sup>Enunciado *</sup>
              </label>
              <editor
                api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh"
                :init="editorConfig"
                v-model="form.main_text"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0 pt-3">
              <v-combobox
                v-model="form.tags"
                :disabled="sending"
                label="Tags"
                background-color="transparent"
                multiple
                chips
                outlined
                hide-selected
                hint="Pressione TAB ou ENTER para adicionar uma nova tag."
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    close
                    @click:close="data.parent.selectItem(data.item)"
                  >{{ data.item }}</v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-combobox
                v-model="form.matters"
                :disabled="sending"
                label="Assunto"
                background-color="transparent"
                multiple
                chips
                outlined
                hide-selected
                hint="Pressione TAB ou ENTER para adicionar uma novo assunto."
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    close
                    @click:close="data.parent.selectItem(data.item)"
                  >{{ data.item }}</v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                :items="difficulty"
                v-model="form.difficulty"
                item-text="name"
                :disabled="sending"
                item-value="value"
                label="Dificuldade *"
                background-color="transparent"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <autocomplete-sync
                label="Etapa de Ensino *"
                :action="action"
                path="lists/teaching-stages"
                type="teaching_stages"
                :value="form.teaching_stage"
                @change="setTeachingStages($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <autocomplete-sync
                label="Área de conhecimento *"
                :action="action"
                :path="`lists/knowledge-area?teaching_stage=${form.teaching_stage.id}`"
                type="knowledge_area"
                :value="form.knowledge_area"
                @change="setKnowledgeArea($event)"
                :disabled="!form.teaching_stage.id"
              />
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <autocomplete-sync
                label="Disciplina *"
                :action="action"
                :path="`lists/curricular-component?knowledge_area=${form.knowledge_area.id}`"
                type="curricular_component"
                :value="form.curricular_component"
                :disabled="!form.knowledge_area.id"
                @change="setCurricularComponent($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="py-0">
              <v-select
                :items="typesMatrices"
                v-model="typesMatricesSelected"
                item-text="name"
                :disabled="sending"
                item-value="value"
                return-object
                label="Matriz de referência"
                background-color="transparent"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <autocomplete-sync
                label="Descritores"
                :action="action"
                :path="`lists/competency-matrix?teaching_stage=${form.teaching_stage.id}&knowledge_area=${form.knowledge_area.id}&curricular_component=${form.curricular_component.id}&type=${typesMatricesSelected.value}`"
                type="competency-matrix"
                :multiple="true"
                :maintainSelected="true"
                :value="{...form.competency_matrices}"
                @change="setCompetencyMatrices($event)"
                :disabled="!form.curricular_component.id"
                :search="searchMatrixReference"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                :items="types"
                v-model="form.wordings[0].data.type"
                item-text="name"
                :disabled="sending"
                item-value="value"
                label="Tipo *"
                background-color="transparent"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                label="Peso *"
                v-model="form.wordings[0].data.grade"
                background-color="transparent"
                v-float
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <open-ended-question
            v-if="form.wordings[0].data.type === 'OPEN_ENDED'"
            v-model="openEndedParams"
          />

          <multiple-choice
            v-if="['MULTIPLE_CHOICE', 'MULTIPLE_CHOICE_MULTIPLE_ANSWER'].includes(form.wordings[0].data.type)"
            v-model="multipleChoiceOptions"
            :action="action"
          />

          <slider-question
            v-if="form.wordings[0].data.type === 'SLIDER'"
            v-model="sliderData"
          />

          <matrix
            v-if="form.wordings[0].data.type === 'MATRIX'"
            @updateMatrixData="updateMatrixData($event)"
            :wordings="form.wordings"
          />

          <rating-question
            v-if="form.wordings[0].data.type === 'RATING'"
            v-model="ratingValue"
          />

          <v-card-actions v-if="action === 'add' || duplicate">
            <v-spacer />
            <v-btn large outlined @click="$router.go(-1)" color="primary">Cancelar</v-btn>
            <v-btn large @click="save" color="primary">Salvar</v-btn>
          </v-card-actions>

          <button-update v-on:update="update" v-else-if="action === 'edit'" class="mt-6"></button-update>
        </form>
      </div>
    </div>
  </v-container>
</template>

<script>
import editorConfig from '../../libs/editorConfig'
import collect from 'collect.js'
import Editor from '@tinymce/tinymce-vue'
import { mapMutations, mapState } from 'vuex'
import OpenEndedQuestion from '@/components/question-type/OpenEndedComponent.vue'
import SliderQuestion from '@/components/question-type/SliderComponent.vue'
import MultipleChoice from '@/components/question-type/MultipleChoiceComponent.vue'
import Matrix from '@/components/question-type/MatrixComponent.vue'
import RatingQuestion from '../question-type/RatingComponent.vue'
import { difficulties } from '@/constants/difficulties'
import { types } from '@/constants/question-types'
import { matrices } from '@/constants/matrices'

export default {
  components: {
    editor: Editor,
    OpenEndedQuestion,
    SliderQuestion,
    MultipleChoice,
    Matrix,
    RatingQuestion
  },
  directives: {
    float: {
      bind (el) {
        el.addEventListener('input', (event) => {
          event.target.value = handleInput(event.target.value)
        })
        el.addEventListener('focusout', (event) => {
          event.target.value = handleInput(event.target.value)
        })
        el.addEventListener('focusin', (event) => {
          event.target.value = handleInput(event.target.value)
        })

        function handleInput (value) {
          value = replaceComma(value)

          if (isNotNumber(value)) {
            return ''
          }

          return value
        }

        function replaceComma (value) {
          return value.replace(/,/g, '.')
        }

        function isNotNumber (value) {
          return isNaN(value)
        }
      }
    }
  },
  props: {
    examDatabase: {
      type: Boolean,
      default: true
    },
    examApplication: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'add'
    },
    enunciated: {
      type: Object,
      default () {
        return { text: null }
      }
    }
  },
  data: () => ({
    checkbox: true,
    id: '',
    items: {},
    optionsKey: 0,
    sending: false,
    showOptions: false,
    optionIndex: 0,
    duplicate: false,
    editDisabled: false,
    search: {
      teachingStages: {
        path: 'lists/teaching-stages'
      }
    },
    types,
    typesMatricesSelected: {
      value: 'OWN',
      name: 'Instituição'
    },
    typesMatrices: matrices,
    difficulty: difficulties,
    order: 1,
    permissions: {
      update: false
    },
    form: {
      main_text: '',
      teaching_stage: {
        id: ''
      },
      knowledge_area: {
        id: ''
      },
      curricular_component: {
        id: ''
      },
      competency_matrices: [],
      matters: [],
      difficulty: '',
      grades: [],
      owner: { id: '' },
      tags: [],
      wordings: [
        {
          data: {
            wording: '',
            type: 'OPEN_ENDED',
            grade: 1,
            options: [
              {
                is_correct: false,
                answer: '',
                order: 0
              }
            ]
          }
        }
      ]
    },
    editorConfig,
    sliderData: {
      min: 0,
      max: 0,
      step: 1
    },
    ratingValue: 5,
    openEndedParams: {},
    multipleChoiceOptions: [],
    searchMatrixReference: ''
  }),
  watch: {
    typesMatricesSelected (oldVal, newValue) {
      if (newValue) {
        this.searchMatrixReference = ''
      }
    }
  },
  computed: {
    ...mapState(['user']),
    userId () {
      return this.user.me.metadata.id
    },
    type () {
      return this.form.wordings[0].data.type
    }
  },
  created () {
    if (this.$route.path.includes('copy')) {
      this.editDisabled = true
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    setTeachingStages (item) {
      this.form.teaching_stage = item
      this.form.knowledge_area = {
        id: '',
        name: ''
      }
      this.form.curricular_component = {
        id: '',
        name: ''
      }
      this.form.competency_matrices = []
    },
    setKnowledgeArea (item) {
      this.form.knowledge_area = item
      this.form.curricular_component = {
        id: '',
        name: ''
      }
      this.form.competency_matrices = []
    },
    setCurricularComponent (item) {
      this.form.curricular_component = item
      this.form.competency_matrices = []
    },
    setCompetencyMatrices (items) {
      const arr = []
      if (!items.length) return arr
      items.forEach(value => {
        const obj = {
          id: value.id
        }
        arr.push(obj)
      })
      this.form.competency_matrices = arr
    },
    formatMultipleChoiceOptions (options) {
      return collect(options).map(option => {
        return {
          answer: option.answer,
          is_correct: option.is_correct,
          order: option.order
        }
      }).toArray()
    },
    async getQuestion () {
      this.fetchQuestions = false
      await this.$axios.get(this.link.get)
        .then(response => response.data)
        .then(question => {
          this.form = {
            main_text: question.data.main_text,
            teaching_stage: {
              id: question.data.teaching_stage.id
            },
            knowledge_area: {
              id: question.data.knowledge_area.id
            },
            curricular_component: {
              id: question.data.curricular_component.id
            },
            competency_matrices: question.data.competency_matrices,
            matters: question.data.matters,
            difficulty: question.data.difficulty,
            owner: { id: question.data.owner.id },
            tags: question.data.tags
          }
          this.formatWordings(question.data.wordings[0].data.type, question.data.wordings)
          this.checkbox = question.data.is_shared
          this.permissions = question.permissions

          if (this.$route.path.includes('copy')) {
            delete this.form.teaching_stage.id
            delete this.form.knowledge_area.id
            delete this.form.curricular_component.id
            this.form.competency_matrices = []
          }
        })
        .finally(() => {
          this.fetchQuestions = true
          this.setLoading(false)
        })
    },
    formatWordings (questionType, wordings) {
      const formatter = {
        MULTIPLE_CHOICE: () => {
          formatter.addWordings()
          this.multipleChoiceOptions = this.formatMultipleChoiceOptions(wordings[0].data.options)
        },
        MULTIPLE_CHOICE_MULTIPLE_ANSWER: () => {
          formatter.MULTIPLE_CHOICE()
        },
        OPEN_ENDED: () => {
          formatter.addWordings()
          this.openEndedParams = this.form.wordings[0].data.params
        },
        MATRIX: () => {
          formatter.addWordings()
        },
        SLIDER: () => {
          formatter.addWordings()
          this.sliderData = this.form.wordings[0].data.params
        },
        RATING: () => {
          formatter.addWordings()
          this.ratingValue = this.form.wordings[0].data.params.length
        },
        addWordings: () => {
          this.form = {
            ...this.form,
            wordings
          }
        }
      }
      if (formatter[questionType]) {
        formatter[questionType]()
      }
    },
    handleError (error) {
      collect(error).when(error.response.data.errors, (err) => {
        collect(err.items.response.data.errors).each(msg => {
          this.$store.dispatch('alert', { msg: msg[0], color: 'red' })
        })
      })
    },
    save () {
      this.setLoading(true)
      this.sending = true
      delete this.form.author

      const payload = {
        ...this.form,
        teaching_stage: { id: this.form.teaching_stage.id },
        knowledge_area: { id: this.form.knowledge_area.id },
        curricular_component: { id: this.form.curricular_component.id },
        is_shared: this.checkbox
      }

      if (payload.competency_matrices.length === 0) {
        delete payload.competency_matrices
      }

      const questionType = {
        OPEN_ENDED: () => {
          payload.wordings[0].data.params = this.openEndedParams
          delete payload.wordings[0].data.options
        },
        SLIDER: () => {
          payload.wordings[0].data.params = this.sliderData
          delete payload.wordings[0].data.options
        },
        RATING: () => {
          payload.wordings[0].data.params = {
            length: this.ratingValue
          }
          delete payload.wordings[0].data.options
        },
        MULTIPLE_CHOICE_MULTIPLE_ANSWER: () => {
          payload.wordings[0].data.options = this.multipleChoiceOptions
        },
        MULTIPLE_CHOICE: () => {
          payload.wordings[0].data.options = this.multipleChoiceOptions
        }
      }

      if (questionType[this.form.wordings[0].data.type]) {
        questionType[this.form.wordings[0].data.type]()
      }

      delete payload.wordings[0].metadata
      delete payload.wordings[0].permissions

      this.$axios
        .post(this.link.save, payload)
        .then(() => this.successMsgAndRedirect('Questão cadastrada com sucesso'))
        .catch(error => this.handleError(error))
        .finally(() => {
          this.setLoading(false)
          this.sending = false
        })
    },
    update () {
      this.setLoading(true)
      this.sending = true

      const payload = {
        ...this.form,
        teaching_stage: { id: this.form.teaching_stage.id },
        knowledge_area: { id: this.form.knowledge_area.id },
        curricular_component: { id: this.form.curricular_component.id },
        is_shared: this.checkbox
      }

      const questionType = {
        OPEN_ENDED: () => {
          delete payload.wordings[0].data.options
          payload.wordings[0].data.params = this.openEndedParams
        },
        SLIDER: () => {
          delete payload.wordings[0].data.options
          payload.wordings[0].data.params = this.sliderData
        },
        RATING: () => {
          delete payload.wordings[0].data.options
          payload.wordings[0].data.params = {
            length: this.ratingValue
          }
        },
        MULTIPLE_CHOICE: () => {
          payload.wordings[0].data.options = this.multipleChoiceOptions
        },
        MULTIPLE_CHOICE_MULTIPLE_ANSWER: () => {
          payload.wordings[0].data.options = this.multipleChoiceOptions
        }
      }

      if (questionType[this.form.wordings[0].data.type]) {
        questionType[this.form.wordings[0].data.type]()
      }

      delete payload.wordings[0].metadata
      delete payload.wordings[0].permissions

      this.$axios
        .patch(this.link.update, payload)
        .then(() => this.successMsgAndRedirect('Questão atualizada com sucesso'))
        .catch(error => this.handleError(error))
        .finally(() => {
          this.setLoading(false)
          this.sending = false
        })
    },
    successMsgAndRedirect (msg) {
      this.$store.dispatch('alert', { msg: msg })
      this.$router.push(this.link.redirect)
    },
    updateMatrixData (data) {
      this.order = 0
      this.form.wordings = []
      data.rows.map(row => {
        this.form.wordings.push({
          data: {
            wording: row.value,
            grade: row.points,
            options: data.columns.map((column, index) => {
              this.order += 1
              return {
                answer: column.value,
                is_correct: row.selectedAnswer === index,
                order: this.order
              }
            }),
            type: 'MATRIX'
          }
        })
      })
    }
  },
  async mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.id = id
    if (this.examApplication) {
      this.link = {
        get: `/organizations/${this.id}/exams/${this.$route.params.id}/questions/${this.$route.params.question}?with_wordings=true`,
        save: `/organizations/${id}/exams/${this.$route.params.id}/questions`,
        update: `/organizations/${id}/exams/${this.$route.params.id}/questions/${this.$route.params.question}`,
        redirect: `/exam/view/${this.$route.params.id}`
      }
    } else {
      this.link = {
        get: `/organizations/${this.id}/questions/${this.$route.params.id}?with_wordings=true`,
        save: `/organizations/${id}/questions`,
        update: `/organizations/${id}/questions/${this.$route.params.id}`,
        redirect: '/question/list/'
      }
    }
    this.form.owner.id = id
    if (this.action === 'edit') {
      this.setLoading(true)
      await this.getQuestion()
      if (this.form.author.id === this.userId) {
        this.form.permissions.update = true
      }
      if (!this.permissions.update) {
        this.editDisabled = true
        this.form.owner.id = id
      }
    }
  }
}
</script>
<style scoped>
form.disabled {
  opacity: 0.55;
  pointer-events: none;
}
.bg_card_alert {
  background-color: rgba(248, 215, 218, 0.5)
}
.bg_card_sucess {
  background-color: rgba(212, 237, 218, 0.5)
}
</style>
