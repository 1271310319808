const organizations = state => state.organizations.map(value => {
  value.data.company = Array.isArray(value.data.company) ? {
    logo: '',
    name: {
      trade: 'Instituição sem nome'.toUpperCase(),
      legal: 'Instituição sem nome'.toUpperCase()
    },
    document: {
      type: '',
      id: ''
    },
    segment: ''
  } : value.data.company

  return value
})

const activeOrganization = state => state.activeOrganization

const activeOrganizationDomains = state => state.activeOrganization.data.domains

export default {
  organizations,
  activeOrganization,
  activeOrganizationDomains
}
