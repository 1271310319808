<template>
    <div>
        <div v-for="option in optionsFiltered"
          :key="option.id"
          class="tw-my-2 tw-p-2 tw-rounded-lg d-flex"
          :class="
            option.is_correct ? 'tw-bg-blue-300' : 'tw-bg-gray-300'
          "
          id="option"
        >
          <span class="mr-3 tw-uppercase tw-whitespace-nowrap">{{ alphabet[option.order] }})</span>
          <span v-html="option.answer"></span>
        </div>
    </div>
</template>

<script>
import alphabet from '@/constants/alphabet'
import collect from 'collect.js'

export default {
  name: 'MutipleChoicePreview',
  props: ['options'],
  data () {
    return {
      alphabet
    }
  },
  computed: {
    /*
    * this method sorts by 'order' and then sorts by index
    */
    optionsFiltered () {
      return collect(this.options)
        .values()
        .sortBy('order')
        .map((value, index) => ({ ...value, order: index }))
        .all()
    }
  }
}
</script>
