<template>
  <v-card text class="mb-7">
    <v-card-text class="pa-0" v-if="fetch">
      <div
        class="rounded-t"
        style="
          height: 100px;
          background: rgb(30, 136, 229) !important;
          background-image: linear-gradient(
            180deg,
            rgba(30, 136, 229, 1) 0%,
            rgba(30, 136, 229, 0.5) 100%
          ) !important;
        "
      ></div>
      <div class="pa-4 mt-n12 text-center">
        <div class="d-flex justify-center mt-n5">
          <img
            :src="course.avatar"
            alt="user"
            v-if="course.avatar"
            class="rounded-circle elevation-1"
            width="128"
            height="128"
            style="height:128px;width:128px;"
          />
          <img
          v-else
            src="@/assets/images/class.webp"
            class="rounded-circle elevation-1"
            width="128"
            height="128"
            style="height:128px;width:128px;"
          />

        </div>
        <h2 class="mb-0 mt-4" v-if="course.data.name">{{ course.data.name }}</h2>
        <!-- <p
          class="font-weight-regular"
          v-if="course.data.address"
        >{{ course.data.address.city }}, {{ course.data.address.state }}</p>-->
        <v-btn
          v-if="edit"
          color="primary"
          text
          outlined
          large
          :to="`/course/edit/${$route.params.id}/${$route.params.course}`"
          class="px-7"
        >Editar</v-btn>
      </div>
    </v-card-text>
    <v-skeleton-loader v-else v-bind="attrs" type="article, actions"></v-skeleton-loader>
  </v-card>
</template>
<script>
export default {
  props: {
    edit: {
      type: Boolean,
      default: true
    },
    fetch: {
      type: Boolean,
      default: true
    },
    course: {
      type: Object,
      default: () => {
        return {
          data: {
            name: ''
          }
        }
      }
    }
  },
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: true
    }
  })
}
</script>
