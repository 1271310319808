<template>
  <v-container fluid class="down-top-padding">
    <base-card heading="Administradores">
      <datatable-list-adm-edit
        :text="text"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        v-if="items.length"
        :showSelect="showSelect"
        :displayActionItems="displayActionItems"
        :edit="edit"
        :pagination="pagination"
        :loading="!fetch"
        @update="pagination = { ...pagination, ...$event}"
      ></datatable-list-adm-edit>
      <v-skeleton-loader
        v-else
        v-bind="{
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
        }"
        v-for="i in 5"
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>

<script>
export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    filteredPeoples: [],
    page: {
      title: 'Administradores'
    },
    pagination: {
      page: 1,
      itemsPerPage: 15,
      prev_page_url: false,
      next_page_url: false
    },
    fetch: false,
    text: {
      searchLabel: 'Pesquisar administradores',
      emptyLabel: 'Nenhum administrador encontrado',
      addLabel: 'Adicionar administradores',
      addUrl: '/school/add',
      importLabel: 'Importar administradores',
      importUrl: '/school/add'
    },
    headers: [
      {
        text: 'Nome',
        align: 'start',
        value: 'data'
      },
      {
        text: 'Permissões',
        align: 'center',
        value: 'data.roles'
      }

    ],
    sortBy: 'name',
    showSelect: false,
    displayActionItems: false,
    items: [
    //   {
    //     id: 1,
    //     schoolName: [
    //       'Escola Municipal Batel',
    //       'Escola Municipal Batel'
    //     ],
    //     avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
    //     name: 'Lucinéia Percigili',
    //     email: 'embatel@sme.curitiba.pr.gov.br',
    //     numberOfClass: '345',
    //     url: '/user/profile'
    //   },
    //   {
    //     id: 2,
    //     schoolName: 'Escola Municipal Batel',
    //     avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
    //     name: 'Josélia Percigili',
    //     email: 'embatel@sme.curitiba.pr.gov.br',
    //     numberOfClass: '345',
    //     url: '/user/profile'
    //   }
    ]
  }),
  mounted () {
    this.getPeoples()
  },
  methods: {
    buildQuery () {
      const pagination = `?page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}`
      return pagination
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        const obj = {
          ...value,
          isSelected: false
        }
        arr.push(obj)
      })
      return arr
    },
    async getPeoples () {
      this.fetch = false
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      if (id) {
        await this.$axios.get(`/organizations/${id}/users?${this.buildQuery()}`)
          .then(response => {
            this.items = this.formatPeoples(response.data.items)
          })
        this.fetch = true
      }
    }
  }
}
</script>
