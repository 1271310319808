<template>
  <div>
    <div v-if="!isLoading">
      <datatable-list
      :showSearch="false"
      :headers="headers"
      :items="items"
      :text="text"
      :displayActionItems="false"
      :pagination="pagination"
      @update="pagination = { ...pagination, ...$event}"
      @select-item="preview($event)"/>
    </div>
    <v-skeleton-loader
      v-else
      v-bind="attrs"
      type="article">
    </v-skeleton-loader>
    <DialogInformation
    :value="dialogInformation"
    title="Informações"
    closeBtn="Fechar"
    @close="dialogInformation = false">
      <LogsPreview
        :dataNew="newValue" :dataOld="oldValue">
      </LogsPreview>
    </DialogInformation>
  </div>
</template>

<script>
import moment from 'moment'
import DatatableList from '@/components/datatable/DatatableList.vue'
import DialogInformation from '@/components/dialog/DialogInformation.vue'
import LogsPreview from '@/components/logs/LogsPreview.vue'
import collect from 'collect.js'

export default {
  name: 'LogsTableComponent',
  components: {
    DatatableList,
    DialogInformation,
    LogsPreview
  },
  props: ['audits'],
  data () {
    return {
      dialogInformation: false,
      dialogInformationData: {},
      headers: [{ text: 'Data', value: 'date' },
        { text: 'Hora', value: 'hour' },
        { text: 'IP', value: 'ip' },
        { text: 'User-Agent', value: 'user_agent' },
        { text: 'Ação', value: 'event' },
        { text: 'Local', value: 'local' },
        { text: 'Chave primária', value: 'primary_key' }
      ],
      items: [],
      text: {
        searchLabel: '',
        emptyLabel: 'Nenhum registro encontrado',
        addLabel: 'Adicionar registro',
        addUrl: '/users',
        importLabel: '',
        importUrl: ''
      },
      search: '',
      pagination: {
        page: 1,
        itemsPerPage: 10,
        total: '',
        prev_page_url: false,
        next_page_url: false
      },
      isLoading: true,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 0
      },
      oldValue: {},
      newValue: {}
    }
  },
  created () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getAudits()
  },
  watch: {
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getAudits()
      }
    },
    async search (value) {
      this.pagination.page = 1
      this.getAudits()
      this.search = value
    }
  },
  methods: {
    moment,
    getEventText (value) {
      const eventText = {
        created: 'Criação',
        updated: 'Atualização',
        deleted: 'Exclusão',
        login: 'Autenticaçao',
        login_user_password: 'Autenticação com usuário e senha',
        login_google_oauth: 'Autenticação com google',
        logout: 'Desautenticação',
        user_impersonator: 'Personificação de usuário',
        user_impersonated: 'Usuário personificado'
      }

      return eventText[value] ?? value
    },
    getPathText (value) {
      const pathText = {
        users: 'Usuários',
        people: 'Pessoas',
        applications: 'Aplicações',
        questions: 'Questões',
        organizations: 'Organizações',
        wordings: 'Alternativas',
        exams: 'Avaliações',
        logs: 'Auditoria',
        students: 'Estudantes',
        answers: 'Respostas',
        courses: 'Turmas'
      }

      return pathText[value] ?? value
    },
    getAudits () {
      this.isLoading = true
      this.items = []
      this.$axios.get(
        `organizations/${this.organizationId}/users/${this.$route.params.user_id}/audits?page=${this.pagination.page}`
      )
        .then(response => response.data)
        .then(audits => {
          collect(audits.items).map(audit => {
            this.items.push({
              date: moment(audit.metadata.createTime).format('DD/MM/YYYY'),
              hour: moment(audit.metadata.createTime).format('HH:mm'),
              ip: audit.data.ip_address,
              user_agent: audit.data.user_agent,
              event: this.getEventText(audit.data.event),
              local: this.getPathText(audit.data.auditable_path.split('/').at(-2)),
              primary_key: audit.data.auditable_id,
              oldValue: JSON.parse(audit.data.old_values),
              newValue: JSON.parse(audit.data.new_values)
            })
          })

          const pagination = audits.pagination
          this.pagination = {
            ...this.pagination,
            total: pagination.total,
            page: pagination.current_page,
            itemsPerPage: pagination.per_page
          }
        })
        .finally(() => { this.isLoading = false })
    },
    preview (value) {
      this.dialogInformation = true
      this.dialogInformationData = value
      this.oldValue = value.oldValue
      this.newValue = value.newValue
    }
  }
}
</script>
