<template>
  <div class="pa-6">
    <v-row class="m-0">
      <v-col cols="12" md="6" class="py-0 tw-bg-white">
        <v-subheader>Pessoas</v-subheader>
        <draggable v-model="peoples" group="people" style="min-height: 10px">
          <div v-for="(item) in peoples" :key="item.data.id">
            <v-menu
              v-model="item.menu"
              bottom
              right
              transition="scale-transition"
              origin="top left"
              class="ma-2"
            >
              <template v-slot:activator="{ on }">
                <v-chip pill v-on="on" class="ma-2 tw-p-2">
                  <v-avatar left>
                    <v-img :src="item.data.avatar"></v-img>
                  </v-avatar>
                  {{ item.data.name }}
                </v-chip>
              </template>
              <v-card width="400">
                <v-list dark>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img :src="item.data.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.data.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.data.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="item.menu = false">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item :to="`/user/profile/${item.data.id}`" target="_blank">
                    <v-list-item-action>
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-list-item-action>
                    <v-list-item-subtitle>{{ item.data.email }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </draggable>
      </v-col>
      <v-col cols="12" md="6" class="py-0 tw-bg-white">
        <v-subheader>Administradores</v-subheader>
        <!-- {{ formatAdm(adm) }} -->
        <draggable v-model="adm" group="people" style="height: 100%">
          <div v-for="(item) in adm" :key="item.data.id">
            <v-menu
              v-model="item.menu"
              bottom
              right
              transition="scale-transition"
              origin="top left"
              class="ma-2"
            >
              <template v-slot:activator="{ on }">
                <v-chip pill v-on="on" class="ma-2 tw-p-2">
                  <v-avatar left>
                    <v-img :src="item.data.avatar"></v-img>
                  </v-avatar>
                  {{ item.data.name }}
                </v-chip>
              </template>
              <v-card width="300">
                <v-list dark>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img :src="item.data.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.data.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.data.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="item.menu = false">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item @click="() => {}">
                    <v-list-item-action>
                      <v-icon>mdi-briefcase</v-icon>
                    </v-list-item-action>
                    <v-list-item-subtitle>{{ item.data.email }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </draggable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false,
    peoples: [],
    adm: [],
    listPeoples: [],
    listAdm: [],
    filteredPeoples: [],
    page: {
      title: 'Administradores'
    },
    text: {
      searchLabel: 'Pesquisar administradores',
      emptyLabel: 'Nenhuma escola encontrada',
      addLabel: 'Adicionar administradores',
      addUrl: '/school/add',
      importLabel: 'Importar administradores',
      importUrl: '/school/add'
    },
    sortBy: 'name',
    showSelect: true
  }),
  mounted () {
    this.getPeoples()
  },
  methods: {
    formatAdm (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        if (value.data.roles.length) {
          const obj = {
            user: value.metadata.id,
            roles: value.data.roles || ['ADMIN']
          }
          arr.push(obj)
        }
      })
      return arr
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        if (value.data) {
          const obj = {
            ...value,
            menu: false
          }
          arr.push(obj)
        }
      })
      return arr
    },
    async getPeoples () {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      if (id) {
        await this.$axios.get(`/organizations/${id}/users?page=1&per_page=25`)
          .then(response => {
            this.peoples = this.formatPeoples(response.data.items)
          })
        await this.$axios.get(`/organizations/${id}/users?roles[]=ADMIN&per_page=1000`)
          .then(response => {
            this.adm = this.formatPeoples(response.data.items)
          })
      }
    }
  }
}
</script>
