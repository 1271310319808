<template>
  <v-card :elevation="elevation">
    <v-toolbar flat color="primary" dark v-if="questions.length && toolbar">
      <v-toolbar-title>Questões</v-toolbar-title>
    </v-toolbar>
    <v-tabs v-if="questions.length" :loading="loading" :vertical="!$vuetify.breakpoint.mobile" @change="reRender()">
      <v-tab
        v-for="(question, index) in questionsFiltered"
        :key="index"
      >Questão {{ question.data.order + concat }}</v-tab>
      <template>
        <v-tab-item ref="answer" v-for="question in questions" :key="question.id">
          <v-card flat>
            <v-card-text class="py-0 px-0 px-md-2">
              <v-card flat class="grey lighten-4 md:tw-p-4">
                <v-card-text v-if="!examLoading" class="py-0 px-0 px-md-2">
                  <div class="mt-3 mb-3">
                    <div class="d-flex flex-wrap">
                      <div>
                        <div>{{ question.data.teaching_stage.name }} - {{ question.data.curricular_component.name }} - {{ question.data.knowledge_area.name }}</div>
                        <div class="tw-text-gray-600 tw-font-medium tw-text-xl">
                          Questão
                          {{ getQuestionType(question.data.wordings[0].data.type) }}
                        </div>
                        <div>{{ getQuestionDifficulty(question.data.difficulty) }}</div>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        :to="`/exam/application/question/edit/${$route.params.id}/${question.metadata.id}`"
                        outlined
                        rounded
                        v-if="level>=5"
                      >Editar questão</v-btn>
                    </div>
                  </div>
                  <v-card outlined class elevation="2">
                    <v-card-text>
                      <div
                        class="text--primary tw-max-h-[21rem] tw-min-h-[21rem] tw-overflow-y-scroll question"
                        ref="text"
                        id="text"
                      >
                        <div v-html="question.data.main_text" id="enunciado"></div>
                        <mutiple-choice-preview
                          v-if="question.data.wordings[0].data.type === 'MULTIPLE_CHOICE'"
                          :options="question.data.wordings[0].data.options" />
                        <matrix-preview
                          v-if="question.data.wordings[0].data.type === 'MATRIX'"
                          :wordings="question.data.wordings" />
                        <slider-preview
                          v-if="question.data.wordings[0].data.type === 'SLIDER'"
                          :sliderData="question.data.wordings[0].data.params"/>
                        <multiple-choice-multiple-answer
                         v-if="question.data.wordings[0].data.type === 'MULTIPLE_CHOICE_MULTIPLE_ANSWER'"
                         :options="question.data.wordings[0].data.options"
                        />
                        <rating-preview
                         v-if="question.data.wordings[0].data.type === 'RATING'"
                         v-model="question.data.wordings[0].data.params.length"
                         :length="question.data.wordings[0].data.params.length"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                  <div class="mx-2 tw-max-h-[10rem] tw-overflow-y-scroll">
                    <div class="mt-2" v-if="question.data.matters && question.data.matters.length">
                      <div>Assunto</div>
                      <v-chip-group active-class="deep-purple accent-4 white--text" column>
                        <v-chip
                          v-for="(item, index) in question.data.matters"
                          :key="index"
                        >{{ item }}</v-chip>
                      </v-chip-group>
                    </div>

                    <div
                      class="mt-2"
                      v-if="question.data.competency_matrices && question.data.competency_matrices.length"
                    >
                      <div>Matriz de Referência</div>

                      <v-chip-group active-class="deep-purple accent-4 white--text" column>
                        <v-tooltip
                          bottom
                          v-for="(item, index) in question.data.competency_matrices"
                          :key="index"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                            >{{ item.type === "OWN" ? "Instituição" : item.type }} - {{ item.code }}</v-chip>
                          </template>
                          <span>{{ item.description }}</span>
                        </v-tooltip>
                      </v-chip-group>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text class="justify-center" v-else>
                  <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </template>
    </v-tabs>
    <slot />
  </v-card>
</template>

<script>
import alpha from '@/libs/lists.js'
import { mapState } from 'vuex'
import { getQuestionType, getQuestionDifficulty } from '@/utils/question'
import MutipleChoicePreview from '@/components/wording-preview/MultipleChoicePreview.vue'
import MatrixPreview from '@/components/wording-preview/MatrixPreview.vue'
import SliderPreview from '@/components/wording-preview/SliderPreview.vue'
import MultipleChoiceMultipleAnswer from '../wording-preview/MutipleChoiceMutipleAnswer.vue'
import RatingPreview from '../wording-preview/RatingPreview.vue'
import collect from 'collect.js'

export default {
  components: { MutipleChoicePreview, MatrixPreview, SliderPreview, MultipleChoiceMultipleAnswer, RatingPreview },
  props: {
    questions: {
      type: Array,
      default () {
        return []
      }
    },
    toolbar: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    elevation: {
      type: Number,
      default: 2
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    questions (newValue, oldValue) {
      this.questionNumber(this.questions)
      this.$nextTick(() => {
        this.reRender()
      })
    }
  },
  data () {
    return {
      alpha,
      drag: false,
      tab: null,
      scrollY: false,
      show: false,
      id: '',
      concat: 0
    }
  },
  created () {
    this.questionNumber(this.questions)
  },
  methods: {
    getQuestionType,
    getQuestionDifficulty,
    questionNumber (questions) {
      const data = collect(questions).where('data.order', 0)
      if (data.count() > 0) {
        this.concat = 1
      }
    },
    reRender () {
      if (window.MathJax) {
        setTimeout(() => {
          const elements = document.getElementsByClassName('math-tex')

          window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])

          // rendering done

          if (elements.length) {
            for (const id in elements) {
              elements[id].style.cssText += ';display:block;'
            }
          }
        }, 1000)
      }
    }
  },
  beforeMounted () {
    this.reRender()
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
  },
  computed: {
    ...mapState(['user']),
    level () {
      return this.user.level
    },
    examDatabase () {
      return this.search.typeExam === 1
    },
    examLoading () {
      return this.$store.state.exams.examLoading || this.loading
    },
    questionsFiltered () {
      return collect(this.questions).sortBy('data.order').all()
    }
  }
}
</script>

<style lang="scss">
.question table td {
  border: 1px solid !important;
}
.math-tex{
  display: none;
}
.v-tabs-slider {
  display: none;
}
div#MathJax_Message {
    display: none!important;
}
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
#option p {
  margin-bottom: unset;
}
</style>
