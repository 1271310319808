<template>
  <v-row class="pa-10 align-center">
    <v-col cols="12" md="4">
      <v-card
        class="mx-auto text-center"
        max-width="344"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              CERTIFICADO
            </div>
            <v-list-item-title class="font-weight-bold">Treinamento Pruvo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn
            outlined
            rounded
            text
            @click="showCertificate = true"
          >
            Ver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <DialogViewCertificate
      @closeDialog="showCertificate = false"
      :show-certificate="showCertificate"
      :finished-at="finishedAt"
      :name="name"
      :organization-name="organizationName"/>
  </v-row>
</template>

<script>
import DialogViewCertificate from '../dialog/DialogViewCertificate'
export default {
  name: 'CardCertificates',
  props: ['name', 'organizationName', 'finishedAt'],
  components: { DialogViewCertificate },
  data: () => ({
    showCertificate: false
  })
}
</script>

<style scoped>

</style>
