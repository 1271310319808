<template>
    <v-stepper v-model="e6" vertical elevation="0">

        <StepComponent
            title="Dados pessoais"
            step=1
            :complete="e6 > 1"
            @addRole="e6 = 2"
            @next="e6 = 2"
            @jumpRoles="e6 = 5"
            :buttons="isEdit ? getButtons(['next']) : getButtons(['addRole','jumpRoles'])"
        >
            <template #content>
                <form-user :value="form" :isEdit="isEdit"/>
            </template>
        </StepComponent>

        <StepComponent
            title="Funções na organização"
            step=2
            summary="(Campo opcional)"
            :complete="e6 > 2"
            @back="e6 = 1"
            @next="e6 = 3"
            @jump="e6 = 3"
            :buttons="organizationSelected.length ? getButtons(['next', 'back']) :  getButtons(['jump', 'back'])"
        >
            <template #content>
                <div class="pa-1">
                    <v-select
                        v-model="organizationSelected"
                        multiple
                        outlined
                        chips
                        label="Funções administrativas"
                        :items="roles"
                        item-text="value"
                        item-value="name"
                    />
                </div>
            </template>
        </StepComponent>

        <StepComponent
            title="Funções nas escolas"
            step=3
            summary="(Campo opcional)"
            :complete="e6 > 3"
            @back="e6 = 2"
            @next="e6 = 4"
            @jump="e6 = 4"
            :buttons="schoolSelected.length ? getButtons(['next', 'back']) : getButtons(['jump', 'back'])"
        >
            <template #content>
                <div class="pa-1">
                    <FormUserSchoolRoles
                        v-model="schoolSelected"
                        :schools="schools"
                        @loadMore="loadMore('SCHOOLS')"
                    />
                </div>
            </template>
        </StepComponent>

        <StepComponent
            title="Funções nas turmas"
            step=4
            summary="(Campo opcional)"
            :complete="e6 > 4"
            @next="e6 = 5"
            @back="e6 = 3"
            @jump="e6 = 5"
            :buttons="getButtons([courseSelected.length ? 'next' : 'jump', 'back'])"
        >
            <template #content>
                <div class="pa-1">
                    <FormUserCourseRoles
                        v-model="courseSelected"
                        :schools="schools"
                        @loadMore="loadMore('SCHOOLS')"
                    />
                </div>
            </template>
        </StepComponent>

        <StepComponent
            title="Confirmar dados"
            step=5
            @cancel="cancel"
            @back="e6 = 4"
            @save="save"
            :buttons="getButtons(['save', 'back', 'cancel'])"
        >
          <template #content>
            <v-card
              class="mb-5"
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Dados pessoais
                  </div>
                  <v-list-item-subtitle>Nome: {{ form.name ?? 'Não preenchido' }}</v-list-item-subtitle>
                  <v-list-item-subtitle>E-mail: {{ form.email ?? 'Não preenchido' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
              v-if="organizationSelected.length"
              class="mb-5"
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Funções na organização
                  </div>
                  <v-list-item-subtitle v-for="item in organizationSelected" :key="item">
                    {{ getRoleName(item) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
              v-if="schools.items.length && schoolSelectedFiltered.length"
              class="mb-5"
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Funções nas escolas
                  </div>
                  <v-list-item-subtitle v-for="item in schoolSelectedFiltered" :key="item">
                    {{ `${getRolesNamesOfSchools(item.role) ?? ''} | ${getSchoolName(item.school) ?? 'Nome da escola não encontrado'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
              v-if="courseSelectedFiltered.length"
              class="mb-5"
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Funções nas turmas
                  </div>
                  <v-list-item-subtitle v-for="item in courseSelectedFiltered" :key="item">
                    {{ `${getRoleNameOfCourse(item.role)} | ${item.course_name ?? 'Nome da turma não encontrado'}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>
        </StepComponent>

    </v-stepper>
</template>

<script>
import StepComponent from '@/components/step/StepComponent.vue'
import FormUserSchoolRoles from '@/components/forms/FormUserSchoolRoles.vue'
import FormUserCourseRoles from '@/components/forms/FormUserCourseRoles.vue'
import { roles } from '@/constants/roles'
import collect from 'collect.js'

export default {
  name: 'FormUserSteps',
  components: { StepComponent, FormUserSchoolRoles, FormUserCourseRoles },
  props: ['isEdit', 'userData'],
  data () {
    return {
      e6: 1,
      form: {},
      organizationSelected: [],
      schoolSelected: [],
      courseSelected: [],
      roles,
      buttons: [
        { text: 'Próximo', emit: 'next', outlined: false },
        { text: 'Pular etapa', emit: 'jump', outlined: false },
        { text: 'Adicionar funções', emit: 'addRole', outlined: false },
        { text: 'Salvar', emit: 'save', outlined: false },
        { text: 'Voltar', emit: 'back', outlined: true },
        { text: 'Não adicionar funções agora', emit: 'jumpRoles', outlined: true },
        { text: 'Cancelar', emit: 'cancel', outlined: true }
      ],
      organizationId: '',
      schools: {
        items: [],
        selected: null,
        selected_group: [],
        loading: false,
        disabled: false,
        pagination: {}
      },
      rolesSchool: [
        { text: 'Coordenador', value: 'SCHOOL_PEDAGOGICAL_COORDINATOR' },
        { text: 'Diretor', value: 'PRINCIPAL' }
      ],
      rolesCourse: [
        { text: 'Professor', value: 'TEACHER' },
        { text: 'Aluno', value: 'STUDENT' }
      ]
    }
  },
  computed: {
    schoolSelectedFiltered () {
      return collect(this.schoolSelected).where('operation', 'ADD').all()
    },
    courseSelectedFiltered () {
      return collect(this.courseSelected).where('operation', 'ADD').all()
    }
  },
  created () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getSchools()
    if (this.isEdit) {
      this.mountData(this.userData)
    }
  },
  methods: {
    cancel () {
      this.e6 = 1
      this.form = {}
      this.$router.go(-1)
    },
    save () {
      const data = {
        user: this.form,
        org_roles: this.organizationSelected,
        school_roles: this.schoolSelected,
        course_roles: this.courseSelected
      }
      this.$emit('save', data)
    },
    getButtons (emits) {
      return collect(this.buttons).whereIn('emit', emits).all()
    },
    getSchools (getMore = false) {
      this.schools.loading = true
      this.schools.disabled = true

      if (!getMore) {
        this.schools.items = []
        this.schools.pagination = {
          current_page: 0
        }
      }

      this.$axios.post(`/organizations/${this.organizationId}/lists/schools?page=${this.schools.pagination.current_page + 1}`)
        .then(response => {
          collect(response.data.items)
            .map(school => this.schools.items.push(
              { name: school.name, value: school.id }
            ))

          this.schools.pagination = {
            ...response.data.pagination,
            last_page: Math.ceil(response.data.pagination.total / response.data.pagination.per_page)
          }
        })
        .finally(() => {
          this.schools.loading = false
          this.schools.disabled = false
        })
    },
    getRoleName (value) {
      return collect(this.roles).where('name', value).first().value
    },
    getRolesNamesOfSchools (roles) {
      const roleName = []
      collect(this.rolesSchool).map(item => {
        if (roles.includes(item.value)) {
          roleName.push(item.text)
        }
      })
      return roleName.toString().replace(',', ' e ')
    },
    getSchoolName (schoolId) {
      return collect(this.schools.items).where('value', schoolId).first().name
    },
    getRoleNameOfCourse (roles) {
      const roleName = []
      collect(this.rolesCourse).map(item => {
        if (roles.includes(item.value)) {
          roleName.push(item.text)
        }
      })
      return roleName.toString().replace(',', ' e ')
    },
    mountData (value = null) {
      if (!value) {
        return
      }
      this.form = value
      this.organizationSelected = value.acl.organizationAcl

      collect(value.acl.schoolAcl).each(item => {
        item.operation = 'ADD'
        this.schoolSelected.push(item)
      })

      collect(value.acl.courseAcl).each(item => {
        item.operation = 'ADD'
        this.courseSelected.push(item)
      })
    },
    loadMore (type) {
      if (type === 'SCHOOLS') {
        if (this.schools.pagination.current_page === this.schools.pagination.last_page) {
          return
        }
        this.getSchools(true)
      }
    }
  }
}
</script>
