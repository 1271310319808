<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="orderedItems"
    :sort-by="sortBy"
    :disable-sort="disableSort"
    class="contact-listing-app"
    :search="search"
    :show-select="showSelect"
    @click:row="handleClick"
    :class="[tableUser ? 'table-user' : 'table-data']"
    style="cursor: pointer"
    @toggle-select-all="selectAllToggle"
    :options.sync="paginationConfig"
    :server-items-length="paginationConfig.total"
    :footer-props="{
      'show-current-page': true,
      'disable-items-per-page': true
    }"
    @update:sort-by="updateSort"
    @update:sort-desc="updateSort"
  >
    <template v-slot:top v-if="showSearch || displayActionItems">
      <v-container class="my-2">
        <v-row class="tw-items-center">
          <v-spacer />
          <v-col class="position-relative py-5" cols="12" lg="6" v-if="showSearch">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="text.searchLabel"
              outlined
              dense
              background-color="transparent"
              hide-details
            ></v-text-field>
            <div class="hint">{{ searchHint }}</div>
          </v-col>
          <v-col cols="12" lg="6" v-if="showClearFilter">
            <v-btn @click="$emit('clearFilter')" color="primary">Limpar filtro</v-btn>
          </v-col>
          <v-col cols="12" lg="6" align="right">
            <v-menu bottom left v-if="displayActionItems">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, i) in actionItems" :key="i">
                  <template
                    v-if="!item.needSelect || (item.needSelect && showSelect)"
                  >
                    <v-list-item-title @click="item.action">
                      <v-icon class="mr-3">{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-list-item-title>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.address="{ item }">
      <div
        class="d-flex align-center flex-column flex-sm-row tw-w-full"
        :class="isSelected ? 'selected' : ''"
      >
        <div class="tw-text-xl sm:tw-text-lg tw-mb-3 sm:mb-0">
          {{ item.name }}
          <span
            v-if="item.address"
            class="subtitle-2 font-weight-regular d-block"
            :class="{ 'text-no-wrap': !$vuetify.breakpoint.xs }"
            >{{ item.city }}</span
          >
        </div>
        <!-- <div v-if="item.numberOfNames">
          <v-chip
            class="ml-4"
            color="primary"
            small
            label
            text-color="white"
          >+ {{ item.numberOfNames }}</v-chip>
        </div>-->
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <div
        class="d-flex justify-end align-center flex-column flex-sm-row tw-w-full"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in item.actions"
              :key="index"
            >
              <v-list-item-title @click="$router.push(item.url)" class="tw-cursor-pointer">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-slot:item.buttons="{ item }">
      <div class="tw-flex">
        <div class="mr-3">
          <v-btn dense small color="primary" @click.stop="openUserProfile(item.id)">
            <small class="tw-text-white">Ver perfil</small>
          </v-btn>
        </div>
        <div v-if="item.is_enabled">
          <v-btn dense small color="red" @click.stop="$emit('deactivateUser', item.id)">
            <small class="tw-text-white">Inativar</small>
          </v-btn>
        </div>
        <div v-else>
          <v-btn dense small color="primary" @click.stop="$emit('activateUser', item.id)">
            <small>Ativar</small>
          </v-btn>
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.name="{ item }">
      <div
        class="d-flex align-center flex-column flex-sm-row tw-w-full"
        :class="isSelected ? 'selected' : ''"
      >
        <div :class="$vuetify.breakpoint.name == 'xs' ? 'mr-0' : 'mr-4'">
          <v-img
            v-if="item && item.avatar"
            :src="item.avatar"
            :width="$vuetify.breakpoint.xs ? '80px' : '45px'"
            class="rounded-circle img-fluid"
          ></v-img>
        </div>
        <div
          class="
            tw-text-xl
            sm:tw-text-lg
            tw-my-1.5
            sm:mb-0
            d-flex
            flex-column
            align-center align-sm-start
          "
        >
          {{ item.name }}
          <span
            v-if="item.email"
            class="subtitle-2 font-weight-regular d-block"
            :class="{ 'text-no-wrap': !$vuetify.breakpoint.xs }"
            >{{ item.email }}</span
          >
        </div>
        <v-tooltip bottom v-if="item.classroom">
          <template v-slot:activator="{ on, attrs }">
            <a
              :href="item.classroom"
              target="_blank"
              rel="noopener noreferrer"
              v-bind="attrs"
              v-on="on"
              @click.stop
            >
              <Classroom :height="20" class="mx-2" />
            </a>
          </template>
          <span>Turma sincronizada com o classroom</span>
        </v-tooltip>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.roles="{ item }">
      <div class="d-flex align-center tw-justify-center tw-flex-wrap">
        <div
          v-for="i in item.roles"
          :key="i"
          class="
            tw-bg-blue-400
            tw-rounded-full
            tw-py-1
            tw-px-2
            tw-text-white
            tw-capitalize
            mt-2
          "
          :class="$vuetify.breakpoint.name == 'xs' ? 'mr-0' : 'mr-2'"
        >
          {{
            i === "SCHOOL_PEDAGOGICAL_COORDINATOR"
              ? "Coordenador"
              : i === "PRINCIPAL"
              ? "Diretor"
              : i === "STUDENT"
              ? "Aluno"
              : i === "TEACHER"
              ? "Professor"
              : ""
          }}
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`item.status.name`]="{ item }">
      <div class="d-flex align-center tw-justify-center tw-flex-wrap">
        <v-chip class="ma-2" :color="item.status.color" outlined>
          {{ item.status.name }}
        </v-chip>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.courses="{ item }">
      <div class="d-flex align-center tw-justify-center tw-flex-wrap">
        <!-- <div
          class="mr-2 tw-bg-blue-400 tw-rounded-full tw-py-1 tw-px-2 tw-text-white tw-capitalize mt-2"
        > -->
        <v-chip
          pill
          v-for="i in item.courses"
          :key="i.id"
          @click="openCourse(i)"
        >
          <v-avatar left>
            <v-img
              :src="`https://gravatar.com/avatar/${md5(
                i.id
              )}?s=80&d=identicon&r=x`"
            ></v-img>
          </v-avatar>
          {{ i.name }}
        </v-chip>
        <!-- </div> -->
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.data-table-select="{ item, isSelected, select }">
      <template v-if="item.selectDisabled">
        <v-tooltip bottom>
          <!-- eslint-disable-next-line -->
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox
              :value="isSelected"
              :readonly="item.selectDisabled"
              :disabled="item.selectDisabled"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <span>{{ item.disabledDescription || "" }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-simple-checkbox
          :value="isSelected"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.title="{ item }">
      <div class="d-flex align-center">
        <div class="font-datatable-item">
          {{ item.title }}
          <span
            v-if="item.subtitle"
            class="subtitle-2 font-weight-regular d-block"
            :class="{ 'text-no-wrap': !$vuetify.breakpoint.xs }"
            >{{ item.subtitle }}</span
          >
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.question="{ item }">
      {{ item.question }}
      <span
        v-if="item.details"
        class="subtitle-2 font-weight-regular d-block text-truncate"
        >{{ item.details }}</span
      >
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.difficulty="{ item }">
      <span class="subtitle-2 font-weight-regular d-block">
        {{ item.difficulty }}
      </span>
      <v-icon
        v-if="!item.favorite"
        @click.stop="setFavorite(item)"
        color="grey lighten-1"
        >mdi-star-outline</v-icon
      >

      <v-icon v-else @click.stop="setFavorite(item)" color="yellow darken-3"
        >mdi-star</v-icon
      >
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.actionButton="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="gray"
            @click.stop="$emit('actionButton', item.actionButton)"
            class="mr-2"
            icon
          >
            <v-icon v-if="item.actionButton.icon" class="mr-1">{{
              item.actionButton.icon
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.actionButton.text }}</span>
      </v-tooltip>
       <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }" v-if="showDeleteButton(item.deleteButton.author)">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="gray"
            @click.stop="$emit('openModal', item.deleteButton)"
            class="mr-2"
            icon
          >
            <v-icon v-if="item.deleteButton.icon" class="mr-1">{{
              item.deleteButton.icon
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.deleteButton.text }}</span>
      </v-tooltip>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.coursesGroup="{ item }">
      <base-courses :totalCourses="item.courses_group_count" :coursesGroup="item.coursesGroup" />
    </template>
     <!-- eslint-disable-next-line -->
    <template v-slot:item.dates="{ item }">
      <div class="tw-bg-white text-center md:tw-bg-gray-200 tw-p-2 md:tw-p-4 tw-m-2 md:tw-m-0 tw-rounded-md">
        <!-- <div>Inicio {{ formatInTimeZone(parseISO(item.dates.startsAt), 'America/Sao_Paulo', 'yyyy-MM-dd HH:mm') }}</div>
        <div>Termino {{ formatInTimeZone(parseISO(item.dates.endsAr), 'America/Sao_Paulo', 'yyyy-MM-dd HH:mm') }}</div> -->
         <div>
          {{ format(parseISO(item.dates.startsAt),"dd/MM/yyyy HH:mm", {locale: pt}) }} - {{ format(parseISO(item.dates.endsAt),"dd/MM/yyyy HH:mm", {locale: pt}) }}
        </div>
      </div>
    </template>

    <template v-slot:no-data>{{ text.emptyLabel }}</template>
  </v-data-table>
</template>
<script>
import md5 from '@/libs/md5'
import { parseISO, format } from 'date-fns'
import pt from 'date-fns/locale/pt'

export default {
  props: {
    searchHint: String,
    showSearch: {
      type: Boolean,
      default: false
    },
    disableSort: Boolean,
    type: {},
    tableUser: Boolean,
    text: {},
    headers: {},
    sortBy: {},
    items: [],
    showSelected: {},
    showSelect: {},
    pagination: {
      type: Object,
      default () {
        return {
          page: 1
        }
      }
    },
    disabledDescription: {},
    displayActionItems: {
      type: Boolean,
      default: true
    },
    emit: {
      type: Boolean,
      default: false
    },
    showClearFilter: Boolean
  },
  data: () => ({
    pt,
    _timerId: null,
    isSelected: null,
    disabledCount: 0,
    selected: [],
    loading: false,
    actionItems: [
      {
        title: 'Cancelar Seleção',
        icon: 'mdi-delete-outline',
        needSelect: true
      },
      {
        title: 'Deletar Selecionados',
        icon: 'mdi-delete-outline',
        needSelect: true
      }
    ],
    dialog: false,
    singleSelect: false,
    search: '',
    sortProp: '',
    orderedItems: []
  }),
  computed: {
    paginationConfig: {
      get: function () {
        return this.pagination
      },
      set: function (newValue) {
        this.$emit('update', newValue)
      }
    }
  },
  methods: {
    md5,
    format,
    parseISO,
    showDeleteButton (item) {
      if (this.type === 0) {
        return true
      } else if (this.$store.state.user.me.metadata.id === item) {
        return true
      } else {
        return false
      }
    },
    openCourse (i) {
      const link = this.$router.resolve({
        path: `/course/${i.ref.split('/')[3]}/${i.id}`
      })
      window.open(link.href, '_blank')
    },

    selectAllToggle (props) {
      if (
        this.selected.length !==
        this.orderedItems.length - this.disabledCount
      ) {
        this.selected = []
        const self = this
        props.orderedItems.forEach((item) => {
          if (!item.selectDisabled) {
            self.selected.push(item)
          }
        })
      } else this.selected = []
    },
    removeSelection () {
      this.selected = []
    },
    deletePeople () {
      this.$emit('delete', this.selected)
    },
    handleClick (item) {
      if (item.url) {
        return this.$router.push({ path: item.url })
      }
      this.$emit('select-item', item)
    },
    setFavorite (item) {
      const index = this.orderedItems.indexOf(item)
      this.orderedItems[index].favorite = !this.orderedItems[index].favorite
    },
    updateSort (item) {
      if (item === undefined) {
        // reset sort
        this.orderedItems = this.originalItems.slice()
      } else {
        if (typeof item === 'string') {
          this.sortProp = item
          // changed column
          this.orderedItems.sort((x, y) => {
            if (x[this.sortProp] > y[this.sortProp]) {
              return 1
            }
            if (x[this.sortProp] < y[this.sortProp]) {
              return -1
            }
            return 0
          })
        } else if (typeof item === 'object') {
          if (item[0] === false) {
            // changed direction (asc)
            this.orderedItems.sort((x, y) => {
              if (x[this.sortProp] > y[this.sortProp]) {
                return 1
              }
              if (x[this.sortProp] < y[this.sortProp]) {
                return -1
              }
              return 0
            })
          } else if (item[0] === true) {
            // changed direction (desc)
            this.orderedItems.sort((x, y) => {
              if (x[this.sortProp] > y[this.sortProp]) {
                return -1
              }
              if (x[this.sortProp] < y[this.sortProp]) {
                return 1
              }
              return 0
            })
          }
        }
      }
    },
    openUserProfile (id) {
      const routeData = this.$router.resolve(`/user/profile/${id}`)
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    search (value) {
      clearTimeout(this._timerId)
      this._timerId = setTimeout(async () => {
        this.$emit('search', value)
      }, 1500)
    }
  },
  mounted () {
    this.actionItems = [
      {
        title: 'Cancelar Seleção',
        icon: 'mdi-delete-outline',
        action: () => {
          this.removeSelection()
        },
        needSelect: true
      },
      {
        title: 'Deletar Selecionados',
        icon: 'mdi-delete-outline',
        action: () => {
          this.deletePeople()
        },
        needSelect: true
      }
    ]
    this.originalItems = this.items.slice()
    this.orderedItems = this.items.slice()

    this.orderedItems.map((item) => {
      if (item.selectDisabled) self.disabledCount += 1
    })

    this.showItem()
  }
}
</script>
<style>
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row:last-child {
  padding-bottom: 40px !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__header {
  display: none !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 2px 0 !important;
  min-height: auto !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell {
  width: 100% !important;
  text-align: center !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell
  .v-simple-checkbox {
  width: 100% !important;
  text-align: left !important;
  padding: 0.5rem 1rem !important;
}

.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  flex-direction: column !important;
  align-items: flex-start;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell
  .font-datatable-item {
  min-width: 235px;
  width: 100%;
  word-wrap: normal;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 6px 18px !important;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  background: #efefef;
  border-radius: 10px;
  margin: 6px 18px;
}
.v-data-table__wrapper .v-data-table__mobile-table-row.v-data-table__selected {
  background: #ceebff !important;
}
.v-data-footer__select {
  opacity: 0;
}
</style>
