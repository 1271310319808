<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form ref="formExamApplication">
          <v-row>
            <v-col class="py-0 pt-3" md="12">
              <v-text-field
                v-model="form.name"
                :rules="[rules.required]"
                background-color="transparent"
                dense
                label="Título *"
                outlined
                required
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 editor-box mb-3 pt-3" cols="12" md="12">
              <label class="v-label v-label--active theme--light">
                <sup>Instruções *</sup>
              </label>
              <editor
                v-model="form.instructions"
                :init="editorConfig"
                api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh"
              />
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col class="py-5" cols="12">
              <label class="v-label v-label--active theme--light pb-5">
                <template class="text-caption grey">Aplicar avaliação por:</template>
              </label>
            </v-col>
          </v-row>

          <div class="ml-2 mt-5">
            <v-btn-toggle
              v-model="toggle_one"
              borderless
              mandatory
              class="flex align-center"
              @change="changeTab($event)"
            >
              <v-btn>
                Turmas
              </v-btn>
              <v-btn>
                Alunos
              </v-btn>
              <v-btn v-if="userLevel.includes('SUPER_ADMIN') || userLevel.includes('ADMIN') || userLevel.includes('PRINCIPAL') || userLevel.includes('SCHOOL_PEDAGOGICAL_COORDINATOR') || userLevel.includes('ORG_PEDAGOGICAL_COORDINATOR') || userLevel.includes('TECHNICAL')">
                Anos
              </v-btn>
              <v-btn v-if="userLevel.includes('SUPER_ADMIN') || userLevel.includes('ADMIN') || userLevel.includes('PRINCIPAL') || userLevel.includes('SCHOOL_PEDAGOGICAL_COORDINATOR') || userLevel.includes('ORG_PEDAGOGICAL_COORDINATOR') || userLevel.includes('TECHNICAL')">
                Escolas
              </v-btn>
              <v-btn v-if="userLevel.includes('SUPER_ADMIN') || userLevel.includes('ADMIN') || userLevel.includes('ORG_PEDAGOGICAL_COORDINATOR') || userLevel.includes('TECHNICAL')">
                Instituição
              </v-btn>
            </v-btn-toggle>

            <div v-if="toggle_one === 0">
              <v-card class="pa-5 mb-5">
                <v-card-title>Selecionar turmas</v-card-title>
                <v-card-text>
                  <v-row class="my-5 mt-4">
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                        v-model="schools.selected"
                        :items="schools.items"
                        :disabled="schools.disabled"
                        :loading="schools.loading"
                        label="Selecione uma escola"
                        @loadMore="loadMore('SCHOOLS')"
                        @change="getCourses(false)"
                      />
                    </v-col>
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                        v-model="courses.selected_group"
                        :items="courses.items"
                        :loading="courses.loading"
                        :disabled="courses.disabled || !schools.selected"
                        :multiple="true"
                        chips
                        label="Selecione as turmas"
                        @loadMore="loadMore('COURSES')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="w-full">
                  <v-btn color='primary' elevation="0" @click="setSelectedValue(courses.selected_group, 'courses')">Adicionar turmas</v-btn>
                </v-card-actions>
              </v-card>

            </div>
            <div v-else-if="toggle_one === 1">

              <v-card class="pa-5 mb-5">
                <v-card-title>
                  Selecionar alunos
                </v-card-title>
                <v-card-text>
                  <v-row class="my-5 mt-4">
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                          v-model="schools.selected"
                          :items="schools.items"
                          :disabled="schools.disabled"
                          :loading="schools.loading"
                          label="Selecione uma escola"
                          @loadMore="loadMore('SCHOOLS')"
                          @change="getCourses(false)"
                        />
                    </v-col>
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                        v-model="courses.selected"
                        :items="courses.items"
                        :loading="courses.loading"
                        :disabled="courses.disabled || !schools.selected"
                        label="Selecione uma turma"
                        @loadMore="loadMore('COURSES')"
                        @change="getStudents(false)"
                      />
                    </v-col>
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                        v-model="students.selected_group"
                        :disabled="students.disabled || !courses.selected || !schools.selected"
                        :items="students.items"
                        :loading="students.loading"
                        label="Selecione os alunos"
                        :multiple="true"
                        chips
                        @loadMore="loadMore('STUDENTS')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn color='primary' elevation="0" @click="setSelectedValue(students.selected_group, 'students')">Adicionar alunos</v-btn>
                </v-card-actions>
              </v-card>

            </div>
            <div v-else-if="toggle_one === 2">
              <v-card class="pa-5 mb-5">
                <v-card-title>
                  Selecionar anos
                </v-card-title>
                <v-card-title>
                  <v-row class="my-5 mt-4">
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                        v-model="teachingStages.selected"
                        :items="teachingStages.items"
                        :disabled="teachingStages.loading"
                        :loading="teachingStages.loading"
                        label="Selecione a etapa de ensino"
                        @change="getSchoolGrades(false)"
                        @loadMore="loadMore('TEACHING_STAGES')"
                      />
                    </v-col>
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                        v-model="schoolGrades.selected_group"
                        :disabled="schoolGrades.disabled || !teachingStages.selected"
                        :items="schoolGrades.items"
                        :loading="schoolGrades.loading"
                        label="Selecione os anos"
                        :multiple="true"
                        chips
                        @loadMore="loadMore('SCHOOL_GRADES')"
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-actions>
                  <v-btn color='primary' elevation="0" @click="setSelectedValue(schoolGrades.selected_group, 'schoolGrades')">Adicionar anos</v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div v-if="toggle_one === 3">
              <v-card class="pa-5 mb-5">
                <v-card-title>Selecionar escolas</v-card-title>
                <v-card-text>
                  <v-row class="my-5 mt-4">
                    <v-col class="py-0" cols="12" md="12">
                      <AutoComplete
                        v-model="schools.selected_group"
                        :disabled="schools.disabled"
                        :items="schools.items"
                        :loading="schools.loading"
                        label="Selecione as escolas"
                        :multiple="true"
                        chips
                        @loadMore="loadMore('SCHOOLS')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn color='primary' elevation="0" @click="setSelectedValue(schools.selected_group, 'schools')">Adicionar escolas</v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div v-if="toggle_one === 4">
              <v-card class="pa-5 mb-5">
                <v-card-title>Selecionar instituição</v-card-title>
                <v-card-text>
                  <v-row class="my-5 mt-4">
                    <v-col class="py-0" cols="12" md="12">
                      <v-switch
                        label="Aplicar para toda a instituição"
                        v-model="form.is_apply_to_all_organization"
                        @change="onSwitchIsApplyToAllOrganization"
                      />
                    </v-col>
                    <v-col>
                      <DialogConfirm
                        :dialog="dialogApplicationToAllOrganization"
                        @close="dialogApplicationToAllOrganization.show = false"
                        @action="form.is_apply_to_all_organization = true; dialogApplicationToAllOrganization.show = false"
                        @action2="form.is_apply_to_all_organization = false; dialogApplicationToAllOrganization.show = false"
                        >
                      </DialogConfirm>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <v-card
              class="mb-10"
              elevation="0"
              outlined
            >
              <v-card-text>
                <div class="text-overline mb-0">
                  Aplicando avaliação para:
                </div>
                <v-chip-group
                  v-model="selection"
                  active-class="deep-purple accent-4 white--text"
                  column
                >
                  <v-chip v-for="value in selectedValues.courses" :key="value.value" close
                          @click:close="removeSelectedValue(value.value, 'courses')">
                    {{ value.name }}
                  </v-chip>
                  <v-chip v-for="value in selectedValues.students" :key="value.value" close
                          @click:close="removeSelectedValue(value.value, 'students')">
                    {{ value.name }}
                  </v-chip>
                  <v-chip v-for="value in selectedValues.schoolGrades" :key="value.value" close
                          @click:close="removeSelectedValue(value.value, 'schoolGrades')">
                    {{ value.name }}
                  </v-chip>
                  <v-chip v-for="value in selectedValues.schools" :key="value.value" close
                          @click:close="removeSelectedValue(value.value, 'schools')">
                    {{ value.name }}
                  </v-chip>
                  <v-chip v-if="form.is_apply_to_all_organization" close @click:close="form.is_apply_to_all_organization = false">
                    Toda a instituição
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </div>
          <v-row>
            <v-col class="py-0 mb-3 pt-3" cols="12" md="4">
              <DatetimePicker
                label="Data de início *"
                v-model="starts_at"
                />
            </v-col>
            <v-col class="py-0 mb-3 pt-3" cols="12" md="4">
              <DatetimePicker
                label="Data final *"
                v-model="ends_at"
              />
            </v-col>
            <v-col class="py-0 mb-3 pt-3 position-relative" cols="12" md="4">
              <v-text-field
                v-model="form.duration"
                v-mask="'####'"
                :rules="[rules.required]"
                background-color="transparent"
                label="Tempo limite (em minutos) *"
                required
                validate-on-blur
              ></v-text-field>
              <div class="hint">* Caso não exista limite, preencha com 0 (zero)</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="form.has_limited_access">
                <template #label>
                  <span>Limitar quantas vezes o estudante pode acessar a avaliação</span>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon color="gray" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>Informe 1 para que o aluno acesse uma única vez e, caso saia ou feche o navegador,
                      aba, aplicativo, não tenha mais acesso e a aplicação será encerrada automaticamente
                      (não inclui quedas de conexão com a internet).</span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-row v-show="form.has_limited_access">
                <v-col cols="12">
                  <v-text-field
                    v-model="access_limit"
                    :rules="[rules.min1]"
                    label="Quantas vezes o estudante pode acessar a avaliação?"
                    min="1"
                    required
                    step="1"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-item-group>
                <v-checkbox
                  v-model="form.is_offline"
                  class="shrink mr-0 mt-0 mb-0"
                  @change="onSwitchIsOffline"
                  >
                  <template #label>
                    <span>A avaliação será aplicada na modalidade impressa</span>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-icon color="gray" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                      </template>
                      <span>Os alunos realizarão as provas no papel e as respostas deverão ser inseridas no gabarito online posteriormente.</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
                <v-checkbox
                  :disabled="form.is_offline"
                  v-model="form.wordings_random_order"
                  class="shrink mr-0 mt-0 mb-2"
                  hide-details
                  label="Mostrar alternativas embaralhadas para os alunos na resolução"
                >
                </v-checkbox>
                <v-checkbox
                  :disabled="form.is_offline"
                  v-model="form.questions_random_order"
                  class="shrink mr-0 mt-0 mb-3"
                  hide-details
                  label="Mostrar questões embaralhadas para os alunos na resolução"
                ></v-checkbox>
                <br/>
              </v-item-group>
            </v-col>
            <v-col cols="12">
              <v-card-text>
                <span class="subheading">Marcador de questões</span>
                <v-chip-group
                  v-model="form.marker"
                  active-class="blue--text text--accent-4"
                  mandatory
                >
                  <v-chip
                    v-for="(item, index) in marker"
                    :key="index"
                    :value="item.value"
                  >{{ item.name }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-col>
          </v-row>
          <button-save v-if="action === 'add'" class="mt-12" v-on:save="save"></button-save>
          <button-update v-else-if="action === 'edit'" class="mt-6" v-on:update="update"></button-update>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import editorConfig from '@/libs/editorConfig'
import Editor from '@tinymce/tinymce-vue'
import { mapMutations, mapState } from 'vuex'
import { formatISO, parseISO } from 'date-fns'
import collect from 'collect.js'
import { checkRole } from '@/utils/role'
import DatetimePicker from '../datetime-picker/DatetimePicker.vue'
import AutoComplete from '../select/AutoComplete.vue'

export default {
  components: {
    editor: Editor,
    DatetimePicker,
    AutoComplete
  },
  props: ['action', 'applicationData'],
  data: () => ({
    parseISO,
    editorConfig,
    selectedValues: {
      courses: [],
      students: [],
      schoolGrades: [],
      schools: []
    },
    toggle_one: 0,
    organizationId: '',
    selection: [],
    // AutoComplete DATA
    schools: {
      items: [],
      selected: null,
      selected_group: [],
      loading: false,
      disabled: false,
      pagination: {}
    },
    courses: {
      items: [],
      selected: null,
      selected_group: [],
      loading: false,
      disabled: false,
      pagination: {}
    },
    students: {
      items: [],
      selected: null,
      selected_group: [],
      loading: false,
      disabled: false,
      pagination: {}
    },
    teachingStages: {
      items: [],
      selected: null,
      selected_group: [],
      loading: false,
      disabled: false,
      pagination: {}
    },
    schoolGrades: {
      items: [],
      selected: null,
      selected_group: [],
      loading: false,
      disabled: false,
      pagination: {}
    },
    form: {
      name: '',
      marker: 'UPPER_ALPHA',
      type: 'COURSE',
      instructions: '',
      starts_at: '',
      duration: 0,
      ends_at: '',
      questions_random_order: false,
      wordings_random_order: false,
      answers_random_order: false,
      has_limited_access: false,
      access_limit: '1',
      is_apply_to_all_organization: false,
      is_offline: false
    },
    access_limit: '1',
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      min1: value => value >= 1 || 'O valor mínimo é 1.'
    },
    starts_at: '',
    ends_at: '',
    marker: [
      { name: 'Nenhuma', value: 'NONE' },
      { name: 'A, B, C', value: 'UPPER_ALPHA' },
      { name: 'a, b, c', value: 'LOWER_ALPHA' },
      { name: '1, 2, 3', value: 'DECIMAL' },
      { name: 'I, II, III', value: 'UPPER_ROMAN' },
      { name: 'i, ii, iii', value: 'LOWER_ROMAN' }
    ],
    dialogApplicationToAllOrganization: {
      show: false,
      title: 'Deseja aplicar essa avaliação para toda a instituição?',
      confirm: 'Sim',
      confirm2: 'Não',
      text: 'Ao aplicar essa avaliação para toda a instituição, todas as turmas receberão essa avaliação.'
    }
  }),
  watch: {
    starts_at (val) {
      this.form.starts_at = formatISO(val)
    },
    ends_at (val) {
      this.form.ends_at = formatISO(val)
    },
    access_limit (val) {
      this.form.access_limit = !val ? '1' : Math.floor(val)
    }
  },
  methods: {
    checkRole,
    formatISO,
    ...mapMutations(['setLoading']),
    changeTab (e) {
      const tabs = {
        // Turmas
        0: () => {
          this.getSchools()
        },
        // Alunos
        1: () => {
          this.getSchools()
        },
        // Anos
        2: () => {
          this.getTeachingStages()
        },
        // Escolas
        3: () => {
          this.getSchools()
        },
        // Instituições
        4: () => {}
      }
      if (tabs[e]) {
        tabs[e]()
      }
    },
    loadMore (type) {
      const types = {
        SCHOOLS: () => {
          if (this.schools.pagination.current_page === this.schools.pagination.last_page) {
            return
          }
          this.getSchools(true)
        },
        COURSES: () => {
          if (this.courses.pagination.current_page === this.courses.pagination.last_page) {
            return
          }
          this.getCourses(true)
        },
        STUDENTS: () => {
          if (this.students.pagination.current_page === this.students.pagination.last_page) {
            return
          }
          this.getStudents(true)
        },
        TEACHING_STAGES: () => {
          if (this.teachingStages.pagination.current_page === this.teachingStages.pagination.last_page) {
            return
          }
          this.getTeachingStages(true)
        },
        SCHOOL_GRADES: () => {
          if (this.schoolGrades.pagination.current_page === this.schoolGrades.pagination.last_page) {
            return
          }
          this.getSchoolGrades(true)
        }
      }
      if (types[type]) {
        types[type]()
      }
    },
    handleErrors (error) {
      collect(error.response.data.errors).each(value => {
        this.$store.dispatch('alert', { color: 'red', msg: value[0] })
      })
    },
    getSchools (getMore = false) {
      this.schools.loading = true
      this.schools.disabled = true

      if (!getMore) {
        this.schools.items = []
        this.schools.pagination = {
          current_page: 0
        }
      }
      this.$axios.get(`/organizations/${this.organizationId}/schools?page=${this.schools.pagination.current_page + 1}`)
        .then(response => {
          collect(response.data.items).each(value => {
            this.schools.items.push({
              name: value.data.name,
              value: value.metadata.id
            })
          })

          this.schools.pagination = {
            ...response.data.pagination,
            last_page: Math.ceil(response.data.pagination.total / response.data.pagination.per_page)
          }
        })
        .finally(() => {
          this.schools.loading = false
          this.schools.disabled = false
        })
    },
    getCourses (getMore = false) {
      this.courses.disabled = true
      this.courses.loading = true

      if (!getMore) {
        this.courses.items = []
        this.courses.pagination = {
          current_page: 0
        }
      }

      this.$axios.get(`/organizations/${this.organizationId}/schools/${this.schools.selected.value}/courses?page=${this.courses.pagination.current_page + 1}`)
        .then(response => {
          collect(response.data.items).each(value => {
            this.courses.items.push({
              name: value.data.name,
              value: value.metadata.id
            })
          })

          this.courses.pagination = {
            ...response.data.pagination,
            last_page: Math.ceil(response.data.pagination.total / response.data.pagination.per_page)
          }
        })
        .finally(() => {
          this.courses.disabled = false
          this.courses.loading = false
        })
    },
    getStudents (getMore = false) {
      this.students.loading = true
      this.students.disabled = true

      if (!getMore) {
        this.students.pagination = {
          current_page: 0
        }
        this.students.items = []
      }

      this.$axios.get(`/organizations/${this.organizationId}/schools/${this.schools.selected.value}/courses/${this.courses.selected.value}/people?roles[]=STUDENT&page=${this.students.pagination.current_page + 1}`)
        .then(response => {
          collect(response.data.items).each(value => {
            this.students.items.push({
              name: value.data.user.name,
              value: value.metadata.id
            })
          })

          this.students.pagination = {
            ...response.data.pagination,
            last_page: Math.ceil(response.data.pagination.total / response.data.pagination.per_page)
          }
        })
        .finally(() => {
          this.students.loading = false
          this.students.disabled = false
        })
    },
    getTeachingStages (getMore = false) {
      this.teachingStages.loading = true
      this.teachingStages.disabled = true

      if (!getMore) {
        this.teachingStages.pagination = {
          current_page: 0
        }
        this.teachingStages.items = []
      }

      this.$axios.get(`/organizations/${this.organizationId}/lists/teaching-stages?page=${this.teachingStages.pagination.current_page + 1}`)
        .then(response => {
          collect(response.data.items).each(value => {
            this.teachingStages.items.push({
              name: value.name,
              value: value.id
            })

            this.teachingStages.pagination = {
              ...response.data.pagination,
              last_page: Math.ceil(response.data.pagination.total / response.data.pagination.per_page)
            }
          })
        }).finally(() => {
          this.teachingStages.loading = false
          this.teachingStages.disabled = false
        })
    },
    getSchoolGrades (getMore = false) {
      if (this.teachingStages.selected.value === null) {
        return
      }

      this.schoolGrades.loading = true
      this.schoolGrades.disabled = true

      if (!getMore) {
        this.schoolGrades.items = []
        this.schoolGrades.pagination = {
          current_page: 0
        }
      }

      const page = `${this.schoolGrades.pagination.current_page + 1}`
      const payload = {
        _method: 'GET',
        teaching_stage: this.teachingStages.selected.value
      }

      this.$axios.post(`/organizations/${this.organizationId}/lists/school-grades?page=${page}`, payload)
        .then(response => {
          collect(response.data.items).each(value => {
            this.schoolGrades.items.push({
              name: `${value.name} | ${value.name_abbr}`,
              value: value.id
            })
          })

          this.schoolGrades.pagination = {
            ...response.data.pagination,
            last_page: Math.ceil(response.data.pagination.total / response.data.pagination.per_page)
          }
        })
        .finally(() => {
          this.schoolGrades.loading = false
          this.schoolGrades.disabled = false
        })
    },
    clearSelectSelecteds () {
      this.schools.selected = null
      this.schools.selected_group = []
      this.courses.selected = null
      this.courses.selected_group = []
      this.students.selected = null
      this.students.selected_group = []
      this.teachingStages.selected = null
      this.teachingStages.selected_group = []
      this.schoolGrades.selected = null
      this.schoolGrades.selected_group = []
    },
    verifyIfValueExistInArray (value, list) {
      return collect(this.selectedValues[list]).where('value', value).count() > 0
    },
    setSelectedValue (value, list) {
      if (!this.selectedValues[list]) {
        return
      }
      collect(value).each(el => {
        if (!this.verifyIfValueExistInArray(el.value, list)) {
          this.selectedValues[list].push(el)
        }
      })
      this.clearSelectSelecteds()
    },
    removeSelectedValue (id, list) {
      if (this.selectedValues[list]) {
        this.selectedValues[list] = collect(this.selectedValues[list]).where('value', '!=', id).all()
      }
    },
    getGroups () {
      return {
        ...(this.selectedValues.courses.length > 0 ? { courses_group: this.selectedValues.courses.map(el => ({ id: el.value })) } : {}),
        ...(this.selectedValues.schools.length > 0 ? { schools_group: this.selectedValues.schools.map(el => ({ id: el.value })) } : {}),
        ...(this.selectedValues.students.length > 0 ? { students_group: this.selectedValues.students.map(el => ({ id: el.value })) } : {}),
        ...(this.selectedValues.schoolGrades.length > 0 ? { school_grades_group: this.selectedValues.schoolGrades.map(el => ({ id: el.value })) } : {})
      }
    },
    isFormValid () {
      if (!this.$refs.formExamApplication.validate() && this.selection.length <= 0) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return this.$refs.formExamApplication.validate() && this.selection.length > 0
      } return true
    },
    save () {
      if (!this.isFormValid()) return

      this.form.duration = parseInt(this.form.duration)

      this.setLoading(true)
      const payload = {
        ...this.form,
        ...(this.form.has_limited_access ? { access_limit: this.access_limit } : {}),
        ...this.getGroups()
      }
      this.$axios
        .post(
          `/organizations/${this.organizationId}/exams/${this.$route.params.id}/applications`,
          payload
        )
        .then(response => {
          this.$store.dispatch('alert', { msg: 'Cadastrado com sucesso. Aguarde alguns minutos para os alunos serem notificados e carregados, por favor.' })
          this.$router.push(
            `/exam/application/view/${this.$route.params.id}/${response.data.metadata.id}`
          )
        })
        .catch(error => {
          if (error.response) {
            this.handleErrors(error)
          }
        }).finally(() => {
          this.setLoading(false)
        })
    },
    update () {
      if (!this.isFormValid()) return

      this.setLoading(true)
      const payload = {
        ...this.form,
        ...(this.form.has_limited_access ? { access_limit: this.access_limit } : {}),
        ...this.getGroups(),
        type: 'COURSE'
      }
      this.$axios.patch(`/organizations/${this.organizationId}/exams/${this.$route.params.id}/applications/${this.$route.params.application}`, payload)
        .then(response => {
          this.$store.dispatch('alert', { msg: 'Dados alterados com sucesso' })
          this.$router.push({
            path: `/exam/application/view/${this.$route.params.id}/${response.data.metadata.id}`,
            replace: true
          })
        })
        .catch(error => this.handleErrors(error))
        .finally(() => this.setLoading(false))
    },
    clearSelectedValues () {
      collect(this.selectedValues).each((value, key) => {
        this.selectedValues[key] = []
      })
    },
    onSwitchIsApplyToAllOrganization (value) {
      this.clearSelectedValues()
      this.clearSelectSelecteds()
      if (value) {
        this.dialogApplicationToAllOrganization.show = true
      }
    },
    onSwitchIsOffline (value) {
      if (value) {
        this.form.wordings_random_order = false
        this.form.answers_random_order = false
        this.form.questions_random_order = false
      }
    }
  },
  computed: {
    ...mapState(['user']),
    userLevel () {
      return this.checkRole(this.user.me)
    }
  },
  created () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getSchools()
    if (this.action === 'edit') {
      // TODO: refatorar
      this.form = this.applicationData
      delete this.form.author
      this.ends_at = this.applicationData.ends_at
      this.starts_at = this.applicationData.starts_at
      this.access_limit = this.applicationData.access_limit
      if (!this.form.is_apply_to_all_organization) {
        this.setSelectedValue(this.applicationData.courses_group, 'courses')
        this.setSelectedValue(this.applicationData.schools_group, 'schools')
        this.setSelectedValue(this.applicationData.school_grades_group, 'schoolGrades')
        this.setSelectedValue(this.applicationData.students_group, 'students')
      }
    }
  }
}
</script>
