import Vue from 'vue'
import App from './views/pages/AppPruvo.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VueQRCodeComponent from 'vue-qrcode-component'
// icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// mask
import { VueMaskDirective, VueMaskFilter } from 'v-mask'
import dotenv from 'dotenv'

dotenv.config()
Vue.config.silent = true
Vue.directive('mask', VueMaskDirective)
Vue.filter('VMask', VueMaskFilter)
library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('qr-code', VueQRCodeComponent)
Vue.config.productionTip = false

const requireComponent = require.context(
  './components',
  true, // deep search
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
  )

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
