const upperAlpha = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N'
}

const lowerAlpha = {
  0: 'a',
  1: 'b',
  2: 'c',
  3: 'd',
  4: 'e',
  5: 'f',
  6: 'g',
  7: 'h',
  8: 'i',
  9: 'j',
  10: 'k',
  11: 'l',
  12: 'm',
  13: 'n'
}

const upperRoman = {
  0: 'I',
  1: 'II',
  2: 'III',
  3: 'IV',
  4: 'V',
  5: 'VI',
  6: 'VII',
  7: 'VIII',
  8: 'IX',
  9: 'X'
}

const lowerRoman = {
  0: 'i',
  1: 'ii',
  2: 'iii',
  3: 'iv',
  4: 'v',
  5: 'vi',
  6: 'vii',
  7: 'viii',
  8: 'ix',
  9: 'x'
}

export {
  upperAlpha,
  lowerAlpha,
  upperRoman,
  lowerRoman
}
