<template>
  <!-- eslint-disable -->
  <v-container>
    <v-row class="mt-5">
      <v-col class="pb-0" cols="12" md="5">
        <v-text-field v-model="searchQuery" background-color="transparent" hide-details label="Pesquisar" outlined
          @input="search.isTyping = true">
          <template v-slot:append>
            <v-btn background-color="transparent" class="v-bnt-advanced-search mt-n1" color="grey" depressed text
              @click="toggle = !toggle">Busca Avançada
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="pb-0 d-flex justify-end" cols="12" md="7">
        <div class="md:tw-block tw-hidden">
          <v-btn-toggle v-model="search.typeSearch" mandatory @change="setTypeSearch()">
            <v-btn v-for="i in types" :key="i.value" :disabled="disabled" :value="i.value" outlined
              style="height: 56px">
              {{ i.name }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="tw-block md:tw-hidden tw-w-full">
          <v-select v-model="search.typeSearch" :items="types" background-color="transparent" item-text="name"
            item-value="id" label="Tipo" outlined @change="setTypeSearch()"></v-select>
        </div>
      </v-col>
    </v-row>
    <v-container v-show="toggle" class="rounded grey lighten-4 tw-p-4 mt-4" transition="slide-y-transition">
      <v-row class="mt-3">
        <v-col v-if="search.typeQuestion !== 'MY_QUESTIONS'" class="py-0" cols="12" md="6">
          <v-autocomplete v-model="search.author" :items="author" :loading="isLoading" :search-input.sync="searchAuthor"
            item-text="name" item-value="id" label="Autor" no-filter>
            <!-- eslint-disable-next-line -->
            <template v-slot:item="data">
              <template v-if="(typeof data.item !== 'object')">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <!-- eslint-disable-next-line -->
                  <img :src="data.item.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-autocomplete v-model="search.teachingStage" :items="teachingStageList"
            :loading="isLoadingTeachingStage" item-text="name" item-value="id" label="Etapa de ensino" no-filter return-object>
            <template #append-item>
              <div v-intersect="loadMore(pageTeachingStage, lastPageTeachingStage, 'teaching_stage')"></div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-autocomplete v-model="search.knowledgeArea" :disabled="knowledgeAreaDisabled" :items="knowledgeAreaList"
            :loading="isLoadingKnowledgeArea" item-text="name" item-value="id" label="Área de Conhecimento" no-filter
            return-object>
            <template #append-item>
              <div v-intersect="loadMore(pageKnowledgeArea, lastPageKnowledgeArea, 'knowledge_area')"></div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-autocomplete v-model="search.curricularComponent" :disabled="curricularDisabled"
            :items="curricularComponentList" :loading="isLoadingCurricular" item-text="name" item-value="id"
            label="Disciplina" no-filter return-object>
            <template #append-item>
              <div v-intersect="loadMore(pageCurricularComponent, lastPageCurricularComponent, 'curricular_component')"></div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-select v-model="search.difficulty" :disabled="sending" :items="difficulty" background-color="transparent"
            item-text="name" item-value="value" label="Dificuldade"></v-select>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-combobox v-model="search.tags" background-color="transparent" label="Tags" multiple>
            <template v-slot:selection="data">
              <v-chip :key="JSON.stringify(data.item)" :disabled="data.disabled" :input-value="data.selected" close
                v-bind="data.attrs" @click:close="data.parent.selectItem(data.item)">{{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-combobox v-model="search.matters" background-color="transparent" label="Assuntos" multiple>
            <template v-slot:selection="data">
              <v-chip :key="JSON.stringify(data.item)" :disabled="data.disabled" :input-value="data.selected" close
                v-bind="data.attrs" @click:close="data.parent.selectItem(data.item)">{{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right" class="p-4" cols="12" right>
          <v-spacer></v-spacer>
          <v-btn class="tw-m-2" color="primary" depressed large @click="clearFilters()">Limpar Filtros
          </v-btn>
          <v-btn class="tw-m-2" color="primary" depressed large @click="getQuestions()">Buscar</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-list>
      <v-list-item class="my-3">
        <v-list-item-action class="checkbox-width" v-if="selection">
          <v-checkbox v-model="checkAll.checked" :indeterminate="checkAll.indeterminate"
            @change="selectAll($event)"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Questão</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item-group class="tw-relative tw-min-h-[8rem]" two-line>
        <template v-for="(item, index) in items">
          <v-list-item class="py-2">
            <v-list-item-action class="checkbox-width" v-if="selection" :key="item.id">
              <v-checkbox v-model="item.checked" hide-details @change="setIndeterminate($event, item)"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="cursor-pointer" @click="openModal(index)">
              <v-list-item-title class="mb-3" v-text="convertHtmlToText(item.title)"></v-list-item-title>
              <v-list-item-subtitle class="mb-2" v-text="item.subtitle"></v-list-item-subtitle>
              <v-list-item-subtitle v-text="item.infoAuthor"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!$vuetify.breakpoint.xs">
              <small @click="goTo(item.id)" v-show="!isShowSelectedQuestions">
                <v-icon color="lighten-1">mdi-file-document-edit-outline</v-icon> Editar
              </small>
              <small>
                Dificuldade: <v-list-item-action-text v-text="item.difficulty"></v-list-item-action-text>
              </small>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index < items.length - 1" :key="index + (items.length * 2)"></v-divider>
        </template>
        <div v-if="loadingQuestions && !skeleton"
          class="tw-h-full tw-w-full tw-bg-gray-300 tw-bg-opacity-20 tw-absolute tw-top-0 tw-l-0 tw-flex tw-justify-center tw-items-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </div>
        <template v-if="loadingQuestions && skeleton">
          <v-skeleton-loader v-for="i in 5" :key="i" type="table-heading"></v-skeleton-loader>
        </template>
      </v-list-item-group>
      <v-divider />
    </v-list>
    <v-row justify="center">
      <v-col align="center" class="pb-0 pt-4" cols="12">
        <small>Total: {{ pagination.total }}</small>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="py-0" cols="6">
        <v-container class="max-width py-0">
          <v-pagination v-model="pagination.current_page" :length="Math.ceil(pagination.total / pagination.per_page) || 0"
            :light="true" class="my-4" depressed></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <template>
      <div class="text-center">
        <dialog-preview-question :dialog="dialog" @removeQuestion="removeQuestion($event)" />
      </div>
    </template>
  </v-container>
</template>
<script>
import collect from 'collect.js'
import debounce from 'lodash/debounce'
import { mapGetters, mapMutations, mapState } from 'vuex'
import Vue from 'vue'
import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue'
import { formatQuestions } from '@/utils/question'
import { convertHtmlToText } from '@/utils/helpers'
import { difficulties } from '@/constants/difficulties'

Vue.use(MathJax)
export default {
  props: {
    text: {},
    selection: {
      default: false
    },
    isShowSelectedQuestions: Boolean
  },
  data: () => ({
    skeleton: true,
    options: { tex: { inlineMath: [['\\(', '\\)']] } },
    initMathJax,
    renderByMathjax,
    loadingQuestions: false,
    id: '',
    searchQuery: '',
    isLoading: false,
    isLoadingOwner: false,
    searchAuthor: '',
    searchOwner: '',
    author: [],
    owner: [],
    dialog: {},
    difficulty: difficulties,
    knowledgeAreaList: [],
    curricularComponentList: [],
    teachingStageList: [],
    curricularDisabled: true,
    knowledgeAreaDisabled: true,
    isLoadingKnowledgeArea: false,
    isLoadingCurricular: false,
    isLoadingTeachingStage: false,
    idQuestionsDatabase: '9644a5ff-c737-48b7-9da1-8e7516fab288',
    search: {
      isTyping: false,
      isTypingMatter: false,
      tags: [],
      matters: [],
      author: '',
      teachingStage: '',
      curricularComponent: '',
      isLoadingcurricular: false,
      knowledgeArea: '',
      owner: '',
      subject: '',
      difficulty: '',
      wording_type: '',
      typeSearch: 0,
      typeQuestion: 'MY_QUESTIONS'
    },
    sending: false,
    typesQuestion: [
      { name: 'Objetiva', value: 'MULTIPLE_CHOICE' },
      { name: 'Discursiva', value: 'OPEN_ENDED' }
    ],
    scrollY: false,
    showMore: false,
    fetchQuestions: false,
    pagination: {
      current_page: 1,
      per_page: 25,
      prev_page_url: false,
      next_page_url: false
    },
    attrs: {
      class: 'mb-1',
      boilerplate: true,
      elevation: 2
    },
    checkAll: {
      checked: false,
      indeterminate: false
    },
    checked: [],
    toggle: false,
    actionItems: [],
    types: [
      { name: 'Minhas Questões', id: 0 },
      { name: 'Questões da Instituição', id: 1 },
      { name: 'Banco de Questões', id: 2 }
    ],
    items: [],
    disabled: false,
    pageTeachingStage: 1,
    lastPageTeachingStage: 1,
    pageKnowledgeArea: 1,
    lastPageKnowledgeArea: 1,
    pageCurricularComponent: 1,
    lastPageCurricularComponent: 1
  }),
  watch: {
    'search.knowledgeArea': function (value) {
      if (!value) {
        return
      }
      this.curricularDisabled = true
      this.getCurricularComponent(this.search.knowledgeArea.id)
    },
    'search.teachingStage': function (value) {
      if (!value) {
        return
      }
      this.getKnowledgeArea()
    },
    selectedQuestions () {
      const copySelected = this.selectedQuestions
      copySelected.forEach(a => {
        this.items.forEach(function (b) {
          if (a.id === b.id) {
            b.checked = true
          }
        })
      })
    },
    searchAuthor (value) {
      if (!value) {
        return
      }
      debounce(this.getAuthor, 1500)(value, this)
    },
    searchOwner (value) {
      if (!value) {
        return
      }
      debounce(this.getOwner, 1500)(value, this)
    },
    searchQuery: debounce(function () {
      this.search.isTyping = false
    }, 1500),
    'search.isTyping': function (value) {
      if (!value) {
        this.getQuestions()
      }
    },
    'pagination.current_page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getQuestions(true)
      }
    },
    'search.teachingStage.id': function () {
      this.curricularComponentList = []
      this.knowledgeAreaList = []
    },
    'search.knowledgeArea.id': function () {
      this.curricularComponentList = []
    }
  },
  methods: {
    convertHtmlToText,
    ...mapMutations('exams', ['SET_SELECTED', 'REMOVE_SELECTED']),
    checkExamQuestions () {
      const ids = collect(this.$store.state.exams.selectedQuestions)
        .pluck('import_id')
        .toArray()
      collect(this.items)
        .whereIn('id', ids)
        .each(question => {
          question.checked = true
        })
      this.items = collect(this.items)
        .when(this.isShowSelectedQuestions, items => {
          return items.sortByDesc('checked')
        })
        .toArray()
    },
    sortByBoolean (a, b) {
      if (a.checked > b.checked) {
        return -1
      }
      if (a.checked < b.checked) {
        return 1
      }
      return 0
    },
    getCurricularComponent (value) {
      this.isLoadingCurricular = true
      this.$axios.get(`/organizations/${this.id}/lists/curricular-component?knowledge_area=${value}`)
        .then((response) => {
          this.curricularComponentList.push(
            ...response.data.items.map(el => {
              return { ...el }
            })
          )
          this.lastPageCurricularComponent = response.data.pagination.last_page
        }).finally(() => {
          this.curricularDisabled = false
          this.isLoadingCurricular = false
        })
    },
    getTeachingStage () {
      this.isLoadingTeachingStage = true
      this.$axios.get(`/organizations/${this.id}/lists/teaching-stages?page=${this.pageTeachingStage}`)
        .then((response) => {
          this.teachingStageList.push(
            ...response.data.items.map(el => {
              return { ...el }
            })
          )
          this.lastPageTeachingStage = response.data.pagination.last_page
        }).finally(() => {
          this.isLoadingTeachingStage = false
        })
    },
    getKnowledgeArea () {
      this.isLoadingKnowledgeArea = true
      this.$axios.get(`/organizations/${this.id}/lists/knowledge-area?teaching_stage=${this.search.teachingStage.id ?? ''}`)
        .then((response) => {
          this.knowledgeAreaList.push(
            ...response.data.items.map(el => {
              return { ...el }
            })
          )
          this.lastPageKnowledgeArea = response.data.pagination.last_page
        }).finally(() => {
          this.isLoadingKnowledgeArea = false
          this.knowledgeAreaDisabled = false
        })
    },
    selectAll (event) {
      this.checkAll.checked = event
      if (event) {
        collect(this.items)
          .where('checked', true)
          .each(item => {
            this.REMOVE_SELECTED(item)
          })
        collect(this.items).each(item => {
          item.checked = event
          this.SET_SELECTED(item)
        })
      } else {
        collect(this.items).each(item => {
          item.checked = event
          this.REMOVE_SELECTED(item)
        })
      }
    },
    checkSelected (arr) {
      this.selectedQuestions.forEach(a => {
        arr.forEach(function (b) {
          if (a.id === b.id) {
            b.checked = true
          }
        })
      })
      return arr
    },
    removeQuestion (question) {
      this.items = this.items.filter(item => item.id !== question.id)
    },
    getAuthor: async (value, self) => {
      if (!value) {
        self.author = []
      }

      self.isLoading = true
      await self.$axios
        .get(`/organizations/${self.id}/questions/authors?search=${value}`)
        .then(response => {
          self.author = response.data.items
        })
        .finally(() => (self.isLoading = false))
    },
    getOwner: async (value, self) => {
      if (!value) {
        self.owner = []
      }
      self.isLoadingOwner = true
      self.$axios
        .get(`/organizations/${self.id}/questions/owners?search=${value}`)
        .then(response => {
          self.owner = response.data.data
        })
        .finally(() => (self.isLoadingOwner = false))
    },
    setTypeSearch () {
      this.disabled = true
      const val = this.search.typeSearch
      this.setOrganizationId()
      switch (val) {
        case 0:
          this.search.typeQuestion = 'MY_QUESTIONS'
          break
        case 1:
          this.search.typeQuestion = 'ORG_QUESTIONS'
          break
        case 2:
          this.search.typeQuestion = 'DB_QUESTIONS'
          break
        default:
          this.search.typeQuestion = 'MY_QUESTIONS'
          break
      }
      this.clearFilters()
    },
    clearFilters () {
      this.searchQuery = ''
      this.search = {
        isTyping: false,
        isTypingMatter: false,
        tags: [],
        matters: [],
        author: '',
        owner: '',
        difficulty: '',
        wording_type: '',
        typeSearch: this.search.typeSearch,
        typeQuestion: this.search.typeQuestion,
        curricularComponent: '',
        teachingStage: '',
        knowledgeArea: ''
      }
      this.knowledgeAreaDisabled = true
      this.curricularDisabled = true
      this.getAuthor('', this)
      this.getQuestions()
    },
    formatArray (value) {
      if (!value) return
      return value.join(', ')
    },
    openModal (index) {
      this.scrollY = false
      const item = this.items[index]
      this.dialog = {
        ...item,
        show: true
      }
    },
    async onMathJaxReady () {
      setTimeout(async () => {
        await this.$nextTick()
        const elements = document.getElementsByClassName('math-tex')
        this.renderByMathjax(elements)
      }, 250)
    },
    formatFullName (text) {
      const lowerCaseString = text.toLowerCase()
      const stringSplitByWordsArr = lowerCaseString.split(' ')
      const stringWithFirstCapital = stringSplitByWordsArr.map(el => {
        return el.charAt(0).toUpperCase() + el.slice(1)
      })
      return stringWithFirstCapital.join(' ')
    },
    formatQuestions,
    createLink (changePagination) {
      const s = this.search
      let params = ''
      params += this.searchQuery
        ? `&search=${encodeURIComponent(this.searchQuery)}`
        : ''
      params += s.author ? `&author_id=${encodeURIComponent(s.author)}` : ''
      params += s.teachingStage ? `&teaching_stage=${encodeURIComponent(s.teachingStage.id)}` : ''
      params += s.curricularComponent ? `&curricular_component=${encodeURIComponent(s.curricularComponent.id)}` : ''
      params += s.knowledgeArea ? `&knowledge_area=${encodeURIComponent(s.knowledgeArea.id)}` : ''
      params += s.difficulty
        ? `&difficulty=${encodeURIComponent(s.difficulty)}`
        : ''
      params += `&type=${encodeURIComponent(s.typeQuestion)}`
      params += s.typesQuestion ? `&wording_type=${encodeURIComponent(s.typesQuestion)}` : ''
      params +=
        s.tags && s.tags.length ? '&tags[]=' + s.tags.join('&tags[]=') : ''
      params +=
        s.matters && s.matters.length ? '&matters[]=' + s.matters.join('&matters[]=') : ''
      const pagination = `&page=${changePagination ? this.pagination.current_page : 1
        }`

      return `/organizations/${this.id}/questions?with_wordings=1${params}${pagination}`
    },
    getQuestions (changePagination) {
      this.items = []
      this.pagination.total = 0
      this.fetchQuestions = false
      this.loadingQuestions = true
      this.$axios
        .get(this.createLink(changePagination))
        .then(response => {
          const formatted = this.formatQuestions(response.data.items)
          this.items = this.checkSelected(formatted)
          this.pagination = response.data.pagination
          this.checkAll.checked = false
          this.checkExamQuestions()
        }).catch(error => {
          if (error.response) {
            Object.values(error.response.data.errors).map((value) => {
              return this.$store.dispatch('alert', {
                color: 'red',
                msg: value[Object.keys(value)]
              })
            })
          }
        }).finally(() => {
          this.fetchQuestions = true
          this.loadingQuestions = false
          this.skeleton = false
          this.disabled = false
          scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        })
    },
    setFavorite (index) {
      this.items[index].favorite = !this.items[index].favorite
    },
    setOrganizationId () {
      this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    },
    goTo (id) {
      this.$router.push({ path: '/question/edit/' + id })
    },
    setIndeterminate (event, item) {
      if (!event) {
        this.checkAll.checked = false
        this.REMOVE_SELECTED(item)
      } else {
        this.SET_SELECTED(item)
      }
    },
    loadMore (actualPage, lastPage, competence) {
      actualPage += 1

      if (actualPage > lastPage) {
        return
      }

      const competences = {
        teaching_stage: () => {
          this.pageTeachingStage = actualPage
          this.getTeachingStage()
        },
        knowledge_area: () => {
          this.pageKnowledgeArea = actualPage
          this.getKnowledgeArea()
        },
        curricular_component: () => {
          this.pageCurricularComponent = actualPage
          this.getCurricularComponent()
        }
      }

      if (competences[competence]) {
        competences[competence]()
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedQuestions: 'exams/selectedQuestions'
    }),
    ...mapState(['user']),
    userId () {
      return this.user.me.metadata.id
    }
  },
  mounted () {
    this.disabled = true
    this.setOrganizationId()
    this.getTeachingStage()
    this.getQuestions()
    this.getAuthor('', this)
    this.getOwner('', this)
  }
}
</script>
<style scoped>
.v-bnt-advanced-search {
  height: 30px !important;
  font-size: 12px !important;
}
.checkbox-width {
  width: 40px !important;
}
</style>
