<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" :color="color">{{ icon }}</v-icon>
      </template>
      <span>{{ message }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ResolutionTooltip',
  props: ['finalGrade', 'grade', 'status'],
  data: () => ({
    message: '',
    icon: '',
    color: ''
  }),
  mounted () {
    switch (true) {
      case parseFloat(this.finalGrade) === parseFloat(this.grade):
        this.message = 'Aluno acertou a questão'
        this.icon = 'mdi-comment-check-outline'
        this.color = 'green'
        break
      case this.finalGrade > 0 && this.finalGrade < this.grade:
        this.message = 'Aluno pontuou na questão'
        this.icon = 'mdi-comment-bookmark-outline'
        this.color = 'blue'
        break
      case this.status === 'NEED_BE_CORRECTED':
        this.message = 'Questão precisa de correção'
        this.icon = 'mdi-comment-edit-outline'
        this.color = 'orange'
        break
      case this.status === 'CANCELED':
        this.message = 'Questão cancelada'
        this.icon = 'mdi-close'
        this.color = 'red'
        break
      case this.status === 'NOT_ANSWERED':
        this.message = 'Aluno não respondeu a questão'
        this.icon = 'mdi-comment-off-outline'
        break
      case this.finalGrade === 0:
        this.message = 'Aluno errou a questão'
        this.icon = 'mdi-comment-remove-outline'
        this.color = 'red'
        break
      case this.finalGrade > 0 && this.status === 'CORRECTED':
        this.message = 'Aluno pontuou na questão'
        this.icon = 'mdi-comment-bookmark-outline'
        this.color = 'blue'
        break
      default:
        this.message = 'Aluno não respondeu a questão'
        this.icon = 'mdi-comment-off-outline'
        break
    }
  }
}
</script>
