<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" class="d-flex">
        <v-text-field
          v-model="organizationAddress.zip"
          label="CEP *"
          v-mask="'#####-###'"
          :rules="[rules.required, rules.zipCode]"
          required
          validate-on-blur
          outlined
          :disabled="sending"
          dense
          background-color="transparent"
          v-on:keyup="findAddress"
        ></v-text-field>
        <v-progress-circular indeterminate color="primary" class="ml-4" v-if="loadingZip"></v-progress-circular>
      </v-col>
      <v-col cols="12" md="8" class="d-flex">
        <div v-if="errorZip" class="tw-text-red-300">Erro ao buscar CEP</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" class="py-0">
        <v-text-field
          v-model="organizationAddress.street"
          id="inputStreetName"
          label="Logradouro *"
          required
          :rules="[rules.required]"
          validate-on-blur
          outlined
          :disabled="sending || loadingZip"
          dense
          background-color="transparent"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field
          v-model="organizationAddress.number"
          id="inputNumber"
          label="Número *"
          required
          :rules="[rules.required]"
          validate-on-blur
          outlined
          :disabled="sending || loadingZip"
          dense
          background-color="transparent"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="organizationAddress.additional"
          label="Complemento - opcional"
          outlined
          :disabled="sending || loadingZip"
          dense
          background-color="transparent"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          v-model="organizationAddress.neighborhood"
          label="Bairro"
          validate-on-blur
          outlined
          :disabled="sending || loadingZip"
          dense
          background-color="transparent"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" class="py-0">
        <v-text-field
          v-model="organizationAddress.city"
          label="Cidade *"
          required
          :rules="[rules.required]"
          validate-on-blur
          outlined
          :disabled="sending || loadingZip"
          dense
          background-color="transparent"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-select
          v-model="organizationAddress.state"
          :items="states"
          label="Estado *"
          required
          :rules="[rules.required]"
          validate-on-blur
          outlined
          :disabled="sending || loadingZip"
          dense
          background-color="transparent"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import { TheMask } from 'vue-the-mask'
// import $ from 'jquery'

export default {
  // components { TheMask },
  props: {
    sending: {
      type: Boolean,
      default: false
    },
    address: {
      type: Object,
      default () {
        return {
          state: '',
          city: '',
          street: '',
          zip: '',
          number: '',
          country: '',
          neighborhood: '',
          additional: ''
        }
      }
    }
  },
  computed: {
    organizationAddress: {
      get () {
        return this.address
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  data: () => ({
    errorZip: false,
    loadingZip: false,
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      phone: value => value.length >= 14 || 'Favor inserir um telefone válido.',
      // eslint-disable-next-line no-useless-escape
      zipCode: value => /^\d{5}\-\d{3}$/.test(value) || 'Favor inserir um CEP válido.'
    },
    states: [
      'AC',
      'AL',
      'AM',
      'AP',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RJ',
      'RN',
      'RO',
      'RS',
      'RR',
      'SC',
      'SE',
      'SP',
      'TO'
    ]
  }),
  methods: {
    async findAddress () {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.organizationAddress.zip)) {
        this.loadingZip = true
        try {
          const meResponse = await fetch(`https://viacep.com.br/ws/${this.organizationAddress.zip}/json/`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
          })
          const address = await meResponse.json()

          if (!meResponse.ok) {
            throw address
          }
          if (!address) {
            throw address
          }
          this.organizationAddress.street = address.logradouro
          this.organizationAddress.additional = address.complemento
          this.organizationAddress.neighborhood = address.bairro
          this.organizationAddress.city = address.localidade
          this.organizationAddress.state = address.uf.toUpperCase()
          this.errorZip = false
        } catch (error) {
          this.errorZip = true
        } finally {
          this.loadingZip = false
        }
      }
    }
    /* async formatState (stateRaw) {
      switch (stateRaw) {
        case 'Acre':
          return 'AC'
        case 'Alagoas':
          return 'AL'
        case 'Amazonas':
          return 'AM'
        case 'Amapá':
          return 'AP'
        case 'Bahia':
          return 'BA'
        case 'Ceará':
          return 'CE'
        case 'Distrito Federal':
          return 'DF'
        case 'Espírito Santo':
          return 'ES'
        case 'Goiás':
          return 'GO'
        case 'Maranhão':
          return 'MA'
        case 'Mato Grosso':
          return 'MT'
        case 'Mato Grosso do Sul':
          return 'MS'
        case 'Minas Gerais':
          return 'MG'
        case 'Pará':
          return 'PA'
        case 'Paraíba':
          return 'PB'
        case 'Paraná':
          return 'PR'
        case 'Pernambuco':
          return 'PE'
        case 'Piauí':
          return 'PI'
        case 'Rio de Janeiro':
          return 'RJ'
        case 'Rio Grande do Norte':
          return 'RN'
        case 'Rondônia':
          return 'RO'
        case 'Rio Grande do Sul':
          return 'RS'
        case 'Roraima':
          return 'RR'
        case 'Santa Catarina':
          return 'SC'
        case 'Sergipe':
          return 'SE'
        case 'São Paulo':
          return 'SP'
        case 'Tocantins':
          return 'TO'
      }
    } */
  }
}
</script>
