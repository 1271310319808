<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form ref="formOrg">
          <v-row class="m-0">
            <v-col cols="12" md="6" class="py-0">
              <!-- <v-text-field
                v-if="organizationData && organizationData.company && organizationData.company.document  === 'cpf'"
                v-model="organizationData.company.document.id"
                label="CPF *"
                v-mask="'###.###.###-##'"
                :rules="[rules.registeredNumber, rules.required]"
                :disabled="sending"
                required
                outlined
                dense
                background-color="transparent"
              ></v-text-field>-->
              <v-text-field
                v-model="organizationData.company.document.id"
                v-if="organizationData.company.document"
                label="CNPJ *"
                v-mask="'##.###.###/####-##'"
                return-masked-value
                :rules="[rules.registeredNumber, rules.required]"
                validate-on-blur
                required
                outlined
                :disabled="sending"
                dense
                background-color="transparent"
              ></v-text-field>
              <!-- {{ organizationData.company.document.id }} -->
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                v-model="organizationData.company.segment"
                :items="industry"
                label="Segmento *"
                value="value"
                item-text="name"
                required
                :rules="[rules.requiredSelect]"
                validate-on-blur
                :disabled="sending"
                outlined
                dense
                background-color="transparent"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="12" class="py-0">
              <!-- v-if="organizationData && organizationData.company.document.id" -->
              <v-text-field
                v-model="organizationData.company.name.legal"
                label="Nome Formal da Instituição *"
                required
                :disabled="sending"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                v-model="organizationData.company.name.trade"
                label="Nome da Instituição *"
                required
                :disabled="sending"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="organizationData.billing.email"
                label="E-mail *"
                :rules="[rules.email, rules.required]"
                validate-on-blur
                :disabled="sending"
                required
                outlined
                dense
                background-color="transparent"
                hint="Para envio da Nota Fiscal."
                persistent-hint
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="organizationData.phone"
                v-mask="maskTel()"
                label="Telefone *"
                :rules="[rules.phone, rules.required]"
                required
                outlined
                dense
                background-color="transparent"
              ></v-text-field>
            </v-col>-->
          </v-row>
          <form-address
            :address="organizationData.address"
            :sending="sending"
            class="mt-4"
            @input="organizationData.address = $event"
          />
          <v-row class="mt-3">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="organizationData.licenses"
                label="Licenças disponíveis *"
                :rules="[rules.required]"
                validate-on-blur
                :disabled="sending"
                required
                v-mask="'########'"
                outlined
                dense
                background-color="transparent"
                hint="Total de licenças disponíveis para a organização"
                persistent-hint>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="organizationData.licenses_tolerance"
                label="Porcentagem de Tolerância *"
                :rules="[rules.required, rules.minMax]"
                validate-on-blur
                v-mask="'###'"
                :disabled="sending"
                required
                outlined
                dense
                background-color="transparent"
                hint="Porcentagem de tolerância permitida para caso o limite de licenças exceda"
                persistent-hint
                suffix="%">
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions v-if="action !== 'new'">
          <v-spacer />
          <button-update :sending="sending" v-on:update="update"></button-update>
        </v-card-actions>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      default: 'edit'
    },
    organization: {
      type: Object,
      default () {
        return {
          data: {
            domains: [],
            address: {
              state: '',
              city: '',
              street: '',
              zip: '',
              number: '',
              country: '',
              neighborhood: '',
              additional: ''
            },
            billing: {
              email: ''
            },
            company: {
              name: {
                trade: '',
                legal: ''
              },
              document: {
                type: 'CNPJ',
                id: ''
              },
              segment: ''
            },
            licenses: 0,
            licenses_tolerance: 0
          }
        }
      }
    }
  },
  computed: {
    organizationData: {
      get () {
        return this.organization.data
      },
      set (val) {
        this.$emit('update', val)
        return val
      }
    }
  },
  data: () => ({
    sending: false,
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      requiredSelect: v => (v && !!v.length >= 1) || 'Selecione um segmento.',
      registeredNumber: v =>
        /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(v) ||
        'Favor inserir um documento válido.',
      email: (v) => {
        if (!/.+@.+/.test(v)) return 'Favor inserir um e-mail válido.'
        if (v.indexOf(' ') >= 0) return 'Não são permitidos espaços.'
      },
      phone: v => v.length >= 14 || 'Favor inserir um telefone válido.',
      minMax: v => (v >= 0 && v <= 100) || 'Inserir um valor entre 0 e 100.'
    },
    industry: [
      {
        value: 'STATE_GOVERNMENT',
        name: 'Governo Estadual'
      },
      {
        value: 'LOCAL_GOVERNMENT',
        name: 'Governo Municipal'
      },
      {
        value: 'FEDERAL_GOVERNMENT',
        name: 'Governo Federal'
      },
      {
        value: 'DATABASE_PROVIDER',
        name: 'Provedor de Questões'
      }
    ]
  }),
  methods: {
    update () {
      if (!this.$refs.formOrg.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.sending = true
      const self = this
      const payload = JSON.parse(JSON.stringify(this.organizationData))
      delete payload.company.logo
      delete payload.gcred_oauth_user
      delete payload.gcred_service_account
      payload.company.document.id = payload.company.document.id.replace(/\D/g, '')
      payload.company.document.type = 'CNPJ'
      this.$axios
        .patch(`/organizations/${this.organization.metadata.id}`, payload)
        .then(response => {
          self.$store.dispatch('alert', {
            msg: 'Dados atualizados com sucesso'
          })
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          this.sending = false
        })
    }
  }
}
</script>
