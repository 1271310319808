<template>
    <v-dialog
          persistent
          v-model="dialog"
          max-width="340"
        >
        <v-card>
            <v-card-title class="text-h5 mb-5">
              <v-icon color="yellow darken-3">mdi-alert</v-icon>&nbsp;&nbsp;Atenção
            </v-card-title>

            <v-card-subtitle>
              <h2>{{ headerMessage }}</h2>
            </v-card-subtitle>

            <v-card-text>
              {{ bodyMessage }}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                color="primary darken-1"
                text
                :disabled="sending"
                @click="$emit('closeDialog')"
                >
                {{ denyMessage }}
                </v-btn>

                <v-btn
                color="red darken-1"
                text
                :disabled="sending"
                @click="update()"
                >
                {{ confirmMessage }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirmActionInCourse',
  props: ['dialog', 'headerMessage', 'bodyMessage', 'confirmMessage', 'denyMessage', 'updateEndpoint', 'action'],
  data () {
    return {
      sending: false
    }
  },
  methods: {
    displayError () {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Ocorreu um erro durante a solicitação, tente novamente.'
      })
    },
    update () {
      this.sending = true
      this.$axios.patch(this.updateEndpoint, { status: this.action })
        .then(() => this.$emit('updateSuccess'))
        .catch(() => this.displayError())
        .finally(() => {
          this.sending = false
          this.$emit('closeDialog')
        })
    }
  }
}
</script>
