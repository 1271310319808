<template>
    <div>
        <v-stepper-step :complete="complete" :step="step">
            {{ title }}
          <small class="mt-1">{{ summary }}</small>
        </v-stepper-step>
        <v-stepper-content :step="step">
             <slot name="content" />
             <v-btn v-for="button, index in buttons" :key="index" class="mr-2"
                :color="button.color ? button.color : 'primary'"
                :outlined="button.outlined"
                @click="$emit(button.emit)"
             >
                {{ button.text ? button.text : 'Please add name' }}
            </v-btn>
          </v-stepper-content>
    </div>
</template>

<script>
export default {
  name: 'StepComponent',
  props: ['title', 'step', 'buttons', 'complete', 'summary']
}
</script>
