<template>
  <v-row>
    <v-col
      cols="12"
      lg="3"
      sm="6"
      v-for="usercard in usercards"
      :usercard="usercard"
      :key="usercard.usercardtitle"
      class="py-0 mb-7"
    >
      <v-card>
        <v-card-text class="pa-5">
          <div class="d-flex align-center">
            <v-btn
              :class="[usercard.iconcolor]"
              class="elevation-0"
              fab
              dark
            >
              <v-icon>{{ usercard.icon }}</v-icon>
            </v-btn>
            <div class="ml-2 mr-1">
              <h2 class="headline font-weight-medium">
                {{ usercard.number }}
              </h2>
              <h5 class="subtitle-2 font-weight-regular">
                {{ usercard.title }}
              </h5>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    counters: {
      type: Object,
      default: () => {
        return {
          courses_count: 0,
          rostered_students_count: 0,
          rostered_teachers_count: 0,
          schools_count: 0,
          users_count: 0
        }
      }
    }
  },
  data: () => ({
    usercards: []
  }),
  mounted () {
    this.usercards = [
      {
        title: 'Escolas',
        iconcolor: 'blue-grey darken-2',
        icon: 'mdi-home-outline',
        number: this.counters.schools_count
      },
      {
        title: 'Turmas',
        iconcolor: 'blue-grey darken-2',
        icon: 'mdi-school-outline',
        number: this.counters.courses_count

      },
      {
        title: 'Professores',
        iconcolor: 'blue-grey darken-2',
        icon: 'mdi-account-multiple',
        number: this.counters.rostered_teachers_count

      },
      {
        title: 'Alunos',
        iconcolor: 'blue-grey darken-2',
        icon: 'mdi-account-group-outline',
        number: this.counters.rostered_students_count
      }
    ]
  }
}
</script>
