<template>
  <v-container class="pa-4">
    <!-- <b>
      When \(a \ne 0\), there are two solutions to \(ax^2 + bx + c = 0\) and they are \[x = {-b \pm \sqrt{b^2-4ac} \over 2a}.\]
      $$b^2-4ac$$
    </b>-->

    <div class="mb-6" v-html="item.enunciated"></div>
    <v-radio-group v-if="item.answers.type == 'objective'" v-model="item.answer">
      <template v-for="(answer, index) in item.answers.fields">
        <v-radio :key="`${index}-answer`" :value="answer.value">
          <template  slot="label">
            <span v-html="answer.label" class="body-2 radio--text"></span>
          </template>
        </v-radio>
      </template>
    </v-radio-group>
    <v-textarea
      v-else
      v-model="item.answer"
      label="Resposta"
      counter
      outlined
      dense
      v-on:keyup="saveAnswer"
      :hint="lastUpdate"
      persistent-hint
      :maxlength="item.answers.maxlength"
      full-width
      single-line
    ></v-textarea>
  </v-container>
</template>
<script>
export default {

  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    lastUpdate: null
  }),
  methods: {
    saveAnswer () {
      this.lastUpdate = `Salvo em 
      `
      // ${this.$moment().format('LLL')}
    }
  }
}
</script>
<style scoped>
.radio--text {
  color: rgba(0, 0, 0, 0.87);
}
</style>
