<template>
  <v-row class="d-flex justify-space-between py-4">
    <v-col cols="12" md="2" class="pt-0" v-for="(item, index) in data" :key="index">
      <CardReportInfoVue
        :color="item.color"
        :title="item.title"
        :text="item.text"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardReportInfoVue from '../cards/CardReportInfo.vue'

export default {
  props: ['data'],
  components: { CardReportInfoVue }
}
</script>
