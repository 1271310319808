const difficulties = [
  {
    value: 'VERY_EASY',
    name: 'Trivial'
  },
  {
    value: 'EASY',
    name: 'Fácil'
  },
  {
    value: 'MEDIUM',
    name: 'Média'
  },
  {
    value: 'HARD',
    name: 'Difícil'
  },
  {
    value: 'VERY_HARD',
    name: 'Complexa'
  }
]

export {
  difficulties
}
