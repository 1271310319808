/* eslint-disable camelcase */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueGtag from 'vue-gtag'
import api from '../libs/axios'
import collect from 'collect.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('../views/pages/authentication/Login.vue')
  },
  {
    path: '/register',
    component: () => import('../views/pages/authentication/Register.vue')
  },
  {
    path: '/password/forgot',
    component: () => import('../views/pages/authentication/password/Forgot.vue')
  },
  {
    path: '/password/reset',
    component: () => import('../views/pages/authentication/password/Reset.vue')
  },
  {
    path: '/password/change/:organizationId/:userId',
    component: () => import('../views/pages/authentication/password/Change.vue')
  },
  {
    path: '/permission',
    component: () => import('../views/pages/authentication/Permission.vue')
  },
  {
    path: '/user/certificate',
    component: () => import('../views/pages/certificate/User.vue')
  },
  {
    path: '/user/verify',
    component: () => import('../views/pages/authentication/Verify.vue'),
    beforeEnter (to, from, next) {
      const token = localStorage.getItem('jwtToken')
      if (!token) {
        return next('/login')
      }
      next()
    }
  },
  {
    path: '/',
    component: () => import('../views/layouts/MainLayout.vue'),
    async beforeEnter (to, from, next) {
      const token = localStorage.getItem('jwtToken')
      api.post('/auth/me').then(async res => {
        const { success } = res.data
        const { is_needed_to_change_password, acl, is_super_admin } = res.data.data
        const { id } = res.data.metadata
        switch (true) {
          case (success): {
            const meData = res.data
            if (is_needed_to_change_password && acl.length >= 1) {
              return next(`/password/change/${acl[0].organization_id}/${id}`)
            }
            await store.dispatch('setDataAuth', { token, meData })
            await store.dispatch('organization/getOrganizations', { token })
            if (!acl || acl.length === 0) {
              await store.dispatch('alert', { color: 'red', msg: 'Verifique se seu usuário está presente em uma instituição com as funções permitidas.' })
              return await next('/permission')
            }
            if (is_super_admin || acl.filter((value) => !value.roles.includes('STUDENT')).length > 0) {
              if (!localStorage.getItem('ACTIVE_ORGANIZATION')) {
                localStorage.setItem('ACTIVE_ORGANIZATION', acl[0].organization_id)
                store.commit('organization/SET_ACTIVE_ORGANIZATION', acl[0].organization_id)
              }
            } else {
              await store.dispatch('alert', { color: 'red', msg: 'Verifique se seu usuário está presente em uma instituição com as funções permitidas.' })
              return await next('/permission')
            }
            await next()
            break
          }
          case (acl.length <= 0): {
            const msg = 'Verifique suas permissões e/ou se seu usuário está em uma instituição'
            await store.dispatch('alert', { color: 'red', msg })
            await next('/login')
            break
          }
          default: {
            await store.dispatch('alert', { color: 'red', msg: 'Erro inesperado.' })
            break
          }
        }
        store.commit('setLoading', false)
      }).catch(async err => {
        switch (true) {
          case (err.request && err.request.status === 401): {
            const needAuthentication = 'Área restrita: realize login para acessar.'
            await store.dispatch('alert', { color: 'red', msg: needAuthentication })
            break
          }
          case (err.request && err.request.status === 403): {
            const blockedUser = 'Usuário sem permissão ou está bloqueado/inativo, contate seu administrador.'
            await store.dispatch('alert', { color: 'red', msg: blockedUser })
            break
          }
          case !token: {
            await store.dispatch('setDataAuth', {})
            await store.dispatch('organization/clearOrganization')
            break
          }
          default: {
            await store.dispatch('alert', { color: 'red', msg: 'Erro inesperado.' })
            break
          }
        }
        await next('/login')
        return store.commit('setLoading', false)
      })
    },
    children: [
      {
        name: 'Home',
        path: '/',
        redirect: '/organization',
        component: () => import('../views/dashboard/DashboardOrganization.vue')
      },
      {
        name: 'Organization Dashboard',
        path: 'organization',
        component: () => import('../views/dashboard/DashboardOrganization.vue'),
        beforeEnter: (to, from, next) => {
          const meData = store.state.user.me
          const roles = []
          collect(meData.data.acl).each(acl => {
            collect(acl.roles).each(role => {
              roles.push(role)
            })
          })

          if (!roles.length) {
            return next('/permission')
          }

          switch (true) {
            case meData.data.is_super_admin:
            case collect(roles).contains('ADMIN'):
            case collect(roles).contains('ORG_PEDAGOGICAL_COORDINATOR'):
              return next()

            case collect(roles).contains('PRINCIPAL'):
            case collect(roles).contains('TEACHER'):
            case collect(roles).contains('SCHOOL_PEDAGOGICAL_COORDINATOR'):
              return next('/school')
          }
        }
      },
      {
        name: 'Organization Admins',
        path: 'organization/admins',
        component: () => import('../views/admin/AdminList.vue')
      },
      {
        name: 'Create Organization',
        path: 'organization/new',
        component: () => import('../views/admin/CreateOrganization.vue')
      },
      {
        name: 'Organization Edit',
        path: 'organization/edit',
        component: () => import('../views/organization/OrganizationEdit.vue')
      },
      {
        name: 'Organization Manage People',
        path: 'organization/people/:roles',
        component: () => import('../views/organization/OrganizationPeople.vue')
      },
      {
        name: 'ExamDataBase Add',
        path: 'examdatabase/add',
        component: () => import('../views/examDatabase/ExamAdd.vue')
      },
      {
        name: 'ExamDataBase Edit',
        path: 'examdatabase/edit/:id?',
        component: () => import('../views/examDatabase/ExamEdit.vue')
      },
      {
        name: 'ExamDataBase List',
        path: 'examdatabase/list',
        component: () => import('../views/examDatabase/ExamList.vue')
      },
      {
        name: 'Exam Add',
        path: 'exam/add',
        component: () => import('../views/exam/ExamAdd.vue')
      },
      {
        name: 'Exam List',
        path: 'exam/list',
        component: () => import('../views/exam/ExamList.vue')
      },
      {
        name: 'Exam View',
        path: 'exam/view/:id',
        component: () => import('../views/exam/DashboardExam.vue')
      },
      {
        name: 'Exam Edit',
        path: 'exam/edit/:id',
        component: () => import('../views/exam/ExamEdit.vue')
      },
      {
        name: 'Exam Import',
        path: 'exam/import',
        component: () => import('../views/exam/ExamImport.vue')
      },
      {
        name: 'Exam New Question',
        path: 'exam/application/question/add/:id',
        component: () => import('../views/exam/ExamQuestionAdd.vue')
      },
      {
        name: 'Exam Edit Question',
        path: 'exam/application/question/edit/:id/:question',
        component: () => import('../views/exam/ExamQuestionEdit.vue')
      },
      {
        name: 'Exam New Application',
        path: 'exam/application/:id',
        component: () => import('../views/exam/ExamApplication.vue')
      },
      {
        name: 'Exam Application View',
        path: 'exam/application/view/:id/:application',
        component: () => import('../views/exam/DashboardApplication.vue')
      },
      {
        name: 'Exam Application Students',
        path: 'exam/application/students/:id/:application',
        component: () => import('../views/exam/StudentsList.vue')
      },
      {
        name: 'Exam Application Edit',
        path: 'exam/application/edit/:id/:application',
        component: () => import('../views/exam/ExamApplicationEdit.vue')
      },
      {
        name: 'Exam Database View',
        path: 'exam-database/:id',
        component: () => import('../views/examDatabase/ExamDatabaseView.vue')
      },
      {
        name: 'Question Add',
        path: 'question/add',
        component: () => import('../views/question/QuestionAdd.vue')
      },
      {
        name: 'Wordings Add',
        path: 'question/add/wordings/:id',
        component: () => import('../views/question/WordingsAdd.vue')
      },
      {
        name: 'Question List',
        path: 'question/list',
        component: () => import('../views/question/QuestionList.vue')
      },
      ...['question/edit/:id', 'question/edit/:id/copy'].map((path) => ({
        path: path,
        component: () => import('../views/question/QuestionEdit.vue')
      })
      ),
      {
        name: 'School',
        path: 'school',
        component: () => import('../views/dashboard/SchoolList.vue')
      },
      {
        name: 'School Add',
        path: 'school/add',
        component: () => import('../views/school/SchoolAdd.vue')
      },
      {
        name: 'School Edit',
        path: 'school/edit/:id?',
        component: () => import('../views/school/SchoolEdit.vue')
      },
      {
        name: 'School List',
        path: 'school/list/:id/:roles?',
        component: () => import('../views/school/SchoolListPeople.vue')
      },
      {
        name: 'School Dashboard',
        path: 'school/:id',
        component: () => import('../views/dashboard/DashboardSchool.vue')
      },
      {
        name: 'School Course',
        path: 'school/course/:id?',
        component: () => import('../views/school/SchoolCourseList.vue')
      },
      {
        name: 'School Add People',
        path: 'school/people/:id/:roles?',
        component: () => import('../views/school/SchoolPeople.vue')
      },
      {
        name: 'All Course List',
        path: 'mycourse',
        component: () => import('../views/course/MyCourses.vue')
      },
      {
        name: 'Course List',
        path: 'course/list/:id/:course/:roles?',
        component: () => import('../views/course/CourseList.vue')
      },
      {
        name: 'Course Add',
        path: 'course/add/:id?',
        component: () => import('../views/course/CourseAdd.vue')
      },
      {
        name: 'School Course Import',
        path: 'course/import/:id?',
        component: () => import('../views/course/ImportCourse.vue')
      },
      {
        name: 'Course Edit',
        path: 'course/edit/:id?/:course?',
        component: () => import('../views/course/CourseEdit.vue')
      },
      {
        name: 'Course Dashboard',
        path: 'course/:id/:course',
        component: () => import('../views/course/DashboardCourse.vue')
      },
      {
        name: 'Course Add People',
        path: 'course/people/:id/:course/:roles?',
        component: () => import('../views/course/CoursePeople.vue')
      },
      {
        name: 'Student List',
        path: 'student/list',
        component: () => import('../views/student/StudentList.vue')
      },
      {
        name: 'Teacher List',
        path: 'teacher/list',
        component: () => import('../views/teacher/TeacherList.vue')
      },
      {
        name: 'Teacher Add',
        path: 'adm/add',
        component: () => import('../components/forms/DragPeople.vue')
      },
      {
        name: 'User Profile',
        path: 'user/profile/:id?',
        component: () => import('../views/profile/ProfileUser.vue')
      },
      {
        name: 'My Profile',
        path: 'user/my-profile',
        component: () => import('../views/profile/MyProfile.vue')
      },
      {
        name: 'Reference Matrix List',
        path: 'referencematrix',
        component: () => import('../views/ReferenceMatrix/ReferenceMatrixList.vue')
      },
      {
        name: 'Application List',
        path: 'applications/:id?',
        component: () => import('../views/application/ApplicationList.vue')
      },
      {
        name: 'New Resolution',
        path: 'resolution',
        redirect: 'resolution/list'
      },
      {
        name: 'Application Resolution Edit',
        path: 'exam/:id/application/:application/resolution/:student',
        component: () => import('../views/application/resolution/ResolutionEdit.vue')
      },
      {
        name: 'School Reports',
        path: 'reports/schools',
        component: () => import('../views/reports/SchoolReports.vue')
      },
      {
        name: 'School Reports View',
        path: 'reports/schools/view/:school',
        component: () => import('../views/reports/SchoolReportsView.vue')
      },
      {
        name: 'Application Reports View',
        path: 'reports/applications/view/:exam/:application',
        component: () => import('../views/reports/ApplicationReportsView.vue')
      },
      {
        name: 'Courses Management',
        path: 'courses-management',
        component: () => import('../views/admin/CoursesManagement.vue')
      },
      {
        name: 'Logs',
        path: 'logs/:user_id',
        component: () => import('../views/logs/Logs.vue')
      },
      {
        name: 'List User View',
        path: '/users',
        component: () => import('@/views/user/ListUser.vue')
      },
      {
        name: 'Create User View',
        path: '/users/create',
        component: () => import('@/views/user/CreateUser.vue')
      },
      {
        name: 'Edit User View',
        path: '/users/:userId/edit',
        component: () => import('@/views/user/EditUser.vue')
      },
      {
        name: 'Material Support',
        path: '/support-material',
        component: () => import('@/views/support-material/SupportMaterialView.vue')
      }
    ]
  },
  {
    name: 'Application Answer Key',
    path: '/answerkey/:id/:application',
    component: () => import('../views/resolution/ResolutionAnswerKey.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Sentry.init({
  Vue,
  dsn: 'https://2f4f9b62288c4cdd9aa57d15b5575cc8@o1138815.ingest.sentry.io/6202055',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'professor.pruvo.app', /^\//]
    })
  ],
  tracesSampleRate: 0
})

Vue.use(VueGtag, {
  config: { id: 'G-9670CK7S0C' }
}, router)

router.beforeEach((to, from, next) => {
  store.dispatch('setFirstLoading', true)
  next()
})

router.afterEach((to, from, next) => {
  setTimeout(() => store.dispatch('setFirstLoading', false), 500)

  const meData = store.state.user.me

  if (!localStorage.getItem('ACTIVE_ORGANIZATION') && meData.data) {
    localStorage.setItem('ACTIVE_ORGANIZATION', meData.data.acl[0].organization_id)
    store.commit('organization/SET_ACTIVE_ORGANIZATION', meData.data.acl[0].organization_id)
    location.reload()
  }
})

export default router
