<template>
  <div>
    <v-simple-table class="answer" height="80vh">
      <template v-if="loading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="table-heading"
          v-bind="{
        class: 'mb-1 py-3',
        boilerplate: true,
        elevation: 1
        }"
        ></v-skeleton-loader>
      </template>
      <template v-else>
        <thead>
        <tr>
          <th class="text-left">Respostas</th>
        </tr>
        </thead>
        <tbody :key="key">
        <v-list-item-title v-if="!questions" class="px-4 my-10 text-center ">
          <v-progress-circular
            class="mr-3"
            color="primary"
            indeterminate
          ></v-progress-circular>
          Aguarde ...
        </v-list-item-title>
        <tr v-for="(item, index) in questions" :key="item.metadata.id">
          <td class="answer">
            <div class="ml-4" v-html="item.data.main_text"></div>
            <div class="d-flex flex-wrap align-center">
              <v-radio-group v-model="selectedAnswers[index]" :loading="true" class="mt-0 d-flex" row>
                <ul
                  v-if="item.data.wordings[0].data.type === 'MULTIPLE_CHOICE'"
                  :style="{ listStyleType: getMarkerKebabName(markerList) }"
                  class="pl-0 d-flex list-options"
                >
                  <div
                    v-for="(option, index, indice) in item.data.wordings[0].data.options"
                    :key="indice"
                    :class="getMarkerKebabName(markerList) !== 'none' && !$vuetify.breakpoint.mobile
                          ? 'ml-6'
                          : 'ml-1'
                      "
                  >
                    <v-radio
                      :value="{id: item.data.wordings[0].metadata.id, answers: [index]}"
                      active-class="active"
                      class="radio mt-0 tw-text-center"
                      hidden
                      @click="countOption(item)"
                    >
                      <template v-slot:label>
                        <li class="marker">{{ getMarker(markerApplication, indice) }}</li>
                      </template>
                    </v-radio>

                  </div>
                </ul>
                <ul v-if="item.data.wordings[0].data.type === 'OPEN_ENDED'" class="pl-0 d-flex list-options">
                  <div v-for="grade in grades" :key="grade">
                    <v-radio
                      :value="{id: item.data.wordings[0].metadata.id, notes: '', answers: [''], final_grade: grade ? parseFloat(grade) / 100 : studentData[index].final_grade}"
                      active-class="active" class="radio mt-0" @click="countOption(item)"
                      :class="finalGrade[index] && parseFloat(grade) / 100 === parseFloat(finalGrade[index].final_grade) && !item.selectedOption ? 'active' : ''">
                      <template v-slot:label>
                        <li class="marker">{{ grade }}</li>
                      </template>
                    </v-radio>
                  </div>
                </ul>
              </v-radio-group>
            </div>
          </td>
          <td>
            <v-btn icon @click="viewQuestion(item, index)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-eye
                  </v-icon>
                </template>
                <span>Ver questão</span>
              </v-tooltip>
            </v-btn>
            <v-btn v-if="selectedAnswers" icon @click="removeAnswer(index, item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-eraser
                  </v-icon>
                </template>
                <span>{{textType[item.data.wordings[0].data.type]}}</span>
              </v-tooltip>
            </v-btn>
          </td>
        </tr>
        </tbody>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="12">
              <v-spacer></v-spacer>
              <v-btn class="mt-t3" color="primary" @click="saveAnswers()">Salvar</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-simple-table>
    <dialog-preview-short-question :dialog="dialog"/>

  </div>
</template>

<script>
import DialogPreviewShortQuestion from '../dialog/DialogPreviewShortQuestion'
import { mapMutations } from 'vuex'
import { getMarker, getMarkerKebabName } from '@/utils/marker'
import collect from 'collect.js'

export default {
  components: {
    DialogPreviewShortQuestion
  },
  props: {
    studentIndex: {
      type: Number
    },
    student: {
      type: Object
    },
    markerApplication: {
      type: String
    }
  },
  data () {
    return {
      grades: [
        '0%',
        '10%',
        '20%',
        '30%',
        '40%',
        '50%',
        '60%',
        '70%',
        '80%',
        '90%',
        '100%'
      ],
      selectedOption: false,
      icons: [
        'mdi-emoticon-sad-outline',
        'mdi-emoticon-neutral-outline',
        'mdi-emoticon-happy-outline',
        'mdi-emoticon-cool-outline'
      ],
      rangeSelected: '',
      selectedAnswers: [],
      dialog: {},
      id: '',
      key: 0,
      markerList: '',
      questions: '',
      studentData: {},
      loading: false,
      lastAnswer: null,
      markerTypes: [
        { name: 'Nenhuma', value: 'NONE' },
        { name: 'A, B, C', value: 'UPPER_ALPHA' },
        { name: 'a, b, c', value: 'LOWER_ALPHA' },
        { name: '1, 2, 3', value: 'DECIMAL' },
        { name: 'I, II, III', value: 'UPPER_ROMAN' },
        { name: 'i, ii, iii', value: 'LOWER_ROMAN' }
      ],
      finalGrade: [],
      textType: {
        MULTIPLE_CHOICE: 'Limpar alternativas',
        OPEN_ENDED: 'Limpar pontuação',
        TRUE_FALSE: 'Limpar alternativas'
      }
    }
  },
  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.markerList = this.markerApplication
    await this.getQuestionStudent()
    await this.getQuestions()
  },
  methods: {
    ...mapMutations(['setLoading']),
    getMarkerKebabName,
    getMarker,
    async getQuestions () {
      try {
        const questions = await this.getAllQuestions()
        this.questions = questions
        this.$emit('countQuestion', this.questions.length)
      } catch (e) {
        // TODO: tratar erro
      }
    },
    async getAllQuestions () {
      const url = `organizations/${this.id}/exams/${this.$route.params.id}/questions?with_wordings=1`
      const pagination = {
        page: 1,
        nextPageUrl: null
      }
      const questions = []

      do {
        const response = await this.$axios.get(`${url}&page=${pagination.page}`)
        questions.push(...response.data.items)
        pagination.nextPageUrl = response.data.pagination.next_page_url
        pagination.page++
      } while (pagination.nextPageUrl)
      return questions
    },
    viewQuestion (item, index) {
      this.dialog = {
        ...item,
        index: index + 1,
        show: true
      }
    },
    removeAnswer (index, item) {
      this.selectedAnswers[index] = null
      this.finalGrade[index] = null
      item.selectedOption = false
      this.countAnswers()
      this.key++
    },
    countAnswers () {
      const countAnswers = collect(this.selectedAnswers).pluck('answers')
        .map(answer => {
          return collect(answer).isEmpty() ? null : answer
        })
        .whereNotNull()
        .count()
      this.$emit('count', countAnswers)
    },
    countOption (item) {
      item.selectedOption = true
      this.countAnswers()
    },
    async getQuestionStudent () {
      try {
        this.loading = true
        await this.getAllQuestionAnswers()
      } finally {
        this.loading = false
      }
    },
    async getAllQuestionAnswers () {
      const url = `/organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}/students/${this.student.id}/answers`
      const pagination = {
        page: 1,
        nextPageUrl: null
      }

      do {
        const response = await this.$axios.get(`${url}?page=${pagination.page}`)
        pagination.nextPageUrl = response.data.pagination.next_page_url
        pagination.page++

        this.selectedAnswers.push(...response.data.items.map(el => {
          if (!el.data.answers[0]) {
            return { answers: el.data.answers, id: el.data.wording_id, final_grade: el.data.final_grade }
          }
          return { answers: el.data.answers, id: el.data.wording_id }
        }))

        this.finalGrade.push(...response.data.items.map(el => {
          return { id: el.data.wording_id, final_grade: el.data.final_grade }
        }))
      } while (pagination.nextPageUrl)
    },
    saveAnswers () {
      this.setLoading(true)
      const payload = this.selectedAnswers
      this.$axios
        .post(`/organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}/students/${this.student.id}/answers/bubble-sheet`,
          { wordings: payload })
        .then(() => {
          this.$emit('studentIndex', this.studentIndex)
          this.$store.dispatch('alert', {
            msg: 'Salvo com sucesso'
          })
          location.reload()
        })
        .catch(error => {
          if (error.response) {
            Object.values(error.response.data.errors).map((value) => {
              return this.$store.dispatch('alert', {
                color: 'red',
                msg: value[Object.keys(value)]
              })
            })
          }
        })
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
ul.list-options {
  list-style-position: inside;
}

.marker {
  border-radius: 100%;
  border: 2px solid rgb(146, 146, 146);
  height: 40px;
  list-style: none;
  width: 40px;
  font-size: 14px;
  padding: 8px 0px 0px 0px;
  text-align: center;
}

.marker:hover {
  border: 2px solid rgb(126, 126, 126);
  background: rgba(94, 177, 255, 0.219);
  transition: 200ms;

}

.active .marker {
  border: 2px solid rgb(37, 156, 253);
}

.active .marker {
  background: rgba(94, 177, 255, 0.568);
  transition: 500ms;
}

tr:nth-child(even) {
  background: rgb(240, 240, 240)
}
</style>
<style>
.answer .v-input--selection-controls__input {
  display: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.panswer {
  padding: 18px 5px 5px 4px !important;
}

.answer .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: rgb(230, 230, 230);
}

.v-input--selection-controls {
  min-width: 250px !important;
}

.v-messages {
  display: none !important;
}

.v-slider__tick {
  background-color: rgba(17, 105, 159, 0.571);
  border-radius: 50px;
}

.v-application--is-ltr .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
  transform: translateX(-27%) !important;
}

.v-slider__tick-label {
  font-size: 13px
}

.marker-list {
  font-size: 14px !important;
  border-radius: 50px !important;
  width: 45px !important;
  text-align: center !important;
  padding: 8px 0 0 0 !important;
}
</style>
