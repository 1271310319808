<template>
  <v-dialog v-model="dialog.show" width="700" class="tw-overflow-hidden">
    <v-card>
      <v-toolbar dark color="primary">
        <!-- <v-toolbar-title>Resumo da Avaliação</v-toolbar-title> -->
        <v-toolbar-title class="text-h5 lighten-2">Questão {{ dialog.index }} </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
        <v-card-text class="py-5">
          <div class="text--primary tw-max-h-80 overflow-x-hidden tw-overflow-y-scroll" ref="text" id="text">
            <div v-html="dialog.data.main_text" id="enunciado"></div>
            <multiple-choice-preview v-if="dialog.data.wordings[0].data.type === 'MULTIPLE_CHOICE'"
              :options="dialog.data.wordings[0].data.options" />
            <matrix-preview v-if="dialog.data.wordings[0].data.type === 'MATRIX'" :wordings="dialog.data.wordings" />
            <slider-preview v-if="dialog.data.wordings[0].data.type === 'SLIDER'" :sliderData="dialog.data.wordings[0].data.params" />
          </div>
        </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color text @click="dialog.show = false">Fechar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import alpha from '../../libs/lists.js'
import MultipleChoicePreview from '@/components/wording-preview/MultipleChoicePreview.vue'
import MatrixPreview from '@/components/wording-preview/MatrixPreview.vue'
import SliderPreview from '@/components/wording-preview/SliderPreview.vue'

export default {
  props: {
    dialog: {
      type: Object
    }
  },
  components: {
    MultipleChoicePreview,
    MatrixPreview,
    SliderPreview
  },
  data () {
    return {
      alpha
    }
  }
}
</script>

<style scoped>
.v-dialog--fullscreen {
  overflow-x: hidden;
}
</style>
