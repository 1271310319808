<template>
  <v-container>
      <div class="mt-4">
        <v-form ref="formExamDatabase">
          <v-row>
            <v-col cols="6" class="py-0 pt-3">
              <text-field label-text="Título *"
              :dense-option="true"
              :outlined-option="true"
              background-color="transparent"
              :validate-on-blur="true"
              :rules-options="[rules.required]"
              @changeValue="form.name = $event"
              :field-value="form.name"/>
            </v-col>

            <v-col cols="6" class="py-0 pt-3">
              <text-field label-text="Tempo limite sugerido (Em Minutos) *"
              background-color="transparent"
              :rules-options="[rules.required, rules.min1]"
              :validate-on-blur="true"
              :dense-option="true"
              :outlined-option="true"
                          :mask-option="'###'"
              min-option="0"
              @changeValue="form.duration = $event"
              :field-value="form.duration"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0 pt-3">
              <text-field label-text="Descrição *"
              background-color="transparent"
              :outlined-option="true"
              :validate-on-blur="true"
              :dense-option="true"
              :rules-options="[rules.required]"
              @changeValue="form.description = $event"
              :field-value="form.description"/>
            </v-col>
          </v-row>

          <!-- Componente para adicionar questões na avaliação -->
          <list-questions-added @questionsOrder="changeOrder($event)" />

          <v-card-actions>
            <v-spacer />
            <v-btn large outlined @click="$router.go(-1)" color="primary">Cancelar</v-btn>
            <template v-if="action === 'add'" >
              <v-btn large @click="saveExam" color="primary">Salvar como avaliação</v-btn>
              <v-btn large @click="save" color="primary">Salvar como modelo</v-btn>
            </template>
            <template v-else-if="action === 'edit'">
              <v-btn large @click="update" color="primary">Atualizar</v-btn>
            </template>
          </v-card-actions>
        </v-form>
      </div>
      <card-loading :message="message" :dialog="dialog" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextField from '@/components/form-fields/TextField'
import CardLoading from '@/components/cards/CardLoading'
import ListQuestionsAdded from '@/components/list/ListQuestionsAdded'
import { getQuestionDifficulty } from '@/utils/question'
import collect from 'collect.js'
import { handleError } from '@/utils/helpers'

export default {
  components: {
    TextField,
    CardLoading,
    ListQuestionsAdded
  },
  props: {
    action: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      min1: value => value >= 1 || 'O valor mínimo é 1.'
    },
    dialog: false,
    message: '',
    name: '',
    id: '',
    questions: [],
    form: {
      name: '',
      description: '',
      duration: '',
      questions: [{ id: '' }],
      owner: { id: '' }
    }
  }),
  methods: {
    ...mapMutations(['setLoading']),
    ...mapActions('exams', ['getExam']),
    ...mapMutations('exams', ['CLEAR_SELECTED', 'SET_SELECTED', 'REMOVE_SELECTED']),
    handleError,
    changeOrder (data) {
      this.selectedQuestions = data
    },
    getQuestionDifficulty,
    formatQuestions (questions) {
      const arr = []
      if (!questions.length) return arr
      questions.forEach((value, index) => {
        const obj = {
          id: value.imported ? value.import_id : value.id,
          order: index
        }
        arr.push(obj)
      })
      return arr
    },
    formatSavedQuestions (questions) {
      return collect(questions)
        .map(value => {
          return {
            ...value.data,
            permissions: { ...value.permissions },
            favorite: false,
            checked: false,
            difficulty: this.getQuestionDifficulty(value.data.difficulty),
            main_text: value.data.main_text.replaceAll('$', '<span>$</span>'),
            title: `${this.striphtml(value.data.main_text)}`.replaceAll('$', '<span>$</span>'),
            subtitle: `Disciplina: ${
              value.data.curricular_component.name
            }, Área de conhecimento: ${
              value.data.knowledge_area.name
            }, Tags: ${this.formatArray(
              value.data.tags
            )}, Assunto: ${this.formatArray(value.data.matters)}`,
            infoAuthor: `Autor: ${this.capitalize(value.data.author.name)}`,
            id: value.metadata.id
          }
        })
        .all()
    },
    capitalize (string) {
      string = string.toLowerCase()
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formatArray (value) {
      if (!value) return
      return value.join(', ')
    },
    striphtml (text) {
      if (!text) return ''
      return text
        .replace(/<[^>]*>?/gm, '')
        .replace('\\(', '')
        .replace('\\)', '')
        .replace(/&nbsp;/g, '')
    },
    handleSuccess () {
      this.$store.dispatch('alert', { msg: `Avaliação ${this.action === 'edit' ? 'atualizada' : 'criada'} com sucesso` })
      this.$router.push('/exam/list')
    },
    setSending (bool = false, msg = 'Aguarde..') {
      this.setLoading(bool)
      this.sending = bool
      this.dialog = bool
      this.message = msg
    },
    isFormValid () {
      if (!this.$refs.formExamDatabase.validate()) {
        this.$store.dispatch('alert', { color: 'red', msg: 'Campos inválidos! Confira e tente novamente.' })
      }
      return this.$refs.formExamDatabase.validate()
    },
    async importQuestions (examId, questions) {
      if (!questions.length) return
      await this.$axios.post(`/organizations/${this.id}/exams/${examId}/questions/import`, { questions })
    },
    async removeQuestions (examId) {
      const questions = collect(this.questions).map(value => ({ id: value.metadata.id })).all()
      if (!questions.length) return
      await this.$axios.post(`/organizations/${this.id}/exams/${examId}/questions`, { _method: 'DELETE', questions })
    },
    // this method is used to save the exam-database
    save () {
      if (!this.isFormValid()) {
        return
      }

      this.setSending(true, 'Aguarde, criando avaliação modelo...')
      this.form.questions = this.formatQuestions(this.selectedQuestions)
      this.form.instructions = this.form.description

      this.$axios
        .post(`/organizations/${this.id}/exams-database`, { ...this.form })
        .then(() => this.handleSuccess())
        .catch(error => this.handleError(error))
        .finally(() => this.setSending(false))
    },
    // this method is used to save the exam
    saveExam () {
      if (!this.isFormValid()) {
        return
      }

      this.setSending(true, 'Aguarde, criando avaliação...')
      const questions = this.formatQuestions(this.selectedQuestions)

      this.$axios
        .post(`/organizations/${this.id}/exams`, { ...this.form })
        .then(async response => await this.importQuestions(response.data.metadata.id, questions))
        .then(() => this.handleSuccess())
        .catch(error => this.handleError(error))
        .finally(() => this.setSending(false))
    },
    // this method is used to update the exam
    update () {
      if (!this.isFormValid()) {
        return
      }

      this.setSending(true, 'Aguarde, atualizando avaliação...')
      const examId = this.$route.params.id
      const questions = this.formatQuestions(this.selectedQuestions)
      if (!examId) return

      this.$axios
        .patch(`/organizations/${this.id}/exams/${this.$route.params.id}`, { ...this.form })
        .then(async () => await this.removeQuestions(examId))
        .then(async () => await this.importQuestions(examId, questions))
        .then(() => this.handleSuccess())
        .catch(error => this.handleError(error))
        .finally(() => this.setSending(false))
    },
    setSelected (savedQuestions) {
      savedQuestions.forEach(el => {
        el.imported = true
        this.SET_SELECTED(el)
      })
    },
    async getQuestions () {
      let nextPageUrl = null
      let page = 1
      this.questions = []
      do {
        const response = await this.$axios.get(`organizations/${this.id}/exams/${this.$route.params.id}/questions?page=${page}`)
        nextPageUrl = response.data.pagination.next_page_url
        this.questions.push(...response.data.items)
        page += 1
      } while (nextPageUrl)
      const savedQuestions = this.formatSavedQuestions(this.questions)
      this.setSelected(savedQuestions)
    },
    getExam (examId) {
      this.$axios.get(
        `organizations/${this.id}/exams/${this.$route.params.id || examId}`
      ).then(res => {
        this.form = {
          description: res.data.data.description,
          name: res.data.data.name,
          duration: res.data.data.duration
        }
        this.examFetch = true
      }).catch(() => {
        this.$store.dispatch('alert', { color: 'red', msg: 'Erro na conexão. Tente novamente.' })
      })
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      selectedQuestions: 'exams/selectedQuestions'
    }),
    exam () {
      return this.$store.state.exams.examSelected
    }
  },
  async created () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (this.action === 'edit') {
      this.setLoading(true)
      this.form = this.exam
      this.getExam()
      await this.getQuestions()
      this.setLoading(false)
    }
  },
  destroyed () {
    this.CLEAR_SELECTED()
  }
}
</script>
