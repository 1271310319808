<template>
    <v-row>
        <v-col class="tw-overflow-x-auto">
          <table class="my-10">
            <tr>
              <th></th>
              <template>
                <th class="text-center" v-for="column, columnIndex in columns" :key="column">
                  {{ column.value ?? `Resposta ${columnIndex}` }}
                </th>
              </template>
            </tr>
            <tr v-for="row, rowIndex in rows" :key="rowIndex">
              <template v-if="row.isAnswered">
                <td class="pa-3 text-center">{{ row.value ?? `Pergunta ${rowIndex}` }}</td>
                <template>
                  <td v-for="column, columnIndex in (isResolution ? row.options : columns)" :key="columnIndex">
                    <v-radio-group v-model="row.selectedAnswer">
                      <v-radio disabled class="tw-flex tw-justify-center" :key="columnIndex" :value="columnIndex">
                      </v-radio>
                    </v-radio-group>
                  </td>
                </template>
              </template>
            </tr>
          </table>
        </v-col>
      </v-row>
</template>

<script>
import collect from 'collect.js'

export default {
  name: 'MatrixPreview',
  props: ['wordings', 'isResolution', 'question'],
  data: () => ({
    rows: [],
    columns: []
  }),
  created () {
    this.mountMatrixIf(this.isResolution)
  },
  watch: {
    question () {
      this.mountMatrixIf(this.isResolution)
    },
    wordings () {
      this.mountMatrixIf()
    }
  },
  methods: {
    mountMatrixIf (isResolution = false) {
      let options = {}
      if (!isResolution) {
        this.rows = collect(this.wordings).pluck('data').map(row => {
          const selectedAnswer = collect(row.options)
            .values()
            .map((option, index) => {
              return !option.is_correct ? null : index
            })
          return {
            value: row.wording,
            points: row.grade,
            selectedAnswer: collect(selectedAnswer).whereNotNull().first(),
            isAnswered: true
          }
        }).toArray()
        options = collect(this.wordings).first().data.options
      } else {
        this.rows = collect(this.question.wordings).map(row => {
          const answer = collect(this.question.answer.answers)
            .first()
          const isAnswered = collect(row.options[answer])
            .isNotEmpty()
          return {
            value: row.wording,
            points: row.grade,
            selectedAnswer: this.question.answer.answers[0],
            options: row.options,
            isAnswered
          }
        }).toArray()
        options = collect(this.question.wordings).first().options
      }
      this.getAnswers(options)
    },
    getAnswers (options) {
      this.columns = collect(options).map(option => {
        return {
          value: option.answer
        }
      }).toArray()
    }
  }
}
</script>
