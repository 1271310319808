<template>
    <v-row class="mt-0">
        <v-col cols="12" md="6">
          <v-select
            v-model="isShortAnswer"
            @input="handleInput"
            outlined
            dense
            :items="items"
            label="Selecione um tipo de limite de caracteres"
            class="mb-0"
          >
           <template v-if="isShortAnswer" slot="append">
            <icon-tooltip
              color="warning"
              text="A resposta ficará limitada a 250 caracteres"
              icon="mdi-alert-circle"
            />
          </template>
        </v-select>
        </v-col>
        <v-col v-if="!isShortAnswer" cols="12" md="6" class="py-0 mb-7 pt-3 position-relative" >
          <v-text-field
            v-mask="`#############`"
            v-model="limitValue"
            @input="handleInput"
            outlined
            dense
            label="Digite uma quantidade de caracteres"
          >
          </v-text-field>
          <div class="hint mb-2">* Caso não exista limite, preencha com 0 (zero)</div>
        </v-col>
        <v-col v-else cols="12" md="6">
        </v-col>
    </v-row>
</template>

<script>
import IconTooltip from '@/components/tooltip/IconTooltip.vue'

export default {
  name: 'OpenEndedQuestion',
  components: { IconTooltip },
  props: ['value'],
  data () {
    return {
      items: [
        { text: 'Quantidade de caracteres', value: false },
        { text: 'Resposta curta', value: true }
      ],
      isShortAnswer: false,
      limitValue: 0
    }
  },
  watch: {
    value () {
      this.isShortAnswer = this.value.is_short_answer
      this.limitValue = this.value.limit_value
    }
  },
  methods: {
    handleInput () {
      const obj = {
        is_short_answer: this.isShortAnswer,
        limit_value: this.isShortAnswer ? 250 : parseInt(this.limitValue),
        ...((this.limitValue >= 1 && !this.isShortAnswer) && { is_limited: true })
      }
      this.$emit('input', obj)
    }
  }
}
</script>
