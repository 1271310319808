<template>
  <v-container>
    <div>
      <div class="mt-4">
        <v-form ref="formConfig">
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <v-select
                :rules="[rules.requiredSelect]"
                v-model="platform"
                :disabled="sending"
                :items="availablePlatforms"
                label="Plataforma de Acesso"
                background-color="transparent"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <v-combobox
                label="Domínios Autorizados"
                background-color="transparent"
                v-model="organizationData.domains"
                :disabled="sending"
                required
                :rules="[domainRules]"
                append-icon=""
                outlined
                multiple
                clearable
                chips
                hint="Pressione TAB ou ENTER para adicionar um novo domínio"
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    close
                    @click:close="data.parent.selectItem(data.item)"
                  >{{ data.item }}</v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-row class="m-0">
              <v-col cols="12" class="py-0">
                <div v-if="domainFail.length" class="tw-py-6 tw-px-5 tw-text-red-700">
                  <span
                    v-for="(domain, ind) in domainFail"
                    :key="ind"
                    class="tw-bg-red-100 tw-px-2 tw-py-1 tw-mx-1 tw-text-red-900 tw-font-semibold tw-rounded-full"
                  >{{ domain }}</span>
                  <span v-if="domainFail.length>1">são dominios inválidos</span>
                  <span v-else>é um dominio inválido</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="m-0">
              <v-col cols="12" class="py-0">
                <v-card-actions v-if="action !== 'new'">
                  <v-spacer />
                  <button-update :sending="sending" v-on:update="update"></button-update>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
// import { TheMask } from 'vue-the-mask'

export default {
  // components: { TheMask },
  props: {

    action: {
      type: String,
      default: 'edit'
    },
    organization: {
      type: Object,
      default () {
        return {
          data: {
            domains: null
          }
        }
      }
    }
  },
  data: () => ({
    domainFail: [],
    rules: {
      requiredSelect: v => (v && !!v.length >= 1) || 'Selecione uma plataforma.'
    },
    sending: false,
    platform: 'Google for Education',
    availablePlatforms: [
      'Google for Education'
    ]
  }),
  computed: {
    organizationData: {
      get () {
        return this.organization.data
      },
      set (val) {
        this.$emit('update', val)
        return val
      }
    }
  },
  methods: {
    domainRules (v) {
      if (v.length === 0) {
        this.domainFail = []
        return 'Este campo é obrigatório.'
      }
      if (v.length > 0) {
        let message = true
        this.domainFail = []
        v.forEach((element) => {
          if (!/((?:[a-z\d](?:[a-z\d-]{0,63}[a-z\d])?|\*)\.)+[a-z\d][a-z\d-]{0,63}[a-z\d]/g.test(element)) {
            this.domainFail.push(element)
            message = 'Favor inserir um domínio válido.'
          }
        })
        return message
      } return true
    },
    submit () {
      this.$v.$touch()
    },
    update () {
      if (!this.$refs.formConfig.validate()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }
      this.sending = true
      const self = this
      const payload = this.organizationData
      delete payload.gcred_oauth_user
      delete payload.company.logo
      this.$axios.patch(`/organizations/${this.organization.metadata.id}`, { ...payload })
        .then(response => { self.$store.dispatch('alert', { msg: 'Dados atualizados com sucesso' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          this.sending = false
        })
    }
  }
}
</script>
