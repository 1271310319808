<template>
  <v-card text class="mb-7">
    <v-card-text class="pa-0" v-if="fetch">
      <div
        class="rounded-t"
        style="
          height: 100px;
          background: rgb(30, 136, 229) !important;
          background-image: linear-gradient(
            180deg,
            rgba(30, 136, 229, 1) 0%,
            rgba(30, 136, 229, 0.5) 100%
          ) !important;
        "
      ></div>
      <div class="pa-4 mt-n12 text-center">
        <div class="d-flex justify-center mt-n5">
          <img
            :src="`https://gravatar.com/avatar/${md5(school.metadata.id)}?s=80&d=identicon&r=x`"
            :alt="school.data.name"
            class="rounded-circle elevation-1"
            width="128"
            height="128"
            style="height:128px;width:128px;"
          />
        </div>
        <h2 class="mb-0 mt-4" v-if="school.data.name">{{ school.data.name }}</h2>
        <p
          class="font-weight-regular"
          v-if="school.data.address"
        >{{ school.data.address.city }}, {{ school.data.address.state }}</p>
        <v-btn
          v-if="edit"
          color="primary"
          text
          outlined
          large
          :to="`/school/edit/${school.metadata.id}`"
          class="px-7"
        >Editar</v-btn>
      </div>
    </v-card-text>
    <v-skeleton-loader v-else v-bind="attrs" type="article, actions"></v-skeleton-loader>
    <!-- <v-container>
      <v-card-text>
        <v-row class="text-center">
          <v-col cols="6">
            <h2 class="mb-0 font-weight-bold">1.000</h2>
            <small class>Professores</small>
          </v-col>
          <v-col cols="6">
            <h2 class="mb-0 font-weight-bold">100.000</h2>
            <small class>Alunos</small>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>-->
  </v-card>
</template>
<script>
import md5 from '@/libs/md5'
export default {

  props: {
    edit: {
      type: Boolean,
      default: false
    },
    fetch: {
      type: Boolean,
      default: true
    },
    school: {
      type: Object,
      default: () => {
        return {
          data: {
            name: '',
            address: {
              city: '',
              state: ''
            }
          }
        }
      }
    }
  },
  methods: {
    md5
  },
  data: () => ({
    attrs: {
      class: 'ma-6',
      boilerplate: true
    }
  })
}
</script>
