<template>
  <v-container>
    <div>
      <div class="mt-4">
        <form>
          <!-- v-model="data.question" -->
          <v-row class="m-0">
            <v-col cols="12" class="py-0">
              <v-checkbox
                v-model="checkbox"
                :label="`Compartilhar entre professores da minha instituição.`"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            {{ enunciated.text }}
            <v-col cols="12" md="12" class="py-0 editor-box mb-3">
              <label class="v-label v-label--active theme--light">
                <sup>Enunciado *</sup>
              </label>
              <editor
                api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh"
                :init="editorConfig"
                v-model="enunciated.text"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0 pt-3">
              <v-combobox
                :items="items"
                label="Tags"
                background-color="transparent"
                multiple
                chips
                outlined
                hide-selected
                hint="Pressione TAB ou ENTER para adicionar uma nova tag."
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    close
                    @click:close="data.parent.selectItem(data.item)"
                  >{{ data.item }}</v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-combobox
                :items="items"
                label="Assuntos"
                background-color="transparent"
                multiple
                chips
                outlined
                hide-selected
                hint="Pressione TAB ou ENTER para adicionar uma nova tag."
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    close
                    @click:close="data.parent.selectItem(data.item)"
                  >{{ data.item }}</v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                :items="items"
                label="Dificuldade *"
                background-color="transparent"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                :items="items"
                label="Área de Conhecimento"
                background-color="transparent"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                :items="items"
                label="Disciplina"
                background-color="transparent"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select :items="items" label="Grau" background-color="transparent" outlined dense></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                :items="types"
                item-text="name"
                item-value="id"
                label="Tipo *"
                return-object
                background-color="transparent"
                @change="changeType"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                label="Peso *"
                background-color="transparent"
                v-mask="'###'"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <div
            v-show="showOptions"
            v-for="(option, index) in options"
            :key="option.name"
            class="tw-bg-gray-50 tw-border tw-border-gray-200 tw-mb-4 tw-rounded-md tw-px-4 tw-pt-4"
          >
            <v-row class="pt-4" justify="center">
              <v-col cols="10" class="py-0">
                <v-switch
                  v-model="correct"
                  :id="option.name"
                  :value="option.name"
                  class="pa-0 ma-0 d-inline-block"
                  label="Alternativa Correta"
                ></v-switch>
              </v-col>
              <v-col cols="2" class="py-0" align="end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="removeOption(index)" icon>
                      <v-icon v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Remover Alternativa</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="mb-4 relative">
              <v-col cols="12" class="py-0 editor-box mb-3">
                <v-divider class="mt-n2 mb-4" style="border-color: #949494" />
                <editor
                  api-key="m13sivy9s8c2sz0weghbjqpkm8zch4r99bqo4u5br7rrx4rh"
                  :init="editorConfig"
                />
              </v-col>
            </v-row>
          </div>
          <div v-show="showOptions">
            <v-row>
              <v-col cols="12" align="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addOption()" fab dark color="primary">
                      <v-icon dark v-bind="attrs" v-on="on">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar Alternativa</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
          <button-save v-on:save="save" v-if="action === 'add'" class="mt-6"></button-save>
          <button-update v-on:update="update" v-else-if="action === 'edit'" class="mt-6"></button-update>
        </form>
      </div>
    </div>
  </v-container>
</template>

<script>
// import { TheMask } from 'vue-the-mask'
// import MathJax from 'mathjax'
// import '../../../public/js/tinymce-mathjax/es5/tex-chtml.js'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    // TheMask,
    editor: Editor
  },
  props: {
    action: {
      type: String,
      default: ''
    },
    enunciated: {
      type: Object,
      default () {
        return { text: null }
      }
    }
  },
  data: () => ({
    name: '',
    select: null,
    items: [],
    checkbox: false,
    showOptions: false,
    optionCorrect: [],
    types: [
      { name: 'Objetiva', id: '1' },
      { name: 'Discursiva', id: '2' },
      { name: 'Bloco', id: '3' }
    ],
    optionIndex: 0,
    options: [
      {
        name: 'option-0'
      }
    ],
    correct: [],
    people: [],

    notFound: false,
    editorConfig: {
      height: 200,
      menubar: true,
      external_plugins: {
        mathjax:
          '/js/tinymce-mathjax/plugin.min.js'
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen media',
        'insertdatetime media table paste code help wordcount',
        'tinymcespellchecker powerpaste export a11ychecker'
      ],
      powerpaste_word_import: 'merge',
      powerpaste_html_import: 'merge',
      powerpaste_googledocs_import: 'merge',
      smart_paste: true,
      a11ychecker_level: 'aaa',
      mathjax: {
        lib:
          '/js/tinymce-mathjax/es5/tex-mml-chtml.js'
      },
      toolbar:
        'undo redo | formatselect | bold italic underline forecolor backcolor | ' +
                     'alignleft aligncenter alignright alignjustify | ' +
                     'bullist numlist outdent indent | removeformat | charmap image media mathjax ' +
                     'export a11ycheck',
      language: 'pt_BR'
    }
  }),
  methods: {
    save () {
      try {
        // this.$axios.post('api/question', formData, this.token)
      } catch (e) {
        throw e
      }
    },
    update () {
      try {
        // this.$axios.put('api/question/{id}', formData, this.token)
      } catch (e) {
        throw e
      }
    },
    addOption () {
      this.optionIndex++
      this.options.push({
        name: `option-${this.optionIndex}`
      })
    },
    removeOption (i) {
      if (this.options.length > 1) this.options.splice(i, 1)
    },
    changeType (value) {
      if (value.id === '1') this.showOptions = true
      else this.showOptions = false
    }
  },
  mounted () {
    // this.people = axios.get('/api/getPerson', 'JSON')
    // config axios
    // MathJax.typeset()
  }
}
</script>
