<template>
    <v-autocomplete
        v-model="value"
        @change="handleInput"
        :disabled="disabled"
        :items="items"
        :loading="loading"
        :multiple="multiple"
        :label="label"
        :chips="chips"
        :dense="dense"
        background-color="transparent"
        item-text="name"
        item-value="value"
        outlined
        required
        return-object
    >
        <template #item="{ item }">
          <v-checkbox v-if="multiple" :value="value.map(item => item.value).includes(item.value)">
           <template #label>
            <div style="display: flex;flex-direction: column;">
              <span style="font-weight: 400;">{{ item?.name }}</span>
              <span style="font-size: small;color: rgb(113, 113, 113)35, 135, 135);">{{ item?.subtitle }}</span>
            </div>
           </template>
          </v-checkbox>
          <span v-else>
            <div style="display: flex;flex-direction: column;">
              <span style="font-weight: 400;">{{ item?.name }}</span>
              <span style="font-size: small;color: rgb(113, 113, 113)35, 135, 135);">{{ item?.subtitle }}</span>
            </div>
          </span>
        </template>
        <template #append-item>
            <div v-intersect="{
                handler: onIntersect,
                options: {
                    threshold: [0, 0.5, 1.0]
                }
                }">
            <v-skeleton-loader v-show="loading" class="mx-auto" type="list-item" />
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
import collect from 'collect.js'

export default {
  props: ['value', 'disabled', 'loading', 'items', 'label', 'multiple', 'chips', 'dense'],
  methods: {
    onIntersect (entries) {
      if (this.loading) return
      collect(entries).each(entry => {
        if (entry.isIntersecting) {
          this.$emit('loadMore')
        }
      })
    },
    handleInput () {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  }
}
</script>
