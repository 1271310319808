<template>
  <v-dialog v-model="value" persistent max-width='80%' transition="dialog-bottom-transition">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="pb-0 pt-4">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="mt-3 flex justify-end">
        <v-btn color="primary" outlined @click="$emit('close')">{{ closeBtn }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'value', 'title', 'closeBtn'
  ]
}
</script>
