import collect from 'collect.js'

function checkRole (me) {
  if (me.data.is_super_admin) {
    return ['SUPER_ADMIN']
  }

  const roles = collect(me.data.acl)
    .pluck('roles')
    .collapse()
    .all()

  return roles
}

export { checkRole }
