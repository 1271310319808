<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="color"
            v-bind="attrs"
            v-on="on"
          >
          {{ icon }}
          </v-icon>
        </template>
        <span>{{ text }}</span>
    </v-tooltip>
</template>

<script>
export default {
  name: 'IconTooltip',
  props: ['color', 'icon', 'text']
}
</script>
