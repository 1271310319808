<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-6">
        <div class="d-flex justify-end">
          <v-btn v-if="isMutipleChoice" class="mr-2" elevation="3" x-large color="primary" @click="genereateBubbleSheetPDF">Baixar gabarito</v-btn>
          <v-btn elevation="3" x-large color="primary" :loading="pdfLoading" @click="convertToPdf">Baixar avaliação</v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="pa-10">
        <div ref="document" class="exam_export">
          <div>
            <div>
              <img style="margin-bottom:20px;" width="60" :src="organizationData.data.data.company.logo" :alt="organizationData.data.data.company.name" />
              <h2 style="font-size:14px">Instituição: {{ organizationData.data.data.company.name.trade }}</h2><br/>
            </div>
          </div>
          <div class="exam_data">
            <div>
              <h2 style="font-size:20px">Avaliação {{ examApplication.data.exam.name }}</h2><br/>
              <h2 style="font-size:14px">Nome: <span v-for="studentItem, studentIndex in 50" v-bind:key="studentIndex">_</span></h2>
            </div>
            <div>
              <h2 style="font-size:14px;text-align:right">Data: __/__/____</h2><br/>
              <h2 style="font-size:14px;text-align:right">Turma: <span v-for="studentItem, studentIndex in 20" v-bind:key="studentIndex">_</span></h2>
            </div>
          </div>
          <div style="text-align:center;margin-bottom:20px;">
            <h2 style="font-size:14px;">Instruções</h2><br/>
            <div v-html="examApplication.data.instructions"></div>
          </div>
          <div class="exam_export_questions">
            <div style="margin-bottom:15px;font-size:14px;text-align:center;">
              <h2>Questões</h2>
            </div>
            <div v-for="question, examIndex in questionsData.data.items" v-bind:key="question.metadata.id" class="exam_export_questions_answers exam_export_questions_margin">
              <div v-html="`${examIndex + 1}) ${question.data.main_text.replace('<p>', '').replace('</p>', '')}`"></div>
              <div v-if="question.data.wordings[0].data.type === 'MULTIPLE_CHOICE'">
                <strong>Selecione a alternativa correta:</strong><br/><br/>
                <div v-for="option, index in question.data.wordings[0].data.options" v-bind:key="index">
                  <div v-html="`${getMarker(examApplication.data.marker, option.order)}) ${option.answer.replace('<p>', '').replace('</p>', '')}<br/><br/>`"></div>
                </div>
              </div>
              <div v-if="question.data.wordings[0].data.type === 'OPEN_ENDED'" class="exam_export_open_ended">
                <div class="exam_export_open_ended_answer" v-for="item, answerIndex in 500" v-bind:key="answerIndex">_</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col v-show="false" v-if="isMutipleChoice">
        <div ref="bubbleSheet">
          <div class="text-center pb-10">
            <h2 style="text-align: center; font-size:20px;margin-bottom: 0.6rem;">GABARITO<br>{{ examApplication.data.exam.name }}</h2>
            <h2 style="font-size:14px;margin-bottom: 0.6rem;">Nome: <span v-for="i in 50" v-bind:key="i">_</span></h2>
            <h2 style="font-size:14px;margin-bottom: 0.6rem;">Turma - {{examApplication.data.courses_group[0].name}}</h2>
          </div>
          <div class="div_instructions">
            <span>Para todas as marcações neste gabarito, preencha os círculos completamente e com nitidez.</span>
          </div>
          <br>
          <div class="div_questions">
            <v-row align-content="center">
              <div class="div_questions_line" v-for="column in getQuestions(maxQuestionsPerColumn).columns" :key="column" >
                  <div v-for="question, index in getQuestions().questions.slice((column * maxQuestionsPerColumn) - maxQuestionsPerColumn , column * maxQuestionsPerColumn)" :key="index">
                    <div style="display: flex">
                      <div class="div_number">{{ question.index }}</div>
                      <div class="ml-1" v-for="i in 5" :key="i">
                        <div class="div_circle">{{ getMarker(examApplication.data.marker, i - 1) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="getQuestions().questions.length < 10">
                  <div v-for="i in (10 - getQuestions().questions.length)" :key="i" style="height: 25px; width: 25px; margin-bottom: 5px">
                  </div>
                </div>
            </div>
            </v-row>
            <div v-for="i in 4" :key="i">
              <span :class="`marker_${i}`"><img width="30" src="../../assets/images/bubblesheet/omr_marker.png" alt="Marcador"/></span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import alpha from '../../libs/lists'
import { getMarker } from '@/utils/marker'

export default {
  props: {
    examApplication: {},
    organizationData: {},
    examData: {},
    questionsData: {}
  },
  data: () => ({
    organization: {},
    exam: {},
    questions: [].name,
    pdfLoading: false,
    isMutipleChoice: false,
    letters: alpha.map(e => e.toUpperCase()),
    maxQuestionsPerColumn: 10
  }),
  methods: {
    getMarker,
    blobToBase64 (blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    convertToPdf () {
      this.pdfLoading = true
      this.exportToPDF().then(() => {
        setTimeout(() => {
          html2pdf(this.$refs.document, {
            margin: 0.8,
            filename: 'document.pdf',
            image: { type: 'png', quality: 0.99 },
            html2canvas: { dpi: 192 },
            jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
          })
          setTimeout(() => {
            this.pdfLoading = false
          }, 4000)
        }, 10000)
      })
    },
    moment,
    async exportToPDF () {
      const images = this.$refs.document.querySelectorAll('img')
      const arr = Array.from(images)

      arr.map(async (el, index) => {
        await fetch(el.src, {
          method: 'GET',
          headers: {
            accept: '/',
            'Access-Control-Request-Headers': 'access-control-allow-origin',
            'Access-Control-Request-Method': 'GET'

          }
        }).then(async (res) => {
          const imageBlob = await res.blob()
          this.blobToBase64(imageBlob).then(res => {
            this.$refs.document.querySelectorAll('img')[index].src = res.replace('data:application/octet-stream', 'data:image/png')
          })
        })
      })
    },
    getQuestions (maxItemsPerColumn) {
      const questions = []
      let countColumns = 0
      this.questionsData.data.items.map((question, index) => {
        if (question.data.wordings[0].data.type === 'MULTIPLE_CHOICE') {
          if (index % maxItemsPerColumn === 0) {
            countColumns++
          }
          questions.push({
            index: question.data.order,
            value: Object.keys(question.data.wordings[0].data.options).length,
            column: countColumns
          })
        }
      })
      return {
        questions: questions,
        columns: countColumns
      }
    },
    genereateBubbleSheetPDF () {
      const title = this.examApplication.data.exam.name
      html2pdf(this.$refs.bubbleSheet, {
        margin: 0.8,
        filename: `Gabarito - ${title}`,
        image: { type: 'png', quality: 1 },
        html2canvas: { dpi: 192 },
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
      })
    },
    verifyQuentionsRules () {
      const questionsLenght = this.questionsData.data.items.length
      return (questionsLenght >= 1 && questionsLenght <= 30)
    },
    isAllQuestionsMutipleChoice () {
      const questionsNonMultipleChoice = this.questionsData.data.items.filter(question => question.data.wordings[0].data.type !== 'MULTIPLE_CHOICE').length
      return questionsNonMultipleChoice === 0
    }
  },
  mounted () {
    if (this.isAllQuestionsMutipleChoice() && this.verifyQuentionsRules()) {
      this.isMutipleChoice = true
    }
  }
}
</script>

<style>
  .exam_data {
    justify-content: space-between;
    display: flex;
    margin-bottom: 25px;
  }
  h2 {
    color: black;
  }
  .exam_export {
    font-family: 'Arial';
    font-size: 12px;
  }
  .exam_export_questions_margin {
    margin-bottom: 50px;
  }
  .exam_export_open_ended_answer {
    display: inline-block;
  }
  .exam_export_questions_answers {
    font-size: 13px;
  }
  .div_instructions {
    background-color: rgb(193, 193, 193);
    padding: 10px;
    text-align: start;
  }
  .div_number {
    height: 10px;
    width: 10px;
    display: flex;
  }
  .div_circle {
    border-width: 1px;
    border-color: rgb(0, 0, 0);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div_questions {
    padding: 20px 22px;
    position: relative;
  }
  .div_questions_line {
    padding: 10px 10px;
    margin-left: 20px;
  }
  .marker_1 {
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .marker_2 {
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .marker_3 {
    height: 30px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .marker_4 {
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
